/* eslint-disable */
import { push } from 'react-router-redux';
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_OUTET_PRICE, SET_OUTET_PRICE } from '../actions';
import { appId, apiUrl } from "../components/Helpers/Config";
import { getReferenceID } from "../components/Helpers/SettingHelper";
import Axios from 'axios';
import cookie from 'react-cookies';
var qs = require('qs');

export const watchGetOutletPrices = function* () {
  yield takeEvery(GET_OUTET_PRICE, workerGetOutletPrices);
}

function* workerGetOutletPrices() {
  try {
    const uri = apiUrl+'onboarding/getsettings';
    const result = yield call(Axios.get, uri);
  	var resultArr = [];
		resultArr.push(result.data);
    yield put({ type: SET_OUTET_PRICE, value: resultArr });
  } 
  catch {
    console.log('Get Cart Detail Failed');
  }
}

