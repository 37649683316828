/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Slider from "react-slick";

import { apiUrl, proapiUrl, apiBaseUrl, proapiBaseUrl, apiBaseUrlEnt } from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  country_symbol,
  showAlert
} from "../Helpers/SettingHelper";


import logo from "../../common/images/logo.png";
import errorImg from "../../common/images/error.png";
import errorwImg from "../../common/images/error_wrong.png";
import messageImg from "../../common/images/message.svg";

var qs = require("qs");
class Resetpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: "",
      confirm_password: "",
      new_password_error: "",
      confirm_password_error: "",
      confirm_password_re_error: "",
    };
  }



  componentDidMount() {
    var resetKey = this.props.match.params.resetKey;
    this.setState({ resetKey: resetKey });

    /* for account reset - start */
    if (typeof resetKey === "undefined" || resetKey === "") {
      showAlert("Error", "Please check your reset key.");

      const { history } = this.props;

      history.push("/");
    } else {
      $.magnificPopup.open({
        items: {
          src: "#change-password-popup",
        },
        type: "inline",
        closeOnBgClick: false,
      });

    }
  }


  handleChange(field, event) {

    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }



  handleUpdatePassword(){

      var errorFlg = "n";

      if (this.state.new_password === "") {
        this.setState({ new_password_error: true });
        errorFlg = "y";
        return false;
      } else {
        this.setState({ new_password_error: false});
      }

      if (this.state.confirm_password === "") {
    
          this.setState({ confirm_password_error: true });
          errorFlg = "y";
          return false;

        } else {
          this.setState({ confirm_password_error: false });
        }
    
      if (this.state.new_password != this.state.confirm_password ) {
  
        this.setState({ confirm_password_re_error: true });
        errorFlg = "y";
        return false;

      } else {
        this.setState({ confirm_password_re_error: false });
      }

      if (errorFlg === "n") {
        var postObject = {
          key: this.state.resetKey,
          password: this.state.new_password,
          confirmpassword: this.state.confirm_password,
         };

        axios
          .post(apiUrl + "onboarding/resetpassword", qs.stringify(postObject))
          .then((res) => {

           if(res.data.status === "ok"){
            this.handleShowAlertFun("success", res.data.message);
            const { history } = this.props;
            history.push("/");

           }else{

            this.handleShowAlertFun("Error", res.data.message);
            const { history } = this.props;
            history.push("/");

           }

          });
      }

  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  
  render() {

    return (<div>

        <div id="change-password-popup" className="white-popup mfp-hide popup_sec login-popup forgot-password-popup">
          <div className="pop-whole">
            <div className="pop-whole-lhs">
              <div className="pop-whole-lhs-inner">
                <h3>Reset Password</h3>
              </div>
            </div>

            <div className="inside-popup-rhs">
              <div className="popup-header textcenter">
                <h4>
                  <img  />
                </h4>
              </div>

            <div className="popup-body">


            <div className={
                    this.state.new_password !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }>
              <div className="form-group">
                <input type="password" name="new_password" className="input-box" onChange={this.handleChange.bind(this, "new_password")} />

                  {this.state.new_password_error === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                  {this.state.new_password_error === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}

                <label className="control-label">New Password</label>
              </div>
              </div>


             <div className={
                    this.state.confirm_password !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }>
              <div className="form-group">
                <input type="password" name="confirm_password" className="input-box" onChange={this.handleChange.bind(this, "confirm_password")} />


                  {this.state.confirm_password_error === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                  {this.state.confirm_password_error === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}


                  {this.state.confirm_password_re_error === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                <label className="control-label">Confirm Password</label>
              </div>
              </div>

              <div className="form-group">
                <div className="login_pop_sub">
                  <button
                    type="button"
                    className="button btn_black btn_minwid forgotpassword-cls"
                    onClick={this.handleUpdatePassword.bind(this)}
                  >
                   Submit
                  </button>
                </div>
              </div>

            </div>

            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Resetpassword);
