/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";

import {
  apiUrl,
  stripeReference,
  stripeCurrency,
  stripeKey,
  stripeImage,
  stripeCompany,
} from "../Helpers/Config";
import Select from "react-select";
import StripeCheckout from "react-stripe-checkout";
import { Link, withRouter } from 'react-router-dom';
import Slider from "react-slick";

import {
  showLoader,
  hideLoader,
  showCustomAlert,
  showPriceValue,
} from "../Helpers/SettingHelper";


import {
  GET_OUTET_PRICE,
} from "../../actions";


import Sidebar from "../Myaccount/Sidebar";
import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";
import logo from "../../common/images/logo.png";
import started from "../../common/images/getting-started.png";
import planone from "../../common/images/plan-ninjapro.png";
import plantwo from "../../common/images/plan-enterprise.png";
import planthree from "../../common/images/plan-crm.png";
import detailpro from "../../common/images/detail-pro.png";
import howit from "../../common/images/howit-video.jpg";
import payment from "../../common/images/payment.png";
import mobilelogo from "../../common/images/logo.png";
import getstartImg from "../../common/images/s_bar2.svg";
import getstartWImg from "../../common/images/s_bar1.svg";
import mdetailsImg from "../../common/images/s_bar4.svg";
import mdetailsWImg from "../../common/images/s_bar3.svg";
import planImg from "../../common/images/s_bar6.svg";
import planWImg from "../../common/images/s_bar5.svg";
import helpImg from "../../common/images/s_bar8.svg";
import helpWImg from "../../common/images/s_bar7.svg";
import logoutImg from "../../common/images/s_bar10.svg";
import logoutWImg from "../../common/images/s_bar9.svg";
import dashboardImg from "../../common/images/s_bar12.svg";
import dashboardWImg from "../../common/images/s_bar11.svg";
import notficationImg from "../../common/images/s_bar14.svg";
import notficationWImg from "../../common/images/s_bar13.svg";
import subscriptionsImg from "../../common/images/s_bar16.svg";
import subscriptionsWImg from "../../common/images/s_bar15.svg";
import PricetickImg from "../../common/images/blue-tick.png";
import deliveryImg from "../../common/images/delivery.jpg";
import takeawayImg from "../../common/images/takeaway.jpg";
import dineinImg from "../../common/images/dinin.jpeg";
import videobtnImg from "../../common/images/video-btn.png";

import enterpriseImg from "../../common/images/enterprise.jpg";


var qs = require("qs");
class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      userID: cookie.load("userID"),
      name: "",
      user_email: "",
      company_name: "",
      country: "",
      countryList: [],
      postal_code: "",
      address: "",
      block_no: "",
      unit_no: "",

      error_company_name: "",
      error_country: "",
      error_countryList: "",
      error_postal_code: "",
      error_address: "",
      error_block_no: "",
      error_unit_no: "",
      error_totalOulet: "",

      onboarding_type: "",
      totalOulet: 1,
      firstOutletprice: '',
      eachoutleprice: '',
      onboard_totalPrice: 0,
      totalPrice: 0,
      stripe_payment_enable: 1,
      paymentmodevalue: "Stripe",
      stripe_public_key: "",
      stripe_envir_mode: "",
      paymentstatus: "",

      billing_name: "",
      billing_phone: "",
      billing_email: "",
      billing_address: "",
      billing_postal_code: "",
      billing_block_no: "",
      billing_unit_no: "",
      billing_address_same: "yes",
      billing_checked: true,

      validateimage: loadingImage,
      placingorderimage: loadingImage,
      completingpaymentimage: loadingImage,

      error_company_name: "",
      error_country: "",
      error_postal_code: "",
      error_address: "",
      error_block_no: "",
      userDetails: [],
      billcheck: false,
      outletlistprice: [],
      billingaddresserror: false,
      error_get_started_fields: false,
      ninja_video_site_url:"",
      client_enquiry_type: "",
      client_onboarding_message: "",
      currentPage: this.props.match.path,

    };

    this.loadUserDetails();
    this.props.loadOutletPriceDetails();
  }


  componentWillReceiveProps(nextProps){

    if (nextProps.outletlistprice !== this.state.outletlistprice) {
      if (nextProps.outletlistprice.length > 0) {

        var stripe_public_key = "";
        var stripe_envir_mode = "";

         if(nextProps.outletlistprice[0].stripe_setting_mode == 1){
          stripe_public_key = nextProps.outletlistprice[0].client_stripe_public_live;
          stripe_envir_mode = "live";

         }else{
          stripe_public_key = nextProps.outletlistprice[0].client_stripe_public_test;
          stripe_envir_mode = "dev";
         }

        this.setState({outletlistprice: nextProps.outletlistprice, stripe_public_key: stripe_public_key, stripe_envir_mode: stripe_envir_mode}, function(){
         this.calculatePrice();
        });
      }
    }
  }
  
  loadCountlist() {
    var urlShringTxt = apiUrl + "onboarding/getcountryList";

    axios.get(urlShringTxt).then((res) => {
      var countryList = [];
      if (res.data.country.length > 0) {
        res.data.country.map((item) => {
          if (this.state.userDetails.onboarding_country === item.country_id) {
            this.setState({
              country: {
                value: item.country_id,
                label: item.country_name,
              },
            });
          }
          countryList.push({
            value: item.country_id,
            label: item.country_name,
          });
        });
      }
      this.setState({ countryList: countryList });
    });
  }

  loadUserDetails() {
    var urlShringTxt =
      apiUrl + "onboarding/getuserinfo?userID=" + this.state.userID;

    axios.get(urlShringTxt).then((res) => {
      var result = res.data.result_set;
      var step = 1;
      if (result.onboarding_payment_status !== "Paid") {
        step =
          result.onboarding_complete_step !== "" &&
          result.onboarding_complete_step !== null
            ? parseInt(result.onboarding_complete_step) + 1
            : 1;
      }

      cookie.save("onboarding_payment_status", result.onboarding_payment_status, { path: "/" });
   

      if (result.onboarding_payment_status == "Paid") {
        this.setState({paymentstatus: result.onboarding_payment_status });
       this.props.history.push("/dashboard");
      }

      var billing_address = "";

      if(result.onboarding_address !=="" && result.onboarding_address !==null){

        billing_address = result.onboarding_address.replace(/\\/g,'');
      }

    

      this.setState(
        {
          userDetails: res.data.result_set,
          user_email: result.onboarding_email,
          name: result.onboarding_name,
          company_name: result.onboarding_company_name,
          postal_code: result.onboarding_postal_code,
          address: (billing_address !== "" && billing_address !==undefined && billing_address !==null ? billing_address : ""),
          block_no: result.onboarding_block_no,
          unit_no: result.onboarding_unit_no,
          onboarding_type: result.onboarding_type,
          totalOulet: result.onboarding_total_outlet > 0 ?result.onboarding_total_outlet:1,
          step: step,
          paymentstatus: result.onboarding_payment_status,
          billing_name: result.onboarding_name,
          billing_phone: result.onboarding_phone,
          billing_email: result.onboarding_email,
          billing_address: (billing_address !== "" && billing_address !==undefined && billing_address !==null ? billing_address : ""),
          billing_postal_code: result.onboarding_postal_code,
          billing_block_no: result.onboarding_block_no,
          billing_unit_no: result.onboarding_unit_no,
        },
        function () {
          $("#dvLoading").fadeOut();
          this.props.loadOutletPriceDetails();
          this.loadCountlist();
          this.calculatePrice();
        }
      );
    });
  }

  handleTextChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }

  handleTextChange_getStarted(event){

    var name = event.target.name;
    var value = event.target.value;;

   if (event.target.name === "company_name") {
      if (value !== "") {
        this.setState({ error_company_name: false });
      } else {
        this.setState({ error_company_name: true });
      }
      this.setState({ company_name: value });
    }

     if (event.target.name === "postal_code") {
      if (value !== "") {
        this.setState({ error_postal_code: false });
      } else {
        this.setState({ error_postal_code: true });
      }

      if(value.length >=6 && value.length == 6){

        var urlShringTxt =  "https://developers.onemap.sg/commonapi/search?returnGeom=Y&getAddrDetails=Y&searchVal="+value;
       
        axios.get(urlShringTxt).then((res) => {

          if(Object.keys(res.data.results).length > 0) {

            var outltResulSet = res.data.results[0];

            if (Object.keys(outltResulSet).length > 0) {
            var outletAddress =  outltResulSet.ROAD_NAME;
            var block_no =  outltResulSet.BLK_NO;
            this.setState({ address: outletAddress, block_no: block_no });
            }    

          }

        });
        
      }
      this.setState({ postal_code: value });
      
    }

     if (event.target.name === "address") {
        if (value !== "") {
          this.setState({ error_address: false });
        } else {
          this.setState({ error_address: true });
        }
        this.setState({ address: value });
      }


        if (event.target.name === "block_no") {
        if (value !== "") {
          this.setState({ error_block_no: false });
        } else {
          this.setState({ error_block_no: true });
        }
        this.setState({ block_no: value });
      }

       if (event.target.name === "unit_no") {
        if (value !== "") {
          this.setState({ error_unit_no: false });
        } else {
          this.setState({ error_unit_no: true });
        }
        this.setState({ unit_no: value });
      }

    if (event.target.name === "totalOulet") {
      if (this.state.paymentstatus !== "Paid") {
        this.setState({ totalOulet : value });
      }
    }

  }


  handleSelectChange(name, event) {
    this.setState({ [name]: event });
  }

  updateInfo(type, event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      event.preventDefault();
    }

    if (type === 1) {

    if (this.state.country.value === "" || this.state.country.value === null || this.state.country.value === undefined) {
      //this.setState({ error_postal_code: true });
       this.setState({ error_get_started_fields: true});
       return false;
    } else {
       this.setState({ error_get_started_fields: false});

    }

    if (this.state.company_name === "" || this.state.company_name === null) {
      this.setState({ error_company_name: true, error_get_started_fields: true});
      return false;
    } else {
      this.setState({ error_get_started_fields: false});

    }

    if (this.state.postal_code === "" || this.state.postal_code === null) {
      this.setState({ error_postal_code: true, error_get_started_fields: true  });
      return false;
      
    } else {
      this.setState({ error_postal_code: false, error_get_started_fields: false});
    }

    if (this.state.block_no === "" || this.state.block_no === null) {
      this.setState({ error_block_no: true, error_get_started_fields: true });
      return false;
      
    } else {
      this.setState({ error_block_no: false, error_get_started_fields: false});
    }

    if (this.state.unit_no === "" || this.state.unit_no === null) {
      this.setState({ error_unit_no: true,error_get_started_fields: true});
      return false;
     
    } else {
      this.setState({ error_unit_no: false, error_get_started_fields: false });
     
    }

    if (this.state.totalOulet === "" || this.state.totalOulet === null) {
      this.setState({ error_totalOulet: true, error_get_started_fields: true});
      return false;
    } else {
      this.setState({ error_totalOulet: false,error_get_started_fields: false});
      
    }

     if(this.state.error_get_started_fields == false){

      this.setState({ error_get_started_fields: false });
      var postObject = {
        company_name: this.state.company_name,
        country: this.state.country.value,
        postal_code: this.state.postal_code,
        address: this.state.address,
        block_no: this.state.block_no,
        unit_no: this.state.unit_no,
        totalOulet: this.state.totalOulet,
        step: 1,
        userID: cookie.load("userID"),
      };
      showLoader("step1", "class");
    }

    } else if (type === 2) {
      var postObject = {
        step: 2,
        onboarding_type: this.state.onboarding_type,
        userID: cookie.load("userID"),
      };
    } else if (type === 3) {
      //this.loadUserDetails();
      var postObject = {
        step: 3,
        userID: cookie.load("userID"),
        totalOulet: this.state.totalOulet,

      };
    }

    axios
      .post(apiUrl + "onboarding/updateinfo", qs.stringify(postObject))
      .then((res) => {
        if (type === 1) {
          hideLoader("step1", "class");
        }
        if (res.data.status === "success") {
          var nexStep = parseInt(type) + 1;
          this.setState({ step: nexStep });
          this.loadUserDetails();
          showCustomAlert("success", res.data.message);
        } else {
          showCustomAlert("error", res.data.message);
          
        }
      });
  }

  selectBackage(type) {

  if (this.state.paymentstatus == "Pending") {
    this.setState({ onboarding_type: type, step: 3 }, function () { 
        this.calculatePrice();  
        this.updateInfo(2); 
      });
  }

  }

  incQty(type) {
    if (this.state.paymentstatus !== "Paid") {
      var totalOulet = this.state.totalOulet;
      if (type === "inc") {
        totalOulet = parseInt(totalOulet) + 1;
      } else {
        totalOulet = parseInt(totalOulet) - 1;
        if (totalOulet === 0) {
          totalOulet = 1;
        }
      }

      this.setState({ totalOulet: totalOulet }, function () {
        this.calculatePrice();
      });
    }
  }

  calculatePrice() {

    if(this.state.onboarding_type == "NinjaPro"){

      var outletpricelist = this.state.outletlistprice;

      if(Object.keys(outletpricelist).length > 0){

           var totalOulet = this.state.totalOulet;
           var onboard_totalPrice = parseFloat(outletpricelist[0].settings_pro_first_outlet_price) * 12;
           var totalPrice = parseFloat(outletpricelist[0].settings_pro_first_outlet_price) * 12;

           if(this.state.totalOulet > 1){

           var each_total_outlet = this.state.totalOulet - 1;

           var eachoutleprice_total_price = parseFloat(outletpricelist[0].settings_pro_each_outlet_price) * 12 * each_total_outlet;

           var totalPrice_outlet = totalPrice + eachoutleprice_total_price;
           
           }else{

            var totalPrice_outlet = totalPrice;
           }
           
           var onboard_totalPrice = parseFloat(outletpricelist[0].settings_pro_first_outlet_price) * 12;

            this.setState({ totalPrice: totalPrice_outlet, firstOutletprice : outletpricelist[0].settings_pro_first_outlet_price, 
                      eachoutleprice: outletpricelist[0].settings_pro_each_outlet_price, onboard_totalPrice: onboard_totalPrice});
      }

    }else if(this.state.onboarding_type == 'Ninja Enterprise'){

      var outletpricelist = this.state.outletlistprice;

      if(Object.keys(outletpricelist).length > 0){

      var totalOulet = this.state.totalOulet;

      var totalPrice = parseFloat(outletpricelist[0].settings_enterprise_first_outlet_price) * 12;

      if(this.state.totalOulet > 1){

      var each_total_outlet = this.state.totalOulet - 1;

      var eachoutleprice_total_price = parseFloat(outletpricelist[0].settings_enterprise_each_outlet_price) * 12 * each_total_outlet;

      var totalPrice_outlet = totalPrice + eachoutleprice_total_price;

      }else{

      var totalPrice_outlet = totalPrice;

      }

      var onboard_totalPrice = parseFloat(outletpricelist[0].settings_enterprise_first_outlet_price) * 12;

      this.setState({ totalPrice: totalPrice_outlet, firstOutletprice : outletpricelist[0].settings_enterprise_first_outlet_price, 
                      eachoutleprice: outletpricelist[0].settings_enterprise_each_outlet_price, onboard_totalPrice: onboard_totalPrice});
      }

    }
   
  }

  /* post stripe account */
  onToken = (token) => {
    $.magnificPopup.open({
      items: {
        src: ".processing",
      },
      type: "inline",
      midClick: true,
      closeOnBgClick: false,
    });

    var payAmount = this.state.totalPrice.toFixed(2);

    /*  load process html */
    var postObject = {};
    postObject = {
      token: token.id,
      stripe_envir: this.state.stripe_envir_mode,
      stripe_key: stripeKey,
      userID: cookie.load("userID"),
      paid_amount: payAmount,
      company_name: this.state.company_name,
      payment_reference: stripeReference,
      reference_number: 123456,
    };

    axios
      .post(apiUrl + "onboarding/stripeTokenPay", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var captureID = res.data.result_set.payment_reference_1;
          this.captureAmount(captureID);
          this.setState({ validateimage: tickImage });
        } else if (res.data.status === "error") {
          $.magnificPopup.close();
          this.paymentFail(
            "Error",
            "Error code: 1001 Oops! Something went wrong! Please try again."
          );
          $.magnificPopup.open({
            items: {
              src: ".warining_popup",
            },
            type: "inline",
          });
          return false;
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        this.paymentFail(
          "Error",
          "Error code: 1002 Oops! Unable to connect to server. Please try again."
        );
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });
  };

  /* Capture amount */
  captureAmount(captureID) {
    var cabtureObjects = {};

    cabtureObjects = {
      payment_reference: stripeReference,
      stripe_envir: this.state.stripe_envir_mode,
      userID: cookie.load("userID"),
      token: captureID,
    };
    axios
      .post(apiUrl + "onboarding/captureAmount", qs.stringify(cabtureObjects))
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.setState({ completingpaymentimage: tickImage });
          $.magnificPopup.close();
          this.props.history.push("/dashboard");
        } else if (captureRes.data.status === "error") {
          showStripePopup("Error", "Your order was not successful.");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        } else {
          showStripePopup("Error", "Your order was not successful.");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {});
  }

  /* show online payment mode loading */
  onlinePaymentLoading() {
    var grandTotal = this.state.totalPrice;

    if (parseFloat(grandTotal) > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.validateimage} />
          </div>
          <div className="process_right">
            <h5>Processing</h5>
            <p>Validating card information.</p>
          </div>
        </div>
      );
    }
  }
  /**/

  /* show online payment mode loading */
  amountCaptureLoading() {
    var grandTotal = this.state.totalPrice;

    if (parseFloat(grandTotal) > 0) {
      return (
        <div className="process_col">
          <div className="process_left">
            <img src={this.state.completingpaymentimage} />
          </div>
          <div className="process_right">
            <h5>Processing</h5>
            <p>Completing your online payment.</p>
          </div>
        </div>
      );
    }
  }

  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="button btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    );
  }
  pageReload = () => {
    setTimeout(function () {
      // window.location.reload();
    }, 15000);
  };

  /* stripe description  */
  stripeDescription() {
    return this.state.name;
  }

  checkBillAddr(flag) {
    var isSame = this.state.billing_address_same;
    if(isSame === 'yes') {
      var tempvlu = '';
      this.setState({
        billing_address_same: 'no', billing_checked:false});
    } else {
      this.setState({billing_address_same: 'yes', billing_checked:true}, function () {  this.loadUserDetails(); }.bind(this));
    }
  }

   CheckBillingAddrChk(type) {
    var isSame = this.state.billing_address_same;
    var chkBox = false;
        var diplayTxt = 'block';
    if(isSame === 'yes') {
      chkBox = true;
            diplayTxt = 'none';
    }
    return (type === 'checkbox') ? chkBox : diplayTxt;
  }


  payOrder() {

    var billing_name = this.state.billing_name;
    var billing_phone = this.state.billing_phone;
    var billing_email = this.state.billing_email;
    var billing_address = this.state.billing_address;
    var billing_postal_code = this.state.billing_postal_code;
    var billing_block_no = this.state.billing_block_no;
    var billing_unit_no = this.state.billing_unit_no;

    var error_flag = "No";

    if(billing_name ==''){

      error_flag = "Yes";

    }else if(billing_phone ==''){

      error_flag = "Yes";

    }else if(billing_email ==''){

      error_flag = "Yes";

    }else if(billing_address ==''){

      error_flag = "Yes";

    }else if(billing_postal_code ==''){

      error_flag = "Yes";

    }else if(billing_block_no ==''){

      error_flag = "Yes";

    }else if(billing_unit_no ==''){

      error_flag = "Yes";

    }

    if(error_flag == "Yes"){

      this.setState({   billingaddresserror: true });
    }else{

      this.setState({ billingaddresserror: false });
    }

    if(error_flag == "No"){
    var popupIdTxt = "#pay-conf-popup";
    $.magnificPopup.open({
      items: {
        src: popupIdTxt,
      },
      type: "inline",
      closeOnBgClick: false,
    });
     
   }

  }


 validateIntval(e) {  
    const re = /[0-9]+/g; 
    if (!re.test(e.key)) {  
      e.preventDefault(); 
    } 
  }


  featureshow(event){
   $("#features_list").show();
   $("#features_list_ninja").show();
   $(".hide_btn-plan").show();
   $(".show_btn-plan").hide();
 }

 featureshide(){
   $(".hide_btn-plan").hide();
   $(".show_btn-plan").show();
   $("#features_list").hide();
    $("#features_list_ninja").hide();
  }



  featureshowpsg(event){
   $("#features_list_psg").show();
   $(".hide_btn-plan").show();
   $(".show_btn-plan").hide();
 }

 featureshidepsg(){
   $(".hide_btn-plan").hide();
   $(".show_btn-plan").show();
   $("#features_list_psg").hide();
  }

  updatePageInfo(page_type){

    if(page_type !==""){

      this.setState({
        step: page_type
      });

      var postObject = {
        company_name: this.state.company_name,
        country: this.state.country.value,
        postal_code: this.state.postal_code,
        address: this.state.address,
        block_no: this.state.block_no,
        unit_no: this.state.unit_no,
        totalOulet: this.state.totalOulet,
        step: page_type,
        userID: cookie.load("userID"),
        onboarding_type: this.state.onboarding_type,
        step_type:"back",
      };

      axios
          .post(apiUrl + "onboarding/updateinfo", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "success") {
              this.setState({ step: page_type });
              showCustomAlert("success", res.data.message);
            } else {
              showCustomAlert("error", res.data.message);
              
            }
          });

    }

  }

  InquiryCheck( type){
  var enquiry_type = type;

  if(enquiry_type !==""){

    this.setState({ client_enquiry_type: enquiry_type });
  }

   var popupIdTxt = "#enquire-popup";
    $.magnificPopup.open({
      items: {
        src: popupIdTxt,
      },
      type: "inline",
      closeOnBgClick: false,
    });

  }

enquirepopupClose(event){

  event.preventDefault();

   $.magnificPopup.close();
}

enquirenotifysend(event){
  event.preventDefault();

  var user_id = this.state.userID;
  var postObject = {
          user_id: this.state.userID,
          client_enquiry_type: this.state.client_enquiry_type,
          client_onboarding_enquriy_type: "Enquiry"
        
        };
  axios.post(apiUrl + "onboarding/enquiry_sumbit", qs.stringify(postObject))
    .then((res) => {
      if (res.data.status === "ok") {
         $.magnificPopup.close();
      } 
    });
}

openvideoPopup(event){

  if(event !==''){

    if(event == 1){

      this.setState({ninja_video_site_url: "https://www.youtube.com/embed/IZRJu4ZCs44"});

    }else if(event == 2){

      this.setState({ninja_video_site_url: "https://www.youtube.com/embed/mmlM0VSh-BE"});

    }else if(event == 3){

      this.setState({ninja_video_site_url: "https://www.youtube.com/embed/u_CdRNjYl3o"});
    }

     $("#video-popup").modal("show");
  }
}

  render() {
    const settings_plan = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      margin: 10,
      autoplay: false,
      dots: false,
      arrows: false,
      responsive: [
  {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
      },
    },
  ],
    };

    const settings_mobile_plan = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      margin: 10,
      autoplay: false,
      dots: false,
      arrows: false,
      responsive: [
  {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
      },
    },
  ],
    };

    if (Object.keys(this.state.userDetails).length > 0) {
      return (<div>
        <div className="dashboard-mobilemain">
        <div className="dashboard-mobilemenu">
          <div className="menu_icon trigger_menunav_act">
            <span className="icon-bar icon-bar1"></span>
            <span className="icon-bar icon-bar2"></span>
            <span className="icon-bar icon-bar3"></span>
          </div>

          <div className="mobile-nav-logo">
            <a href="#">
              {" "}
              <img src={mobilelogo} />{" "}
            </a>
          </div>

          <div className="mobile-menu">
            <div className="mobile-menu-header">
              <div className="mobile-menu-close">
                <span id="close_mobile_menu"></span>
              </div>
              <div className="mobile-menu-logo">
                <a title="Cake Avenue" href="/">
                  <img src={mobilelogo} />
                </a>
              </div>
              
            </div>
            <div className="mobile-menu-body">
              <ul className="menu">
              {cookie.load("onboarding_payment_status") === "Pending" && (
              <li className={this.state.currentPage === "/getstart" ? "active" : ""}>
                <Link to="/getstart">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={getstartImg} />
                    <img className="order_type_imgwt" src={getstartWImg} />
                  </div>
                  <div className="cat-content">
                    Get Started
                  </div>
                </div>
                </Link>{" "}
              </li>
              )}
              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={ this.state.currentPage === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={mdetailsImg} />
                      <img className="order_type_imgwt" src={mdetailsWImg} />
                    </div>
                    <div className="cat-content">
                      Dashboard
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={this.state.currentPage === "/notifications" ? "active" : ""}>
                <Link to="/notifications">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={notficationImg} />
                      <img className="order_type_imgwt" src={notficationWImg} />
                    </div>
                    <div className="cat-content">
                      notifications
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={this.state.currentPage === "/subscriptions" ? "active" : ""}>
                <Link to="/subscriptions">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={subscriptionsImg} />
                      <img className="order_type_imgwt" src={subscriptionsWImg} />
                    </div>
                    <div className="cat-content">
                      subscriptions
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              <li className={this.state.currentPage === "/my-details" ? "active" : ""}>
                <Link to="/my-details">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={dashboardImg} />
                      <img className="order_type_imgwt" src={dashboardWImg} />
                    </div>
                    <div className="cat-content">
                      My Details
                    </div>
                  </div>
                </Link>{" "}
              </li>

              <li className={this.state.currentPage === "/plan" ? "active" : ""}>
                <Link to="/plan">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={planImg} />
                      <img className="order_type_imgwt" src={planWImg} />
                    </div>
                    <div className="cat-content">
                       Plan
                    </div>
                  </div>
                </Link>{" "}
              </li>
              
              <li>
                <a href="https://support.ninjaos.com/" target="_blank">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={helpImg} />
                      <img className="order_type_imgwt" src={helpWImg} />
                    </div>
                    <div className="cat-content">
                       Help
                    </div>
                  </div>
                </a>{" "}
              </li>

              <li>
                <Link to="/logout">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={logoutImg} />
                      <img className="order_type_imgwt" src={logoutWImg} />
                    </div>
                    <div className="cat-content">
                       Logout
                    </div>
                  </div>
                </Link>{" "}
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>

        <div className="dashboard">
          <div className="dashboard-inner">
            <Sidebar {...this.state} accountType={this.state.paymentstatus !=='' ? this.state.paymentstatus : ""} />

            <div className="dashboard-content">
              {/*<ul className="stepper-wrapper active-first">
                <li
                  className={
                    this.state.step === 1 ||
                    this.state.step === 2 ||
                    this.state.step === 3 ||
                    this.state.step === 4
                      ? "stepper-item active"
                      : "stepper-item"
                  }
                >
                  <div className="step-counter">1</div>
                </li>
                <li
                  className={
                    this.state.step === 2 ||
                    this.state.step === 3 ||
                    this.state.step === 4
                      ? "stepper-item active"
                      : "stepper-item "
                  }
                >
                  <div className="step-counter">2</div>
                </li>
                <li
                  className={
                    this.state.step === 3 || this.state.step === 4
                      ? "stepper-item active"
                      : "stepper-item "
                  }
                >
                  <div className="step-counter">3</div>
                </li>
                <li
                  className={
                    this.state.step === 4
                      ? "stepper-item active"
                      : "stepper-item "
                  }
                >
                  <div className="step-counter">3</div>
                </li>
                <li
                  className={
                    this.state.step === 4
                      ? "stepper-item active"
                      : "stepper-item "
                  }
                >
                  <div className="step-counter">4</div>
                </li>
              </ul>*/}

              {(this.state.step === 1 ||
                this.state.step === 2 ||
                this.state.step === 3) && (
                <div className="getstarted">
                <div className="getstarted-inner">
                  {this.state.step === 1 && (
                    <>
                      <div className="getstarted-lhs">
                        {/* =========Get Started Start==========  */}

                        <div>
                          <div className="getstarted-header">
                            <h2>Lets Get Started</h2>
                            <p>Your Restaurant Informations</p>
                          </div>
                          <div className="getstarted-form">

                            <div className="control-group control-country">
                              <Select
                                onChange={this.handleSelectChange.bind(
                                  this,
                                  "country"
                                )}
                                value={this.state.country}
                                options={this.state.countryList}
                              />
                              <label className="control-label">Choose Your Country</label>
                            </div>

                            <div
                              className={
                                this.state.company_name !== ""
                                  ? "control-group focus-out focused"
                                  : "control-group"
                              }
                            >
                              <input
                                type="text"
                                className="input-box input-focus"
                                name="company_name"
                                value={this.state.company_name}
                                onChange={this.handleTextChange_getStarted.bind(this)}
                              />
                              <label className="control-label">
                                Your Business Name
                              </label>
                            </div>

                            <div
                              className={
                                this.state.postal_code !== ""
                                  ? "control-group focus-out focused"
                                  : "control-group"
                              }
                            >
                              <input
                                type="text"
                                className="input-box"
                                name="postal_code"
                                value={this.state.postal_code}
                                onChange={this.handleTextChange_getStarted.bind(this)}
                              />
                              <label className="control-label">
                                Postal Code
                              </label>
                            </div>
                            <div
                              className={
                                this.state.address !== ""
                                  ? "control-group focus-out focused"
                                  : "control-group"
                              }
                            >
                              <input
                                type="text"
                                className="input-box"
                                name="address"
                                value={this.state.address}
                                onChange={this.handleTextChange_getStarted.bind(this)}
                              />
                              <label className="control-label">
                                Address Line
                              </label>
                            </div>
                            <div className="two-col">
                              <div
                                className={
                                  this.state.block_no !== ""
                                    ? "control-group unit-no1 focus-out focused"
                                    : "control-group unit-no1"
                                }
                              >
                                <input
                                  type="text"
                                  className="input-box"
                                  name="block_no"
                                  value={this.state.block_no}
                                  onChange={this.handleTextChange_getStarted.bind(this)}
                                />
                                <label className="control-label">
                                  Block No
                                </label>
                              </div>
                              <div
                                className={
                                  this.state.unit_no !== ""
                                    ? "control-group unit-no2 focus-out focused"
                                    : "control-group unit-no2"
                                }
                              >
                                <input
                                  type="text"
                                  className="input-box"
                                  name="unit_no"
                                  value={this.state.unit_no}
                                  onChange={this.handleTextChange_getStarted.bind(this)}
                                />
                                <label className="control-label">Unit No</label>
                              </div>
                            </div>

                            <div className="outlet-number get-outlet">
                              <p>Choose number of outlets</p>
                              <div class="qty_bx">
                                <span
                                  class="qty_minus"
                                  onClick={this.incQty.bind(this, "dec")}
                                >
                                  -
                                </span>
                                <input
                                  type="text"
                                  value={this.state.totalOulet}
                                  name="totalOulet"
                                  onChange={this.handleTextChange_getStarted.bind(this)}
                                />
                                <span
                                  class="qty_plus"
                                  onClick={this.incQty.bind(this, "inc")}
                                >
                                  +
                                </span>
                              </div>
                            </div>

                            <div className="btn-single">
                              <a
                                className="button step1"
                                href={void 0}
                                onClick={this.updateInfo.bind(this, 1)}
                              >
                                done
                              </a>
                         
                            </div>
                             
                          </div>
                         
                        </div>

                        {this.state.error_get_started_fields == true && (
                            <p className="error_para">Please fill all fields</p>
                         )}

                        {/* =========Get Started End==========  */}
                      </div>

                      <div className="getstarted-rhs">
                        <img src={started} />
                      </div>
                    </>
                  )}

                  {/* =========Package Start==========  */}
                  {this.state.step === 2 && (
                    <div className="your-package">
                      <h1>Choose Your Package</h1>
                      <ul>
                        <li
                          className={
                            this.state.onboarding_type === "NinjaPro"
                              ? "active"
                              : ""
                          }
                          onClick={this.selectBackage.bind(this, "NinjaPro")}
                        >
                          <div className="package-img">
                            <img src={planone} />
                          </div>
                          <div className="package-content">
                            <h3>NinjaPro</h3>
                            <span>
                              Single Store, Multi Store, Grocery, Hotels
                            </span>
                            <p>
                              Template based mobile web ordering system with
                              CRM, Loyalty and Analytics.
                            </p>
                          </div>

                          <div className="package-li-btn">
                            <a href="#" className="button package-btn">
                              Learn More
                            </a>
                          </div>
                        </li>
                        <li
                          className={
                            this.state.onboarding_type === "Ninja Enterprise"
                              ? "active"
                              : ""
                          }
                          onClick={this.selectBackage.bind(
                            this,
                            "Ninja Enterprise"
                          )}
                        >
                          <div className="package-img">
                            <img src={plantwo} />
                          </div>
                          <div className="package-content">
                            <h3>NinjaEnterprise Pro</h3>
                            <span>
                              Single Store, Multi Store, Grocery, Hotels
                            </span>
                            <p>
                              Custom designed web app ordering system with Multi
                              Tier CRM, Loyalty and Analytics. Extensive
                              integration and customisations available to suit
                              your business requirements.
                            </p>
                          </div>

                          <div className="package-li-btn">
                            <a href="#" className="button package-btn">
                              Learn More
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="package-img">
                            <img src={planthree} />
                          </div>
                          <div className="package-content">
                            <h3>CRM</h3>
                            <span>Loyalty & Payments</span>
                            <p>
                              Setup and launch a cashback loyalty system for
                              your store today. Accept payments and build a
                              loyal customer base today !
                            </p>
                          </div>

                          <div className="package-li-btn">
                            <a href="#" className="button package-btn">
                              Coming Soon
                            </a>
                          </div>
                        </li>
                      </ul>
                       <div className="back-package choose-btn">
                        <a href={void 0} onClick= {this.updatePageInfo.bind(this, 1)} className="button"> back</a>
                      </div>
                    </div>
                    )}
                  {/* =========Package End==========  */}
                </div>
              </div>
              )}

              {this.state.step === 3 && this.state.onboarding_type == "NinjaPro" &&(

                <div className="package-details ninjapro-div">
                  <div className="package-details-top">
                    <div className="pdt-lhs">
                      <img src={detailpro} />
                    </div>
                    <div className="pdt-rhs">
                      <div className="pdt-rhs-top">
                        <h1>{this.state.onboarding_type == "Ninja Enterprise" ? "NinjaEnterprise Lite" : "NinjaPro"}</h1>
                        <h3>Turnkey Ordering System For Your F&B Store</h3>
                        
                        <em>Single Store, Multi Store, Grocery, Hotels</em>
                        <p>
                          Template based mobile web ordering system <br />
                          with CRM, Loyalty and Analytics.
                        </p>
                      </div>
                      <div className="pdt-rhs-btm">
                        <h4>See How It Works</h4>
                        <div>
       
                          <Slider {...settings_plan}>
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this, "1")}>{" "}
                                <img src={deliveryImg} />
                                <img className="video-btn-slider" src={videobtnImg} /> {" "}</a>
                            </div>
                            </div>
                            
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this,"2")}>{" "}
                              <img src={takeawayImg} />
                              <img className="video-btn-slider" src={videobtnImg} /> {" "}</a>
                            </div>
                            </div>
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this, "3")}>{" "}
                              <img src={dineinImg} />
                              <img className="video-btn-slider" src={videobtnImg} />{" "}</a>
                            </div>
                            </div>

                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* =========Plan Package Choosing Start==========  */}

                  <div className="package-details-btm">
                    <div className="package-main-box">
                      <div className="package-details-main">
                        <div className="package-details-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h1>Choose Plan</h1>
                                <h4>you will get</h4>
                              </div>
                            </li>
                          </ul>

                          <ul className="package-details-bottom">
                            <li>delivery</li>
                            <li>takeaway</li>
                            <li>dine in</li>
                            <li>reservation</li>
                            <li>cash back loyalty</li>
                            <li>e vouchers</li>
                            <li>no commissions</li>
                            <li>unlimited orders</li>
                            <li>no of products</li>
                            <li>promo codes</li>
                            <li>CRM</li>
                            <li>menu setup (Done for you)</li>
                            <li>payment gateway setup</li>
                            <li>delivery integration</li>
                            <li>email support</li>
                            <li>phone support</li>
                        </ul>
                        </div>
                      </div>

                      <div className="back-package">
                        <a href={void 0} onClick= {this.updatePageInfo.bind(this, 2)} className="button"> back</a>
                      </div>
                    </div>

                    <div className="package-middle-container destop-package-middle">
                      <div className="package-details-middle">
                        <div className="package-middle-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h4>{this.state.onboarding_type !=="" ? this.state.onboarding_type: ""}</h4>
                                <p>single store, multi store grocery, hotels</p>
                              </div>
                            </li>
                            <li>
                              <div className="package-headingcontent">
                               <h6>per outlet</h6>
                               <h1><sup>$</sup>{showPriceValue(this.state.totalPrice)} </h1>
                                <p>monthly${showPriceValue(this.state.firstOutletprice)}</p>
                              </div>
                            </li>
                          </ul>

                          <ul className="package-priceul">
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li>200 products</li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                          </ul>

                          <div className="outlet-price-btn">
                            <div className="outlet-number">
                              <p>Outlets</p>
                              <div class="qty_bx">
                                <span class="qty_minus" onClick={this.incQty.bind(this, "dec")}> - </span>
                                <input
                                  type="text"
                                  value={this.state.totalOulet}
                                  name="totalOulet"
                                  onChange={this.handleTextChange.bind(this)}
                                />
                                <span class="qty_plus" onClick={this.incQty.bind(this, "inc")}> + </span>
                              </div>
                            </div>

                            <div className="black-part">
                              <a href={void 0} onClick={this.updateInfo.bind(this, 3)} className="button">Checkout</a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="package-details-lower">
                        <div className="package-middle-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h4>{this.state.onboarding_type !=="" ? this.state.onboarding_type: ""} PSG</h4>
                              <p>single store, multi store grocery, hotels</p>
                              </div>
                            </li>
                            <li>
                              <div className="package-headingcontent">
                                   <h6 className="p_middle">1 year license for 1 outlet</h6>
                                   <h1 className="p_middle_price"><sup>$</sup>3,300.00</h1>
                               </div>
                            </li>
                          </ul>

                          <ul className="package-priceul">
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li>200 products</li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                          </ul>
                          <div className="black-part inquire-part">
                          <a className="bod_link ninjapro" href="https://www.gobusiness.gov.sg/images/psg/Jankosoft_20200023_Annex_3_20200625144914_Part_1.pdf" target="_blank">learn more</a>
                            <a className="button" onClick= {this.InquiryCheck.bind(this, "NinjaPro")}>inquire now</a>
                          </div>
                        </div>
                      </div>
                    </div>

                     <div className="package-middle-container mobile-package-middle">
                      <Slider {...settings_mobile_plan}>
                        <div>{/* =========1st SLIDER START==========  */}
                          <div className="package-details-middle">
                            <div className="package-middle-header">
                              <ul>
                                <li>
                                  <div className="package-headingtop">
                                   <h4>{this.state.onboarding_type !=="" ? this.state.onboarding_type: ""}</h4>
                                    <p>single store, multi store grocery, hotels</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="package-headingcontent">
                                   <h6>per outlet</h6>
                                   <h1><sup>$</sup>{showPriceValue(this.state.onboard_totalPrice)} </h1>
                                   <p>monthly${showPriceValue(this.state.firstOutletprice)}</p>
                                  </div>
                                </li>
                              </ul>

                              <ul className="package-priceul">
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li>200 products</li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                              </ul>

                              <div className="outlet-price-btn">
                                <div className="outlet-number">
                                  <p>Outlets</p>
                                  <div class="qty_bx">
                                    <span class="qty_minus" onClick={this.incQty.bind(this, "dec")}> - </span>
                                    <input
                                      type="text"
                                      value={this.state.totalOulet}
                                      name="totalOulet"
                                      onChange={this.handleTextChange.bind(this)}
                                    />
                                    <span class="qty_plus" onClick={this.incQty.bind(this, "inc")}>
                                      +
                                    </span>
                                  </div>
                                </div>
                                <div className="black-part">
                                  <a href={void 0} onClick={this.updateInfo.bind(this, 3)} className="button">Checkout</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>{/* =========2nd SLIDER START==========  */}
                          <div className="package-details-lower">
                            <div className="package-middle-header">
                              <ul>
                                <li>
                                  <div className="package-headingtop">
                                   <h4>NinjaPro PSG</h4>
                                    <p>single store, multi store grocery, hotels</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="package-headingcontent">
                                   <h6 className="p_middle">1 year license for 1 outlet</h6>
                                   <h1 className="p_middle_price"><sup>$</sup>3,300.00 </h1>
                                  </div>
                                </li>
                              </ul>

                              <ul className="package-priceul">
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li>200 products</li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                              </ul>
                              <div className="black-part inquire-part">
                              <a className="bod_link ninjapro" href="https://www.gobusiness.gov.sg/images/psg/Jankosoft_20200023_Annex_3_20200625144914_Part_1.pdf" target="_blank">learn more</a>
                                <a className="button" onClick= {this.InquiryCheck.bind(this, 'NinjaPro')}>inquire now</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>

                  </div>{/* =========Plan Package Choosing End==========  */}

                  <div className="plan-chose-maindiv">
                    <div className="plan-chose-hedaingdiv">
                      <h1>Choose Plan</h1>
                      <h4>you will get</h4>
                    </div>

                    <div className="plan-chose-innerdiv">
                      <div className="plan-chose-innerlhs">
                        <div className="plan-chose-innertop">
                          <h4>{this.state.onboarding_type !=="" ? this.state.onboarding_type: ""}</h4>
                          <p>single store, multi store grocery, hotels</p>
                        </div>

                        <div className="plan-chose-innerprice">
                          <h6>per outlet</h6>
                          <h1><sup>$</sup>1,260 </h1>
                          <p>monthly $105</p>
                        </div>

                        <div className="plan-drop-maindiv">
                          <div className="plan-drop-btn">
                            <a class="button show_btn-plan" onClick={this.featureshow.bind(this)}> Show Features</a>

                            <ul className="plan-details-div" id="features_list">
                              <li>delivery</li>
                              <li>takeaway</li>
                              <li>dine in</li>
                              <li>reservation</li>
                              <li>cash back loyalty</li>
                              <li>e vouchers</li>
                              <li>no commissions</li>
                              <li>unlimited orders</li>
                              <li>no of products</li>
                              <li>promo codes</li>
                              <li>CRM</li>
                              <li>menu setup (Done for you)</li>
                              <li>payment gateway setup</li>
                              <li>delivery integration</li>
                              <li>email support</li>
                              <li>phone support</li>
                            </ul>
                            <a class="button hide_btn-plan" onClick={this.featureshide.bind(this)}>Hide Features</a>
                            <div className="outlet-price-btn">
                              <div className="outlet-number">
                                <p>Outlets</p>
                                <div class="qty_bx">
                                  <span class="qty_minus" onClick={this.incQty.bind(this, "dec")}> - </span>
                                  <input
                                    type="text"
                                    value={this.state.totalOulet}
                                    name="totalOulet"
                                    onChange={this.handleTextChange.bind(this)}
                                  />
                                  <span class="qty_plus" onClick={this.incQty.bind(this, "inc")}> + </span>
                                </div>
                              </div>

                              <div className="black-part show_btn_div">
                                <a href={void 0} onClick={this.updateInfo.bind(this, 3)} className="button"> Checkout</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="plan-chose-innerlhs">
                        <div className="plan-chose-innertop">
                          <h4>{this.state.onboarding_type !=="" ? this.state.onboarding_type: ""} PSG</h4>
                          <p>single store, multi store grocery, hotels</p>
                        </div>

                        <div className="plan-chose-innerprice">
                          <h6>per outlet</h6>
                          <h1><sup>$</sup>3,300.00 </h1>
                        </div>

                        <div className="plan-drop-maindiv">
                          <div className="plan-drop-btn">
                            <a class="button show_btn-plan" onClick={this.featureshowpsg.bind(this)}> Show Features</a>

                            <ul className="plan-details-div" id="features_list_psg">
                              <li>delivery</li>
                              <li>takeaway</li>
                              <li>dine in</li>
                              <li>reservation</li>
                              <li>cash back loyalty</li>
                              <li>e vouchers</li>
                              <li>no commissions</li>
                              <li>unlimited orders</li>
                              <li>no of products</li>
                              <li>promo codes</li>
                              <li>CRM</li>
                              <li>menu setup (Done for you)</li>
                              <li>payment gateway setup</li>
                              <li>delivery integration</li>
                              <li>email support</li>
                              <li>phone support</li>
                            </ul>


                            <a class="button hide_btn-plan" onClick={this.featureshidepsg.bind(this)}>Hide Features</a>
                            <div className="black-part inquire-plan-part">
                              <a href={void 0} onClick= {this.InquiryCheck.bind(this, 'NinjaPro')} className="button">inquire now</a>
                            </div>

                          </div>

                          <div className="back-package">
                          <a href={void 0} onClick= {this.updatePageInfo.bind(this, 2)} className="button"> back</a>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                )}



                {this.state.step === 3 && this.state.onboarding_type == "Ninja Enterprise" && (


                <div className="package-details enterpriselite-div">
                  <div className="package-details-top">
                    <div className="pdt-lhs">
                      <img src={enterpriseImg} />
                    </div>
                    <div className="pdt-rhs">
                      <div className="pdt-rhs-top">
                        <h1>{this.state.onboarding_type == "Ninja Enterprise" ? "NinjaEnterprise Lite" : "NinjaPro"}</h1>
                        <h3>advanced digital solution</h3>
                        <em>Single Store, Multi Store, Grocery, Hotels</em>
                        <p>
                          Template based mobile web ordering system <br />
                          with CRM, Loyalty and Analytics.
                        </p>
                      </div>
                      <div className="pdt-rhs-btm">
                        <h4>See How It Works</h4>
                        <div>
                          <Slider {...settings_plan}>
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this, "1")}>{" "}
                                <img src={deliveryImg} />
                                <img className="video-btn-slider" src={videobtnImg} /> {" "}</a>
                            </div>
                            </div>
                            
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this,"2")}>{" "}
                              <img src={takeawayImg} />
                              <img className="video-btn-slider" src={videobtnImg} /> {" "}</a>
                            </div>
                            </div>
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this, "3")}>{" "}
                              <img src={dineinImg} />
                              <img className="video-btn-slider" src={videobtnImg} />{" "}</a>
                            </div>
                            </div>

                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* =========Plan Package Choosing Start==========  */}

                  <div className="package-details-btm">
                    <div className="package-main-box enterprice-mainbox">
                      <div className="package-details-main enterprice-details-main">
                        <div className="package-details-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h1>Choose Plan</h1>
                                <h4>you will get</h4>
                              </div>
                            </li>
                          </ul>

                          <ul className="package-details-bottom enterprice-details-bottom">
                            <li className="package-details-li">1 x Point of sale (POS), Printer and Cashdrawer</li>
                            <li className="package-details-li">1 x Xero Account Software</li>
                            <li className="package-details-li">1 x Template Based Online Ordering Website</li>
                            <li>delivery</li>
                            <li>takeaway</li>
                            <li>dine in</li>
                            <li>reservation</li>
                            <li>cash back loyalty</li>
                            <li>e vouchers</li>
                            <li>no commissions</li>
                            <li>unlimited orders</li>
                            <li>no of products</li>
                            <li>promo codes</li>
                            <li>CRM</li>
                            <li>menu setup (Done for you)</li>
                            <li>payment gateway setup</li>
                            <li>delivery integration</li>
                            <li>email support</li>
                            <li>phone support</li>
                        </ul>
                        </div>
                      </div>

                      <div className="back-package">
                        <a href={void 0} onClick= {this.updatePageInfo.bind(this, 2)} className="button"> back</a>
                      </div>
                    </div>

                    <div className="package-middle-container destop-package-middle enterprise-middle-div">
                      <div className="package-details-lower enterprice-details-lower">
                        <div className="package-middle-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h4>NinjaLite ADS Package</h4>
                              <p>single store, multi store grocery, hotels</p>
                              </div>
                            </li>
                            <li>
                              <div className="package-headingcontent">
                                   <h6 className="p_middle">1 year license for 1 outlet</h6>
                                   <h1 className="p_middle_price"><sup>$</sup>2,320 </h1>
                               </div>
                            </li>
                          </ul>

                          <ul className="package-priceul enterprice-priceul">
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li>200 products</li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                          </ul>
                          <div className="black-part inquire-part epric-btn">
                             <a className="bod_link" href="https://www.imda.gov.sg/advanceddigitalsolutions#Food-Services" target="_blank">learn more</a>
                            <a className="button" onClick= {this.InquiryCheck.bind(this, "Ninja Enterprise")}>inquire now</a>
                          </div>
                        </div>
                      </div>
                    </div>

                     <div className="package-middle-container mobile-package-middle mobile-enterprice-middle">
                      <Slider {...settings_mobile_plan}>
                        <div>{/* =========2nd SLIDER START==========  */}
                          <div className="package-details-lower enterprice-details-lower">
                            <div className="package-middle-header">
                              <ul>
                                <li>
                                  <div className="package-headingtop">
                                   <h4>NinjaLite ADS Package</h4>
                                  <p>single store, multi store grocery, hotels</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="package-headingcontent">
                                       <h6 className="p_middle">1 year license for 1 outlet</h6>
                                       <h1 className="p_middle_price"><sup>$</sup>2,320 </h1>
                                   </div>
                                </li>
                              </ul>

                              <ul className="package-priceul enterprice-priceul">
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li>200 products</li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                              </ul>
                              <div className="black-part inquire-part epric-btn">
                                <a className="bod_link" href="https://www.imda.gov.sg/advanceddigitalsolutions#Food-Services" target="_blank">learn more</a>
                                <a className="button" onClick= {this.InquiryCheck.bind(this, 'Ninja Enterprise')}>inquire now</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>

                  </div>{/* =========Plan Package Choosing End==========  */}

                  <div className="plan-chose-maindiv">
                    <div className="plan-chose-hedaingdiv">
                      <h1>Choose Plan</h1>
                      <h4>you will get</h4>
                    </div>

                    <div className="plan-chose-innerdiv">

                      <div className="plan-chose-innerlhs">
                        <div className="plan-chose-innertop">
                          <h4>NinjaLite ADS Package</h4>
                          <p>single store, multi store grocery, hotels</p>
                        </div>

                        <div className="plan-chose-innerprice">
                          <h6 className="p_middle">1 year license for 1 outlet</h6>
                          <h1><sup>$</sup>2,320 </h1>
                        </div>

                        <div className="plan-drop-maindiv">
                          <div className="plan-drop-btn">
                            <a class="button show_btn-plan" onClick={this.featureshow.bind(this)}> Show Features</a>

                            <ul className="plan-details-div" id="features_list">
                              <li className="package-details-li">1 x Point of sale (POS), Printer and Cashdrawer</li>
                              <li className="package-details-li">1 x Xero Account Software</li>
                              <li className="package-details-li">1 x Template Based Online Ordering Website</li>
                              <li>delivery</li>
                              <li>takeaway</li>
                              <li>dine in</li>
                              <li>reservation</li>
                              <li>cash back loyalty</li>
                              <li>e vouchers</li>
                              <li>no commissions</li>
                              <li>unlimited orders</li>
                              <li>no of products</li>
                              <li>promo codes</li>
                              <li>crm</li>
                              <li>menu setup (done for you)</li>
                              <li>payment gateway setup</li>
                              <li>delivery integration</li>
                              <li>email support</li>
                              <li>phone support</li>
                            </ul>
                            <a class="button hide_btn-plan" onClick={this.featureshide.bind(this)}>Hide Features</a>
                            <div className="black-part inquire-plan-part">
                              <a href={void 0} onClick= {this.InquiryCheck.bind(this, 'Ninja Enterprise')} className="button">inquire now</a>
                            </div>
                          </div>


                        <div className="back-package">
                          <a href={void 0} onClick= {this.updatePageInfo.bind(this, 2)} className="button"> back</a>
                        </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                )}

              
              {/* =========Package Details End==========  */}

              {/* =========Checkout Start==========  */}
              {this.state.step === 4 && (
                <div className="checkout-main-box">
                  <h1>Checkout</h1>
                  <div className="checkout-main">
                    <div className="checkout-main-lhs">
                      <div className="cards">
                        <div className="card-header">Billing Information</div>
                        <div className="card-body">
                          <div class="billing-chk">
                            <label class="inline">
                              I want to use different Billing Informations{" "}
                            </label>
                            <p>
                            <input
                              type="checkbox" onChange={this.checkBillAddr.bind(this)} 
                              checked={this.CheckBillingAddrChk('checkbox')}
                              value=""
                            /></p>
                          </div>
                          <div
                            className={
                              this.state.billing_name !== ""
                                ? "control-group focus-out focused"
                                : "control-group"
                            }
                          >
                            <input
                              type="text"
                              className="input-box"
                              name="billing_name"
                              value={this.state.billing_name}
                              onChange={this.handleTextChange.bind(this)}
                              disabled = {this.state.billing_checked === true ? "disabled" : ""}

                            />
                            <label className="control-label">Your Name</label>
                          </div>

                          <div
                            className={
                              this.state.billing_phone !== ""
                                ? "control-group focus-out focused"
                                : "control-group"
                            }
                          >
                            <input
                              type="tel"
                              className="input-box"
                              name="billing_phone"
                              value={this.state.billing_phone}
                              onChange={this.handleTextChange.bind(this)}
                              maxLength="8" 
                              disabled = {this.state.billing_checked === true ? "disabled" : ""}
                            />
                            <label className="control-label">
                              Telephone Number
                            </label>
                          </div>

                          <div
                            className={
                              this.state.billing_email !== ""
                                ? "control-group focus-out focused"
                                : "control-group"
                            }
                          >
                            <input
                              type="text"
                              className="input-box"
                              name="billing_email"
                              value={this.state.billing_email}
                              onChange={this.handleTextChange.bind(this)}
                              disabled = {this.state.billing_checked === true ? "disabled" : ""}
                            />
                            <label className="control-label">
                              Email Address
                            </label>
                          </div>

                          <div
                            className={
                              this.state.billing_address !== ""
                                ? "control-group focus-out focused"
                                : "control-group"
                            }
                          >
                            <input
                              type="text"
                              className="input-box"
                              name="billing_address"
                              value={this.state.billing_address}
                              onChange={this.handleTextChange.bind(this)}
                              disabled = {this.state.billing_checked === true ? "disabled" : ""}

                            />
                            <label className="control-label">Address</label>
                          </div>

                          <div className="control-three-merge">
                            <div
                              className={
                                this.state.billing_postal_code !== ""
                                  ? "control-group halfplus focus-out focused"
                                  : "control-group halfplus"
                              }
                            >
                              <input
                                type="text"
                                className="input-box"
                                name="billing_postal_code"
                                value={this.state.billing_postal_code}
                                onChange={this.handleTextChange.bind(this)}
                                disabled = {this.state.billing_checked === true ? "disabled" : ""}

                              />
                              <label className="control-label">
                                Postal Code
                              </label>
                            </div>
                            <div
                              className={
                                this.state.billing_block_no !== ""
                                  ? "control-group half focus-out focused"
                                  : "control-group half"
                              }
                            >
                              <input
                                type="text"
                                className="input-box"
                                name="billing_block_no"
                                value={this.state.billing_block_no}
                                onChange={this.handleTextChange.bind(this)}
                                disabled = {this.state.billing_checked === true ? "disabled" : ""}

                              />
                              <label className="control-label">Block No</label>
                            </div>
                            <div
                              className={
                                this.state.billing_unit_no !== ""
                                  ? "control-group half focus-out focused"
                                  : "control-group half"
                              }
                            >
                              <input
                                type="text"
                                className="input-box"
                                name="billing_unit_no"
                                value={this.state.billing_unit_no}
                                onChange={this.handleTextChange.bind(this)}
                                disabled = {this.state.billing_checked === true ? "disabled" : ""}

                              />
                              <label className="control-label">Unit No</label>
                            </div>
                          </div>
                          {(this.state.billingaddresserror === true) && (
                          <span className="error">Please enter all fields</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="checkout-main-rhs">
                      <div className="cards order-summary">
                        <div className="card-header">Order Summary</div>
                        <div className="card-body">
                          <div className="cart-row">
                            <div className="product-det-lhs">
                              <p>{this.state.onboarding_type !=="" ? this.state.onboarding_type: ""} (1 Year)</p>
                              <span>Outlet : {this.state.totalOulet}</span>
                            </div>
                            <div className="product-det-rhs">
                              <div class="qty_bx">
                                <span
                                  class="qty_minus"
                                  onClick={this.incQty.bind(this, "dec")}
                                >
                                  -
                                </span>
                                <input
                                  type="text"
                                  value={this.state.totalOulet}
                                  name="totalOulet"
                                  onChange={this.handleTextChange.bind(this)}
                                />
                                <span
                                  class="qty_plus"
                                  onClick={this.incQty.bind(this, "inc")}
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <p>Total</p>
                          <p>S$ {showPriceValue(this.state.totalPrice)}</p>
                        </div>
                      </div>
                      {this.state.paymentstatus !== "Paid" && (
                        <div className="cards billing-card">
                          <div className="card-header">Payment</div>
                          <div className="card-body">
                            <h5>Credit / Debit Card</h5>
                            <img src={payment} />
                            <p>
                              Your personal data will be used to process your
                              order, support your experience throughout this
                              website, and for other purposes described in our
                              privacy policy.
                            </p>
                            <input
                              type="submit"
                              className="button"
                              value="Checkout"
                              onClick={this.payOrder.bind(this)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="back-package">
                        <a onClick={this.updatePageInfo.bind(this, 3)} className="button"> back</a>
                   </div>
                  </div>
                </div>
              )}
              {/* =========Checkout End==========  */}
            </div>
          </div>

          {/*Payment confirm popup Start*/}
          <div
            id="pay-conf-popup"
            className="white-popup mfp-hide popup_sec pay-conf-popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header">Payment</div>
                  <div className="alert_body">
                    <p>Awesome ! You are all set</p>
                    <div className="alt_btns">
                      {stripeKey &&
                        this.state.user_email != "" &&
                        this.state.paymentmodevalue == "Stripe" &&
                        this.state.stripe_payment_enable === 1 && (
                          <StripeCheckout
                            name={stripeCompany}
                            image={stripeImage}
                            description={this.stripeDescription()}
                            token={this.onToken}
                            stripeKey={stripeKey}
                            amount={parseInt(this.state.totalPrice * 100)}
                            email={this.state.user_email}
                            currency={stripeCurrency}
                          >
                            {" "}
                            <a
                              href={void 0}
                              className="button"
                              title="Proceed To Payment"
                            >
                              Proceed To Payment
                            </a>
                          </StripeCheckout>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*payment confirm popup - End*/}

          {/*Enquiry Popup*/}
          <div  id="enquire-popup"   className="white-popup mfp-hide popup_sec pay-conf-popup">
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header">Enquiry Submission</div>
                  <div className="alert_body">
                    <div className="alt_btns">
                      <p>Our sales consultant will be in touch with you shortly to share more about the solution.</p>
                        <p>Do you wish to proceed ?</p>

                        <div className="alt_btns">
                          <a onClick={this.enquirenotifysend.bind(this)}  className="button button-right">Yes</a>
                          <a onClick={this.enquirepopupClose.bind(this)} className=" button button-left">No</a>
                        </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Enquiry popup - End*/}


          {/*Enquiry Popup*/}

          <div className="white-popup mfp-hide popup_sec pay-conf-popup">
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header"></div>
                  <div className="alert_body">
                    <div className="alt_btns">
                      <p>

                   
                   </p>
  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Enquiry popup - End*/}


        <div className="modal fade commom-modal-topcls" id="video-popup">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="common-modal-head">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true">
                      </button>
                    </div>
                    <div className="modal-detail-box">
                      <iframe width="560" height="315" src={this.state.ninja_video_site_url+"?controls=0&amp;&amp;autoplay=1&amp;loop=1&amp;mute=1"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div
            id="processing-popup"
            className="white-popup mfp-hide popup_sec processing"
          >
            <div className="pouup_in">
              <h3 className="title1 text-center">
                We Are Processing Your Order
              </h3>
              <div className="process_inner">
                {this.onlinePaymentLoading()}
                {this.amountCaptureLoading()}
              </div>
            </div>
          </div>
          <div id="dvLoading"></div>
        </div>
      </div>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateTopProps = (state) => {
  var outletlistprice = Array();
  if (Object.keys(state.outletprice).length > 0) {
    if (state.outletprice[0].status === "ok") {
      outletlistprice = state.outletprice[0].result;
    }
  }

  return {
    outletlistprice: outletlistprice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadOutletPriceDetails: () => {
      dispatch({ type: GET_OUTET_PRICE });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
