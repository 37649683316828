/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Slider from "react-slick";
import { apiUrl, proapiUrl, apiBaseUrl, proapiBaseUrl, apiBaseUrlEnt,deliveryId,pickupId,reservationId,dineinId } from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  country_symbol,
  stripslashes,
} from "../Helpers/SettingHelper";

import logo from "../../common/images/logo.png";
import BackendImg from "../../common/images/backend.jpg";
import FrontendImg from "../../common/images/frontend.jpg";
import TabcontactImg from "../../common/images/tab-contact.jpg";
import TabImg from "../../common/images/settings.svg";
import TabImg1 from "../../common/images/tab-group.svg";
import TabImg2 from "../../common/images/setup1.svg";
import TabImg3 from "../../common/images/chat-dark.svg";
import sinfo from "../../common/images/settings-info.jpg";
import Back_inImg from "../../common/images/back_copy.png";
import Back_passImg from "../../common/images/passwrd.svg";
import mobilelogo from "../../common/images/logo.png";
import PricetickImg from "../../common/images/blue-tick.png";

import getstartImg from "../../common/images/s_bar2.svg";
import getstartWImg from "../../common/images/s_bar1.svg";
import mdetailsImg from "../../common/images/s_bar4.svg";
import mdetailsWImg from "../../common/images/s_bar3.svg";
import planImg from "../../common/images/s_bar6.svg";
import planWImg from "../../common/images/s_bar5.svg";
import helpImg from "../../common/images/s_bar8.svg";
import helpWImg from "../../common/images/s_bar7.svg";
import logoutImg from "../../common/images/s_bar10.svg";
import logoutWImg from "../../common/images/s_bar9.svg";
import dashboardImg from "../../common/images/s_bar12.svg";
import dashboardWImg from "../../common/images/s_bar11.svg";
import notficationImg from "../../common/images/s_bar14.svg";
import notficationWImg from "../../common/images/s_bar13.svg";
import subscriptionsImg from "../../common/images/s_bar16.svg";
import subscriptionsWImg from "../../common/images/s_bar15.svg";
import FileuploadImg from "../../common/images/download.svg";

import errorImg from "../../common/images/error.svg";
import errorwImg from "../../common/images/error_wrong.svg";

var qs = require("qs");


class Myaccount extends Component {
  constructor(props) {
    super(props);
    var userID =
      cookie.load("userID") !== "" &&
      typeof cookie.load("userID") !== undefined &&
      typeof cookie.load("userID") !== "undefined"
        ? cookie.load("userID")
        : "";
    this.state = {
      step: 1,
      userID: userID,
      country: "",
      countryList: [],
      appid: '',
      backendurl: '',
      userDetails: [],
      user_email: '',
      username: '',
      onboard_user_name: "",
      company_name: '',
      postal_code: '',
      onboarding_country: "",
      address: '',
      block_no: '',
      unit_no: '',
      onboarding_type: '',
      totalOulet: '',
      step: '',
      paymentstatus: '',
      billing_name: '',
      billing_phone: '',
      billing_email: '',
      billing_address: '',
      billing_postal_code: '',
      billing_block_no: '',
      billing_unit_no: '',
      login_customer_password: "",
      customer_password_re: "",
      login_password_exist: "", 
      onboarding_pay_amount: '',
      onboarding_app_id: "",
      onboarding_menu: "",
      client_logo: "",
      client_image_name: "",
      client_image_preview: "",
      client_product_images: "",
      front_end_url: "",
      main_settings_validation: true,
      availability_list: [],
      operational_hours: "",
      dinetablecount: "",
      outletcontactno: "",
      uploadedImageUrl: "",
      product_images: "",
      menuurl: "",
      productImageUrl: "",
      about_us: "",
      terms_and_Conditions: "",
      privacy_policy: "",
      other_commands: "",
      availability_deliveryId: "",
      availability_takeawayId: "",
      availability_dineId: "",
      availability_reservationId: "",
      currentPage: this.props.match.path,
      totalAvailability: [],
      delivery_checked:false,
      pickup_checked:false,
      dinein_checked:false,
      reservation_checked:false,


    };
    if (userID === "") {
      this.props.history.push("/");
    } else {
      this.loadUserDetails();
    }
  }

  loadUserDetails() {

    var urlShringTxt =
      apiUrl + "onboarding/getuserinfo?userID=" + this.state.userID;

    axios.get(urlShringTxt).then((res) => {
      var result = res.data.result_set;
      var step = 1;
      if (result.onboarding_payment_status !== "Paid") {
        step =
          result.onboarding_complete_step !== "" &&
          result.onboarding_complete_step !== null
            ? parseInt(result.onboarding_complete_step) + 1
            : 1;
      }

      if(result.onboarding_payment_status == "Pending" && result.onboarding_payment_status !== "Paid" ) {
        this.props.history.push("/getstart");
      }

      var backendurl = (result.onboarding_type === "Ninja Enterprise") ? apiBaseUrlEnt + "camppanel/login/"+result.onboarding_app_id: proapiBaseUrl + "camppanel/login/"+result.onboarding_app_id;
      
      var menuPdfurl = (result.onboarding_type === "Ninja Enterprise"? apiBaseUrlEnt+"menu" : proapiBaseUrl+"media/menu/");

      this.setState(
        {
          userDetails: res.data.result_set,
          user_email: result.onboarding_email,
          name: result.onboarding_name,
          customer_password: result.onboarding_password,
          company_name: result.onboarding_company_name,
          postal_code: result.onboarding_postal_code,
          address: result.onboarding_address,
          block_no: result.onboarding_block_no,
          unit_no: result.onboarding_unit_no,
          totalOulet: result.onboarding_total_outlet,
          step: step,
          paymentstatus: result.onboarding_payment_status,
          billing_name: result.onboarding_name,
          billing_phone: result.onboarding_phone,
          billing_email: result.onboarding_email,
          billing_address: result.onboarding_address,
          billing_postal_code: result.onboarding_postal_code,
          billing_block_no: result.onboarding_block_no,
          billing_unit_no: result.onboarding_unit_no,
          onboarding_pay_amount: result.onboarding_pay_amount,
          onboarding_app_id: result.onboarding_app_id,
          onboarding_type: result.onboarding_type,
          backendurl: backendurl,
          front_end_url: result.client_site_url,
          appid: result.onboarding_app_id,
          onboarding_menu: result.onboarding_menu,
          uploadedImageUrl: result.onboarding_logo_url,
          menuurl: result.onboarding_menu_url,
          productImageUrl: result.onboarding_product_image_url,
          operational_hours:result.onboarding_operational_hours,
          outletcontactno: result.onboarding_outlet_contact_no,
          dinetablecount: result.onboarding_dinetable_count,
          about_us: result.onboarding_about_us,
          terms_and_Conditions: result.onboarding_terms,
          privacy_policy: result.onboarding_privacy_policy,
          other_commands: result.onboarding_other_commands,
        },
        function () {
          $("#dvLoading").fadeOut();
          this.loadCountlist();
          this.loadCompanyDetails();
        }
      );
    });

  }

  loadCompanyDetails(){

    var onboarding_app_id = this.state.onboarding_app_id;
    var onboarding_type = this.state.onboarding_type;


    if(onboarding_app_id !=='' && onboarding_type !==''){

      if(onboarding_type == "Ninja Enterprise"){

       axios.get(apiBaseUrl + "settings/get_common_settings?app_id="+this.state.onboarding_app_id)
          .then((res) => {

            var result = res.data.result_set;

            var availability_push = [];


            if(res.data.status == "ok"){

              if(result_availability !== "" && result_availability !== "undefined" 
                && result_availability !== undefined && result_availability !== null && result_availability !== "null" && Object.keys(result_availability).length > 0){

               var availability_list = result.availability.map((index, value)=>{

                if(index.availability_id == deliveryId){
                  availability_push.push(deliveryId);
                  this.setState({availability_deliveryId : deliveryId, delivery_checked: true});
                }

                if(index.availability_id == pickupId){
                  availability_push.push(pickupId);
                  this.setState({availability_takeawayId : pickupId, pickup_checked: true});

                }

                if(index.availability_id == dineinId){
                  availability_push.push(dineinId);
                  this.setState({availability_dineId : dineinId, dinein_checked: true});

                }
                 if(index.availability_id == reservationId){
                  availability_push.push(reservationId);
                  this.setState({availability_reservationId : reservationId, reservation_checked: true});
                }

               });

              }
               
              this.setState({ username: result.client_username, client_logo: result.client_logo, totalAvailability: availability_push, onboard_user_name: result.client_username, login_password_exist: result.client_password});
            }else{

              this.setState({  login_password_exist: ""});

            }
          }); 


      }else{

       axios.get(proapiUrl + "settings/get_common_settings?app_id="+this.state.onboarding_app_id).then((res) => {

            var result = res.data.result_set;

            if(res.data.status == "ok"){

              var availability_push = [];

              var result_availability = result.availability;

               if(result_availability !== "" && result_availability !== "undefined" 
                && result_availability !== undefined && result_availability !== null && result_availability !== "null" && Object.keys(result_availability).length > 0){

                var availability_list = result.availability.map((index, value)=>{

                if(index.availability_id == deliveryId){
                  availability_push.push(deliveryId);
                  this.setState({availability_deliveryId : deliveryId, delivery_checked: true});
                }

                if(index.availability_id == pickupId){
                  availability_push.push(pickupId);
                  this.setState({availability_takeawayId : pickupId, pickup_checked: true});

                }

                if(index.availability_id == dineinId){
                  availability_push.push(dineinId);
                  this.setState({availability_dineId : dineinId, dinein_checked: true});

                }
                 if(index.availability_id == reservationId){
                  availability_push.push(reservationId);
                  this.setState({availability_reservationId : reservationId, reservation_checked: true});
                }

               });

              }

              this.setState({ username: result.client_username, client_logo: result.client_logo, totalAvailability: availability_push, onboard_user_name: result.client_username, login_password_exist: result.client_password});
            }else{

              this.setState({  login_password_exist: ""});

            }
          }); 


      }

    }

  }

  loadCountlist() {
    var urlShringTxt = apiUrl + "onboarding/getcountryList";

    axios.get(urlShringTxt).then((res) => {
      var countryList = [];
      if (res.data.country.length > 0) {
        res.data.country.map((item) => {
          if (this.state.userDetails.onboarding_country === item.country_id) {
            this.setState({
              country: {
                value: item.country_id,
                label: item.country_name,
                isDisabled: true
              },
            });
          }
          countryList.push({
            value: item.country_id,
            label: item.country_name,
            isDisabled: true
          });
        });
      }
      this.setState({ countryList: countryList });
    });
  }

  handleTextChange(event) {
    var name = event.target.name;
    var value = event.target.value;

    // if (event.target.name === "login_customer_password") {
    //   if (value !== "") {
    //     this.setState({ error_login_customer_password: false });
    //   } else {
    //     this.setState({ error_login_customer_password: true });
    //   }
    //   this.setState({ login_customer_password: value });
    // } else if (event.target.name === "customer_password_re") {
    //   if (value !== "") {
    //     this.setState({ error_customer_password_re: false });
    //   } else {
    //     this.setState({ error_customer_password_re: true });
    //   }
    //   this.setState( { customer_password_re: value });
    //  }else{

      
    // }

    this.setState({ [name]: value });

    
  }

  handleSelectChange(name, event) {
    this.setState({ [name]: event });
  }

  handleSpaceChange(e){
    if (e.which === 32){
      e.preventDefault();
    }

  }


  onChangeHandler = (event) => {
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({ 
        client_image_name: file.name,
        client_image_preview: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };



   handleFileReader = (event) => {

    let reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({ 
        client_product_images: reader.result,
      });
    };

    reader.readAsDataURL(file);

  }


  createAPP() {

    var formData = new FormData();

    if(this.state.onboard_user_name == "" || this.state.onboard_user_name == null ){
      this.setState({main_settings_validation : false});
      return false;
    }else{
      this.setState({main_settings_validation : true});
    }




    if(this.state.login_password_exist === ""){

    if(this.state.login_customer_password == "" || this.state.login_customer_password == null ){
      this.setState({main_settings_validation : false});
      return false;
    }else{
      this.setState({main_settings_validation : true});
    }

    if(this.state.customer_password_re == "" || this.state.customer_password_re == null ){
      this.setState({main_settings_validation: false});
      return false;
    }else{
      if(this.state.login_customer_password === this.state.customer_password_re){
         this.setState({main_settings_validation : true, error_customer_password_re: true });
      }else{
         this.setState({main_settings_validation : false, error_customer_password_re:false});
         return false;
      }
    }

    }


    if(this.state.totalAvailability == "" || Object.keys(this.state.totalAvailability).length < 0){
      this.setState({main_settings_validation : false});
      return false;
    }else{
      this.setState({main_settings_validation : true});
    }

    var imagefile = this.state.client_image_preview;
    if(imagefile !=="" && imagefile !== null && imagefile !== undefined){
    formData.append("client_logo", imagefile);
    }


    var product_images = document.querySelector("#client_product_images");
    console.log(product_images, "product_images");

    if(product_images !=="" && product_images !== null && product_images !== undefined){
    formData.append("client_product_images", product_images.files[0]);
    }


    var menu = document.querySelector("#menu");
    if(menu !=="" && menu !== null && menu !== undefined){
    formData.append("menu", menu.files[0]);
    }


    formData.append("userID", this.state.userID);
    formData.append("company_name", this.state.company_name);
    formData.append("company_user_name", this.state.onboard_user_name);
    formData.append("company_email", this.state.user_email);

    if(this.state.login_password_exist === ""){
      formData.append("company_password", this.state.customer_password_re);
    }
    
    formData.append("company_avalibility", JSON.stringify(this.state.totalAvailability));
    formData.append("client_dinetable_count", this.state.dinetablecount);
    formData.append("client_outlet_operation_hours", this.state.operational_hours);
    formData.append("client_outlet_contact_no", this.state.outletcontactno);
    formData.append("company_logo_url", this.state.uploadedImageUrl);
    formData.append("company_menu_url", this.state.menuurl);
    formData.append("company_product_image_url", this.state.productImageUrl);
    formData.append("company_about_us", this.state.about_us);
    formData.append("company_terms_and_conditions", this.state.terms_and_Conditions);
    formData.append("company_privacy_policy", this.state.privacy_policy);
    formData.append("company_other_commands", this.state.other_commands);
    formData.append("client_amount", this.state.onboarding_pay_amount);
    formData.append("max_outlet", this.state.totalOulet);
    formData.append("client_country", this.state.country.value);

    if(this.state.onboarding_app_id !=="" && this.state.onboarding_app_id !== null && this.state.onboarding_app_id !== undefined ){
      formData.append("client_app_id", this.state.appid);
    }

    if(this.state.country.label == "Singapore"){
      formData.append("client_currency", "S$");
    }

    if (this.state.onboarding_type === "Ninja Enterprise") {
      var urlShringTxt = apiBaseUrl + "onboarding/createApp";
    } else {
      var urlShringTxt = proapiUrl + "onboarding/createApp";
    }

    if(this.state.main_settings_validation === true){

      showLoader("dashboard_tab_main", "class");

    axios
      .post(urlShringTxt, formData, {
        headers: {
          "Content-Type": "multipart/form-data; boundary=abcdefghijklmnop"},
      })
      .then((res) => {
        if (res.data.status === "ok") {
          hideLoader("dashboard_tab_main", "class");
          this.setState({ step: 2 });
          this.setState({ appid: res.data.appid });

          this.setState({ backendurl: (this.state.onboarding_type === "Ninja Enterprise") ? apiBaseUrlEnt + "camppanel/login/"+this.state.appid : proapiBaseUrl + "camppanel/login/"+this.state.appid})
        }
      });

    }
  }

  gotoStep(step) {

    if(this.state.onboarding_app_id !=='' && this.state.onboarding_app_id !== undefined && this.state.onboarding_app_id !== null){

      this.setState({ step: step });
      
    }

     
  }

  handleUserDomain(event){
   event.preventDefault();

   if(this.state.onboarding_type == "Ninja Enterprise"){

    var domain_name = this.state.front_end_url;

    if(domain_name !==""){

      var postObject = {
        onboarding_user_id: cookie.load("userID"),
        client_app_id: this.state.onboarding_app_id,
        domain_name: domain_name,
      };

      axios
      .post(apiUrl + "onboarding/update_site_url", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "success") {
          showCustomAlert("success", res.data.message);
        } else {
          showCustomAlert("error", res.data.message);
          
        }
      }); 
    }

   }else{

    var domain_name = this.state.front_end_url;

    if(domain_name !==""){

      var postObject = {
        onboarding_user_id: cookie.load("userID"),
        client_app_id: this.state.onboarding_app_id,
        domain_name: domain_name,
      };

      axios
      .post(proapiUrl + "onboarding/update_site_url", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "success") {
          showCustomAlert("success", res.data.message);
        } else {
          showCustomAlert("error", res.data.message);
          
        }
      }); 
    }
    

   }

  }


handleUserDomainCopy(event){
  event.preventDefault();
    var myCode = document.getElementById('userdomain_url');
    var fullLink = document.createElement('input');
    document.body.appendChild(fullLink);
    fullLink.value = myCode;
    fullLink.select();
    document.execCommand("copy", false);
    fullLink.remove();
    $("#copy-success-msg").fadeIn();
    setTimeout(function () {
      $("#copy-success-msg").fadeOut();
    }, 3000);

}

 handleChange(e){

  var checkbox_name = e.target.name;

  var checkbox_value = e.target.checked;

  var totalAvailability = this.state.totalAvailability;


   if(checkbox_name == "availability_deliveryId"){
     if(checkbox_value === true){
      totalAvailability.push(deliveryId)
      }

      this.setState({delivery_checked: e.target.checked});

      if(checkbox_value === false){

      var index = totalAvailability.indexOf(deliveryId);

       for (var i = 0; i < totalAvailability.length; i++) {
        if (totalAvailability[i] === deliveryId) {
            var spliced = totalAvailability.splice(i, 1);

        }
       }

      }
   }

   if(checkbox_name == "availability_takeawayId"){
     if(checkbox_value === true){
      totalAvailability.push(pickupId)
      }

      this.setState({pickup_checked: e.target.checked});
      
      if(checkbox_value === false){
      var index = totalAvailability.indexOf(deliveryId);
      for (var i = 0; i < totalAvailability.length; i++) {
        if (totalAvailability[i] === pickupId) {
            var spliced = totalAvailability.splice(i, 1);

        }
       }
      }
   }

   if(checkbox_name == "availability_dineId"){
     if(checkbox_value === true){
      totalAvailability.push(dineinId)
      }

      this.setState({dinein_checked: e.target.checked});
      
      if(checkbox_value === false){
      var index = totalAvailability.indexOf(dineinId);
      for (var i = 0; i < totalAvailability.length; i++) {
        if (totalAvailability[i] === dineinId) {
            var spliced = totalAvailability.splice(i, 1);

        }
       }
      }
   }


   if(checkbox_name == "availability_reservationId"){
     if(checkbox_value === true){
      totalAvailability.push(reservationId)
      }

      this.setState({reservation_checked: e.target.checked});
      
      if(checkbox_value === false){
      var index = totalAvailability.indexOf(reservationId);
       for (var i = 0; i < totalAvailability.length; i++) {
        if (totalAvailability[i] === reservationId) {
            var spliced = totalAvailability.splice(i, 1);

        }
       }

      }
   }

}


  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }



  render() {
    
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (<div>
        <div className="dashboard-mobilemain">
        <div className="dashboard-mobilemenu">
          <div className="menu_icon trigger_menunav_act">
            <span className="icon-bar icon-bar1"></span>
            <span className="icon-bar icon-bar2"></span>
            <span className="icon-bar icon-bar3"></span>
          </div>

          <div className="mobile-nav-logo">
            <a href="#">
              {" "}
              <img src={mobilelogo} />{" "}
            </a>
          </div>

          <div className="mobile-menu">
            <div className="mobile-menu-header">
              <div className="mobile-menu-close">
                <span id="close_mobile_menu"></span>
              </div>
               <div className="mobile-menu-logo">
              <a title="Ninja Onboard" href="/">
                <img src={mobilelogo} />
              </a>
            </div>
            </div>

            <div className="mobile-menu-body">
              <ul className="menu">

              {cookie.load("onboarding_payment_status") === "Pending" && (
              <li className={this.state.currentPage === "/getstart" ? "active" : ""}>
                <Link to="/getstart">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={getstartImg} />
                    <img className="order_type_imgwt" src={getstartWImg} />
                  </div>
                  <div className="cat-content">
                    Get Started
                  </div>
                </div>
                </Link>{" "}
              </li>
              )}
              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={ this.state.currentPage === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={mdetailsImg} />
                      <img className="order_type_imgwt" src={mdetailsWImg} />
                    </div>
                    <div className="cat-content">
                      Dashboard
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {/*<li className={this.state.currentPage === "/notifications" ? "active" : ""}>
                <Link to="/notifications">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={notficationImg} />
                      <img className="order_type_imgwt" src={notficationWImg} />
                    </div>
                    <div className="cat-content">
                      notifications
                    </div>
                  </div>
                </Link>
              </li>*/}

              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={this.state.currentPage === "/subscriptions" ? "active" : ""}>
                <Link to="/subscriptions">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={subscriptionsImg} />
                      <img className="order_type_imgwt" src={subscriptionsWImg} />
                    </div>
                    <div className="cat-content">
                      subscriptions
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}
             
              <li className={this.state.currentPage === "/my-details" ? "active" : ""}>
                <Link to="/my-details">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={dashboardImg} />
                      <img className="order_type_imgwt" src={dashboardWImg} />
                    </div>
                    <div className="cat-content">
                      My Details
                    </div>
                  </div>
                </Link>{" "}
              </li>
              
            
              <li className={this.state.currentPage === "/plan" ? "active" : ""}>
                <Link to="/plan">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={planImg} />
                      <img className="order_type_imgwt" src={planWImg} />
                    </div>
                    <div className="cat-content">
                       Plan
                    </div>
                  </div>
                </Link>{" "}
              </li>

              <li>
                <a href="https://support.ninjaos.com/" target="_blank">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={helpImg} />
                      <img className="order_type_imgwt" src={helpWImg} />
                    </div>
                    <div className="cat-content">
                       Help
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <Link to="/logout">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={logoutImg} />
                      <img className="order_type_imgwt" src={logoutWImg} />
                    </div>
                    <div className="cat-content">
                       Logout
                    </div>
                  </div>
                </Link>{" "}
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard">
        <div className="dashboard-inner">
          <Sidebar {...this.props} />
          <div className="dashboard-content">
            <div className="dash_heading">
              <h1>Your Almost there,</h1>
              <p>lets setup this quickly now</p>
            </div>

            <div className="dashboard_tab_main">
              <div className="dashboard_top destop_tab">
                <ul>
                  <li className={this.state.step == 1 ? "active" : ""}>
                    <a href={void 0} onClick={this.gotoStep.bind(this, 1)}>
                      <div className="dash_img">
                        <img className="dash_type_img" src={TabImg} />
                      </div>
                      main settings
                    </a>
                  </li>

                  <li className={this.state.step == 2 ? "active" : ""}>
                    <a href={void 0} onClick={this.gotoStep.bind(this, 2)}>
                      <div className="dash_img">
                        <img className="dash_type_img" src={TabImg1} />
                      </div>
                      Backend Info
                    </a>
                  </li>

                  <li className={this.state.step == 3 ? "active" : ""}>
                    <a href={void 0} //onClick={this.gotoStep.bind(this, 3)}
                    >
                      <div className="dash_img">
                        <img className="dash_type_img" src={TabImg2} />
                      </div>
                      front end setup
                    </a>
                  </li>
                  <li className={this.state.step == 4 ? "active" : ""}>
                    <a href={void 0} onClick={this.gotoStep.bind(this, 4)}>
                      <div className="dash_img">
                        <img className="dash_type_img" src={TabImg3} />
                      </div>
                      contact us
                    </a>
                  </li>
                </ul>
              </div>


              <div className="mobile_tab">
                <Slider {...settings}>
                  <div>
                    <a href={void 0} onClick={this.gotoStep.bind(this, 1)}>
                      <div className="dash_img">
                        <img className="dash_type_img" src={TabImg} />
                      </div>
                      main settings
                    </a>
                  </div>

                  <div>
                    <a href={void 0} onClick={this.gotoStep.bind(this, 2)}>
                      <div className="dash_img">
                        <img className="dash_type_img" src={TabImg2} />
                      </div>
                      backendinfo
                    </a>
                  </div>

                  <div>
                    <a href={void 0} >
                      <div className="dash_img">
                        <img className="dash_type_img" src={TabImg2} />
                      </div>
                      front end setup
                    </a>
                  </div>

                  <div>
                    <a href={void 0} onClick={this.gotoStep.bind(this, 4)}>
                      <div className="dash_img">
                        <img className="dash_type_img" src={TabImg3} />
                      </div>
                      contact us
                    </a>
                  </div>
                </Slider>
              </div>


              {this.state.step === 1 && (
                <div className="settings-content dashboard_contant active">
                  {/*<div className="settings-content-lhs">
                    <img src={sinfo} />
                  </div>*/}
                  <div className="settings-content-rhs">
                    <div className="dash_in_heading">
                      <h4>Submit your menu & store settings</h4>
                      <p>
                        Please submit your menu and update basic informations
                      </p>
                    </div>
                    <div className="so-form">
                      <div className="singupform-step-one tab_cont_form">


                        <div className="control-group">
                          <input
                            type="text"
                            className="input-box"
                            name="onboard_user_name"
                            value={this.state.onboard_user_name}
                            onKeyPress={(e) => this.handleSpaceChange(e)}
                            onChange={this.handleTextChange.bind(this)}
                            placeholder="User Name Of the Backend"
                          />
                        </div>

                        {this.state.login_password_exist ==="" && (
                            <div
                                className={
                                  this.state.login_customer_password !== ""
                                    ? "control-group focus-out focused"  
                                    : "control-group"
                                }
                              >
                                <input
                                  type="password"
                                  className="input-box"
                                  id="login_customer_password"
                                  name="login_customer_password"
                                  value={this.state.login_customer_password}
                                  onChange={this.handleTextChange.bind(this)}
                                  placeholder=""
                                />


                                <label className="control-label">Backend Password</label>
                              </div>)}

                          {this.state.login_password_exist ==="" && (

                              <div
                                className={
                                  this.state.customer_password_re !== ""
                                    ? "control-group focus-out focused"  
                                    : "control-group "
                                }
                              >
                                <input
                                  type="password"
                                  className="input-box"
                                  id="customer_password_re"
                                  name="customer_password_re"
                                  value={this.state.customer_password_re}
                                  onChange={this.handleTextChange.bind(this)}
                                  placeholder=""
                                />


                                {this.state.error_customer_password_re === false && (
                                <span className="error_img">
                                <img className="order_type_imgwt" src={errorwImg} />
                                </span>
                                )}

                              <label className="control-label">Re Enter Password</label>
                              </div>)}

                            <div className="availabilty_mdiv">

                              <h5>availability</h5>

                              <div className="availabilty_sectiondiv">
                                <input
                                  type="checkbox"
                                  id="availability_deliveryId"
                                  name="availability_deliveryId"
                                  checked={this.state.delivery_checked}
                                  onChange={this.handleChange.bind(this)}
                                />

                                <label className="inline">
                                  Delivery
                                </label>
                              </div>


                              <div className="availabilty_sectiondiv">
                                <input
                                  type="checkbox"
                                  id="availability_takeawayId"
                                  name="availability_takeawayId"
                                  checked={this.state.pickup_checked}
                                  onChange={this.handleChange.bind(this)}
                                />
                                <label className="inline">
                                  Takeaway
                                </label>
                              </div>


                              <div className="availabilty_sectiondiv">
                                <input
                                  type="checkbox"
                                  id="availability_dineId"
                                  name="availability_dineId"
                                  checked={this.state.dinein_checked}
                                  onChange={this.handleChange.bind(this)}
                                  
                                />
                                <label className="inline">
                                  Dine In
                                </label>
                              </div>


                              <div className="availabilty_sectiondiv">
                                <input
                                  type="checkbox"
                                  id="availability_reservationId"
                                  name="availability_reservationId"
                                  checked={this.state.reservation_checked}
                                  onChange={this.handleChange.bind(this)}
                                  
                                />

                                <label className="inline">
                                  Reservation
                                </label>
                              </div>

                            </div>

                        {this.state.dinein_checked === true && (<div className={ this.state.dinetablecount !== "" ? "control-group focus-out focused"  : "control-group" }>
                          <input
                            type="text"
                            className="input-box"
                            name="dinetablecount"
                            value={this.state.dinetablecount}
                             onChange={this.handleTextChange.bind(this)}
                          />
                          <label className="control-label">Dine in Table Count (If applicable for dine in)</label>
                        </div> )}


                       <div className={this.state.operational_hours !== "" ? "control-group focus-out focused"  : "control-group"  }>
                          <input
                            type="text"
                            className="input-box"
                            name="operational_hours"
                            value={this.state.operational_hours}
                             onChange={this.handleTextChange.bind(this)}
                          />
                          <label className="control-label">Outlet Operating hours</label>
                        </div>

                        <div className={ this.state.outletcontactno !== "" ? "control-group focus-out focused" : "control-group" }>
                          <input
                            type="text"
                            className="input-box"
                            name="outletcontactno"
                            value={this.state.outletcontactno}
                            maxLength="8"
                            onKeyPress={(e) => this.validateIntval(e)}
                            onChange={this.handleTextChange.bind(this)}
                          />
                          <label className="control-label">Outlet Contact Number</label>
                        </div>

                       <div className="fileup_maindiv">
                          <div className="fileup_rhs">
                            <div className="fileup_content">
                              <p className="uploaded-para">Upload Logo</p>
                              <input type="file" id="client_logo" onChange={this.onChangeHandler} className="input-box" accept="image/gif, image/jpeg, image/jpg, image/png" />
                            </div>

                            <div className="fileup_link">
                                <div className={
                                  this.state.uploadedImageUrl !== "" && this.state.uploadedImageUrl !== null
                                    ? "control-group focus-out focused"
                                    : "control-group"
                                }>
                                <input
                                  type="text"
                                  className="input-box"
                                  name="uploadedImageUrl"
                                  value={this.state.uploadedImageUrl}
                                  onChange={this.handleTextChange.bind(this)}
                                />
                                <label className="control-label">Or Enter URL of your Images</label>
                              </div>
                            </div>
                          </div>

                          <div className="fileup_rhs">
                            <div className="fileup_content">
                              <p className="uploaded-para">Product Images</p>
                              <input type="file" id="client_product_images" onChange={this.handleFileReader} className="input-box" accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" />
                            </div>

                            <div className="fileup_link">
                                <div className={
                                  this.state.productImageUrl !== "" && this.state.productImageUrl !== null
                                    ? "control-group focus-out focused"
                                    : "control-group"
                                }>
                                <input
                                  type="text"
                                  className="input-box"
                                  name="productImageUrl"
                                  value={this.state.productImageUrl}
                                  onChange={this.handleTextChange.bind(this)}
                                />
                                <label className="control-label">Or Enter your Product Images</label>
                              </div>
                            </div>
                          </div>

                          <div className="fileup_lhs fileup_container">
                            <div className="fileup_content">
                              <p className="uploaded-para">Upload Menu</p>
                              <input type="file" id="menu" className="input-box" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            </div>

                            <div className="fileup_link">
                                <div className={
                                  this.state.menuurl !=="" && this.state.menuurl !== null
                                    ? "control-group focus-out focused"
                                    : "control-group"
                                }>
                                <input
                                  type="text"
                                  className="input-box"
                                  name="menuurl"

                                  value={this.state.menuurl}
                                  onChange={this.handleTextChange.bind(this)}
                                />
                                <label className="control-label">Or Enter URL of your menu</label>
                              </div>
                            </div>
                          </div>

                          <div className="fileup_rhs">
                            <div className="fileup_content">
                              <a href=""> Sample Menu</a>
                            </div>
                          </div>

                          
                        </div>

                        <div className="myacc_cmdsdiv">
                          <div className="myacc_cmdsinner">
                            <h5>About Us</h5>
                            <textarea placeholder="About Us" name="about_us" value={stripslashes(this.state.about_us)} onChange={this.handleTextChange.bind(this)}></textarea>
                          </div>

                          <div className="myacc_cmdsinner">
                            <h5>Terms & Conditions</h5>
                            <textarea placeholder="Terms & Conditions" name="terms_and_Conditions" value={stripslashes(this.state.terms_and_Conditions)} onChange={this.handleTextChange.bind(this)}></textarea>
                          </div>

                          <div className="myacc_cmdsinner">
                            <h5>Privacy Policy</h5>
                            <textarea placeholder="Privacy Policy" name="privacy_policy" value={stripslashes(this.state.privacy_policy)} onChange={this.handleTextChange.bind(this)}></textarea>
                          </div>

                          <div className="myacc_cmdsinner">
                            <h5>Other Commands</h5>
                            <textarea placeholder="Other Commands" name="other_commands" value={stripslashes(this.state.other_commands)} onChange={this.handleTextChange.bind(this)}></textarea>
                          </div>

                        </div>

                        <div className="dash-btn-div">
                        <button
                          type="button"
                          className="button"
                          onClick={this.createAPP.bind(this)}
                        >
                          submit now
                        </button>
                        </div>

                        {(this.state.main_settings_validation === false )&& ( <p className="error_para">Please enter all fields</p>)}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* =========2nd Tab Content Starting==========  */}
              {this.state.step === 2 && (
                <div className="backendinfo_div dashboard_contant active">
                  <div className="backendinfo-lhs">
                    <div className="backendinfo_img">
                      <img src={BackendImg} />
                    </div>
                  </div>

                  <div className="backendinfo-rhs">
                    <div className="backendinfo-content">
                      <h4>backend informations</h4>
                      <div className="backend-image-div">
                      <a href={this.state.backendurl}
                         target="_blank"
                         type="button"
                         id="userdomain_url"
                         value={this.state.backendurl}
                         className="button">open backend</a>
                        <div className="dash_img" onClick={this.handleUserDomainCopy.bind(this)}>
                          <img className="dash_type_img" src={Back_inImg}  />
                        </div>
                         <div
                      className="custom_alertcls alert-success alert-dismissible1 success_hide"
                      id="copy-success-msg"
                      style={{ display: "none" }}
                    >
                      {" "}
                      <p className="jquery-success-msg">
                       Copied
                      </p>{" "}
                    </div>

                      </div>

                      <div className="backendinfo-group group-top">
                        <label className="label">User name:</label>
                        <input
                          type="text"
                          value={this.state.username}
                          /* onChange={this.handleSignupChange.bind(this)} */
                          placeholder=" Placeholdername"
                        />
                      </div>

                      <div className="backendinfo-group backendpass-group">
                          {/*<label className="label">Password</label>:
                          <input
                          type="password"
                          className="input-box"
                          id="customer_password"
                          name="customer_password"
                          placeholder=" *********"
                        />
                      
                       <div className="dash_img">
                        <img className="dash_type_img" src={Back_passImg} />
                      </div>*/}
                      Password will be sent to your registered email address
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* =========3rd Tab Content Starting==========  */}
              {this.state.step === 3 && (
                <div className="frontendinfo_div dashboard_contant active">
                  <div className="backendinfo-lhs">
                    <div className="backendinfo_img">
                      <img src={FrontendImg} />
                    </div>
                  </div>

                  <div className="backendinfo-rhs">
                    <div className="backendinfo-content">
                      <h4>frontend informations</h4>

                        <div className="control-group backend-group">
                          <input
                            type="text"
                            className="input-box"
                            name="front_end_url"
                            value={this.state.front_end_url}
                             onChange={this.handleTextChange.bind(
                              this
                            )}
                             placeholder="Enter Name you prefer for your frontend"
                          />
                        </div>

                      <p className="frontend-link">
                        Your front end URL : 
                         <a href={this.state.front_end_url} target="_blank"> {this.state.front_end_url}</a>
                      </p>
                      <p className="frontend-para">
                        Would you like to use your <span>own domain name ?</span> no problem
                        please follow our guide
                      </p>

                      <div className="frontend-button">

                      <button
                        type="button"
                        className="button"
                         onClick={this.handleUserDomain.bind(this)}
                      >
                        uses own domain
                      </button>

                      <button type="button" className="button own-two" /*  onClick={this.handleFromChange.bind(this, "singup")} */ >
                        buy domain
                      </button>

                    </div>

                    </div>
                     </div>
                </div>
              )}

              {/* =========4th Tab Content Starting==========  */}
              {this.state.step === 4 && (
                <div className="contactinfo_div dashboard_contant active">
                  <div className="backendinfo-lhs">
                    <div className="backendinfo_img">
                      <img src={TabcontactImg} />
                    </div>
                  </div>

                  <div className="backendinfo-rhs">
                    <div className="backendinfo-content">
                      <h4>contact us</h4>

                      <p className="contacttab-para">
                        Should you have any queries,
                        <br /> feel free to contact us
                      </p>

                      <a
                        type="button"
                        href="https://support.ninjaos.com/"
                        className="button"
                        target="_blank"
                        value="Contact Us"
                      >
                      Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              )}
             </div>
           </div>
         </div>
        </div>
        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Myaccount);
