import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Getstart from "./components/Home/Getstart";
import Plan from "./components/Home/Plan";
import PlanDetails from "./components/Home/Plandetails";
import Myaccount from "./components/Myaccount/Myaccount";
import Subscription from "./components/Myaccount/Subscription";
import Notifications from "./components/Myaccount/Notifications";
import MyDetails from "./components/Myaccount/Mydetails";
import Resetpassword from "./components/Myaccount/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Page404 from "./Page404";
import Pages from "./components/Pages/Pages";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/getstart" component={Getstart} />
        <Route exact path="/plan" component={Plan} />
        <Route exact path="/plan/:packagename" component={PlanDetails} />
        <Route exact path="/dashboard" component={Myaccount} />
        {/*<Route exact path="/notifications" component={Notifications} />*/}
        <Route exact path="/subscriptions" component={Subscription} />
        <Route exact path="/my-details" component={MyDetails} />
        <Route path="/account/resetpassword/:resetKey" component={Resetpassword} />
        <Route path="/logout" component={Logout} />
        <Route path="/:page_slug" component={Pages} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
