/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { connect } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Slider from "react-slick";
import { apiUrl, proapiUrl, apiBaseUrl, proapiBaseUrl, apiBaseUrlEnt } from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  country_symbol,
  showAlert
} from "../Helpers/SettingHelper";

import errorImg from "../../common/images/error.png";
import errorwImg from "../../common/images/error_wrong.png";

import logo from "../../common/images/logo.png";
import BackendImg from "../../common/images/backend.jpg";
import FrontendImg from "../../common/images/frontend.jpg";
import TabcontactImg from "../../common/images/tab-contact.jpg";
import TabImg from "../../common/images/settings.svg";
import TabImg1 from "../../common/images/tab-group.svg";
import TabImg2 from "../../common/images/setup1.svg";
import TabImg3 from "../../common/images/chat-dark.svg";
import sinfo from "../../common/images/settings-info.jpg";
import Back_inImg from "../../common/images/back_copy.png";
import Back_passImg from "../../common/images/passwrd.svg";
import mobilelogo from "../../common/images/logo.png";
import PricetickImg from "../../common/images/blue-tick.png";

import getstartImg from "../../common/images/s_bar2.svg";
import getstartWImg from "../../common/images/s_bar1.svg";
import mdetailsImg from "../../common/images/s_bar4.svg";
import mdetailsWImg from "../../common/images/s_bar3.svg";
import planImg from "../../common/images/s_bar6.svg";
import planWImg from "../../common/images/s_bar5.svg";
import helpImg from "../../common/images/s_bar8.svg";
import helpWImg from "../../common/images/s_bar7.svg";
import logoutImg from "../../common/images/s_bar10.svg";
import logoutWImg from "../../common/images/s_bar9.svg";
import dashboardImg from "../../common/images/s_bar12.svg";
import dashboardWImg from "../../common/images/s_bar11.svg";
import notficationImg from "../../common/images/s_bar14.svg";
import notficationWImg from "../../common/images/s_bar13.svg";
import subscriptionsImg from "../../common/images/s_bar16.svg";
import subscriptionsWImg from "../../common/images/s_bar15.svg";
import FileuploadImg from "../../common/images/download.svg";


var qs = require("qs");
class Mydetails extends Component {
  constructor(props) {
    super(props);
    var userID =
      cookie.load("userID") !== "" &&
      typeof cookie.load("userID") !== undefined &&
      typeof cookie.load("userID") !== "undefined"
        ? cookie.load("userID")
        : "";
    this.state = {
      step: 1,
      userID: userID,
      customer_name: '',
      customer_phone: '',
      customer_email: "",
      country_code: "",
      error_customer_name: "",
      error_customer_email: "",
      error_invalid_email: "",
      error_exist_email: "",
      error_exist_phone: "",
      error_customer_phone: "",
      old_password: "",
      new_password: "",
      reenter_new_password: "",
      old_password_error: "",
      new_password_error: "",
      reenter_new_password_error: "",
      reenter_new_password_same_error: "",
      currentPage: this.props.match.path,
    };
    if (userID === "") {
      this.props.history.push("/");
    } else {
      this.loadUserDetails();
    }
  }

  loadUserDetails() {
    var urlShringTxt =
      apiUrl + "onboarding/getuserinfo?userID=" + this.state.userID;

    axios.get(urlShringTxt).then((res) => {
      var result = res.data.result_set;
      var step = 1;
      $("#dvLoading").fadeOut();
            this.setState(
        {
          customer_name: result.onboarding_name,
          customer_phone: result.onboarding_phone,
          customer_email: result.onboarding_email,
          country_code: result.onboarding_country_phone_code, 
        },
        function () {
          $("#dvLoading").fadeOut();
        }
      );

    });
  }



  handleSignupChange(event) {
    var value = event.target.value;
     if (event.target.name === "customer_phone") {
      if (value !== "") {
        this.setState({ error_customer_phone: false });
      } else {
        this.setState({ error_customer_phone: true });
      }
      this.setState(
        { customer_phone: value },
        function () {
          this.checkAlreadyExit("phone");
        }.bind(this)
      );
    } else if (event.target.name === "customer_email") {
      if (value !== "") {
        this.setState({ error_customer_email: false });
        this.setState(
        { customer_email: value },
        function () {
          var validate_Email_Fun = validateEmailFun(this.state.customer_email);
          if (validate_Email_Fun === true) {
            this.checkAlreadyExit("email");
          }
        }.bind(this)
      );
      } else {
        this.setState({ error_customer_email: true });
      }
      this.setState(
        { customer_email: value },
        function () {
          var validate_Email_Fun = validateEmailFun(this.state.customer_email);
          if (validate_Email_Fun === true) {
            this.checkAlreadyExit("email");
          }
        }.bind(this)
      );
    }
  }


  handleTextChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }



  checkAlreadyExit(flg) {
    var postObject = {
      type: flg,
      customer_email: this.state.customer_email,
      customer_phone: this.state.customer_phone.replace(this.state.country, ""),
    };
    axios
      .post(apiUrl + "onboarding/checkexisting", qs.stringify(postObject))
      .then((res) => {
        hideLoader("signup_first", "class");
        if (res.data.status === "success") {
          if (flg === "email") {
            this.setState({ error_exist_email: "" });
          }
          if (flg === "phone") {
            this.setState({ error_exist_phone: "" });
          }
        } else {
          if (flg === "email") {
            this.setState({ error_exist_email: res.data.message, error_customer_email:false});
          }
          if (flg === "phone") {
            this.setState({ error_exist_phone: res.data.message, error_customer_phone:false});
          }
        }
      });
  }


  handleUpdateMyDetails(){

      var errorFlg = "n";

      if (this.state.customer_name === "") {
        this.setState({ error_customer_name: true });
        errorFlg = "y";
        return false;
      } else {
        this.setState({ error_customer_name: false});
      }

      if (this.state.customer_phone === "") {
        errorFlg = "y";
        this.setState({ error_customer_phone: true });
        return false;
      } else if (this.state.error_exist_phone !==""){
        this.setState({ error_exist_phone: false });
      }
      else {
        this.setState({ error_customer_phone: false, error_exist_phone:""});
      }

      if (this.state.customer_email === "") {
         errorFlg = "y";
        this.setState({
          error_customer_email: true,
          error_invalid_email: false,
        });
       
         return false;

      } else if(this.state.error_exist_email != ''){
        errorFlg = "y";
          this.setState({ error_customer_email: true });
          this.setState({ error_invalid_email: false });
          return false;
      }
       else {
        var validate_Email_Fun = validateEmailFun(this.state.customer_email);

        if (validate_Email_Fun === true) {
          this.setState({ error_customer_email: false });
          this.setState({ error_invalid_email: false });
        } else {
          errorFlg = "n";
          this.setState({ error_invalid_email: true, error_customer_email: true});
          return false;
        }
      }


      if (errorFlg === "n") {

        showLoader("mydetail_maindiv", "class");

        var postObject = {
          userID: this.state.userID,
          customer_name: this.state.customer_name,
          customer_phone: this.state.customer_phone,
          customer_email: this.state.customer_email,
         };

        axios
          .post(apiUrl + "onboarding/update_client_details", qs.stringify(postObject))
          .then((res) => {

           if(res.data.status == "success"){

            this.handleShowAlertFun("success", res.data.message);
            
            this.setState({ error_customer_name: "",  error_customer_phone: "", error_exist_phone: "",
              error_customer_email: ""});

            hideLoader("mydetail_maindiv", "class");

           }else{

            this.handleShowAlertFun("Error", res.data.message);

           }

          });
      }

  }


    handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }




  openChangepassword(){

   $.magnificPopup.open({
      items: {
        src: "#change-password-popup",
      },
      type: "inline",
      midClick: true,
      closeOnBgClick: false,
    });

  }


  changePasswordPopup(){

      var errorFlg = "n";

      if (this.state.old_password === "") {
        this.setState({ old_password_error: false });
        errorFlg = "y";
        return false;
      } else {
        this.setState({ old_password_error: true});
      }

      if (this.state.new_password === "") {
    
          this.setState({ new_password_error: false });
          errorFlg = "y";
          return false;

        } else {
          this.setState({ new_password_error: true });
        }
  

      if (this.state.reenter_new_password === "") {
    
          this.setState({ reenter_new_password_error: false });
          errorFlg = "y";
          return false;

        } else {
          this.setState({ reenter_new_password_error: true });
        }

      if (this.state.new_password != this.state.reenter_new_password ) {
  
        this.setState({ reenter_new_password_same_error: false });
        errorFlg = "y";
        return false;

      } else {
        this.setState({ reenter_new_password_same_error: true });
      }


      if (errorFlg === "n") {

        var postObject = {
        oldpassword: this.state.old_password,
        password: this.state.new_password,
        confirmpassword: this.state.reenter_new_password,
        refrence: this.state.userID
       };
   
         axios.post(apiUrl + "onboarding/changepassword", qs.stringify(postObject)).then((res) => {

         if(res.data.status == "success"){

          this.handleShowAlertFun("success", res.data.message);


         }else{

          this.handleShowAlertFun("Error", res.data.message);

         }

        });

      }

    }



  
  render() {

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (<div>
        <div className="dashboard-mobilemain">
        <div className="dashboard-mobilemenu">
          <div className="menu_icon trigger_menunav_act">
            <span className="icon-bar icon-bar1"></span>
            <span className="icon-bar icon-bar2"></span>
            <span className="icon-bar icon-bar3"></span>
          </div>

          <div className="mobile-nav-logo">
            <a href="#">
              {" "}
              <img src={mobilelogo} />{" "}
            </a>
          </div>

          <div className="mobile-menu">
            <div className="mobile-menu-header">
              <div className="mobile-menu-close">
                <span id="close_mobile_menu"></span>
              </div>
               <div className="mobile-menu-logo">
              <a title="Ninja Onboard" href="/">
                <img src={mobilelogo} />
              </a>
            </div>
            </div>
            <div className="mobile-menu-body">
              <ul className="menu">

              {cookie.load("onboarding_payment_status") === "Pending" && (
              <li className={this.state.currentPage === "/getstart" ? "active" : ""}>
                <Link to="/getstart">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={getstartImg} />
                    <img className="order_type_imgwt" src={getstartWImg} />
                  </div>
                  <div className="cat-content">
                    Get Started
                  </div>
                </div>
                </Link>{" "}
              </li>
              )}
              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={ this.state.currentPage === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={mdetailsImg} />
                      <img className="order_type_imgwt" src={mdetailsWImg} />
                    </div>
                    <div className="cat-content">
                      Dashboard
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {/*<li className={this.state.currentPage === "/notifications" ? "active" : ""}>
                <Link to="/notifications">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={notficationImg} />
                      <img className="order_type_imgwt" src={notficationWImg} />
                    </div>
                    <div className="cat-content">
                      notifications
                    </div>
                  </div>
                </Link>{" "}
              </li>*/}

              {cookie.load("onboarding_payment_status") === "Paid" && (
               <li className={this.state.currentPage === "/subscriptions" ? "active" : ""}>
                <Link to="/subscriptions">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={subscriptionsImg} />
                      <img className="order_type_imgwt" src={subscriptionsWImg} />
                    </div>
                    <div className="cat-content">
                      subscriptions
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

               <li className={this.state.currentPage === "/my-details" ? "active" : ""}>
                <Link to="/my-details">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={dashboardImg} />
                      <img className="order_type_imgwt" src={dashboardWImg} />
                    </div>
                    <div className="cat-content">
                      My Details
                    </div>
                  </div>
                </Link>{" "}
              </li>
        

              <li className={this.state.currentPage === "/plan" ? "active" : ""}>
                <Link to="/plan">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={planImg} />
                      <img className="order_type_imgwt" src={planWImg} />
                    </div>
                    <div className="cat-content">
                       Plan
                    </div>
                  </div>
                </Link>{" "}
              </li>

              <li>
                 <a href="https://support.ninjaos.com/" target="_blank">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={helpImg} />
                      <img className="order_type_imgwt" src={helpWImg} />
                    </div>
                    <div className="cat-content">
                       Help
                    </div>
                  </div>
                </a>{" "}
              </li>
              <li>
                <Link to="/logout">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={logoutImg} />
                      <img className="order_type_imgwt" src={logoutWImg} />
                    </div>
                    <div className="cat-content">
                       Logout
                    </div>
                  </div>
                </Link>{" "}
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard">
        <div className="dashboard-inner">
          <Sidebar {...this.props} />
          <div className="dashboard-content">

            {/*<ul className="stepper-wrapper active-first">
              <li
                className={
                  this.state.step === 1 ||
                  this.state.step === 2 ||
                  this.state.step === 3 ||
                  this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item"
                }
              >
                <div className="step-counter">1</div>
              </li>
              <li
                className={
                  this.state.step === 2 ||
                  this.state.step === 3 ||
                  this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item "
                }
              >
                <div className="step-counter">2</div>
              </li>
              <li
                className={
                  this.state.step === 3 || this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item "
                }
              >
                <div className="step-counter">3</div>
              </li>
              <li
                className={
                  this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item "
                }
              >
                <div className="step-counter">3</div>
              </li>
              <li
                className={
                  this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item "
                }
              >
                <div className="step-counter">4</div>
              </li>
            </ul>*/}

            <div className="getstarted mydetail_maindiv">
              <div className="your-package mydetails">
                <h1>My details</h1>
              </div>

              <div className="getstarted mydetail_form">

              <div className="so-form">
              <div className="singupform-step-one">
                <div
                  className={
                    this.state.customer_name !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }
                >
                  <input
                    type="text"
                    className="input-box"
                    id="customer_name"
                    name="customer_name"
                    value={this.state.customer_name}
                    onChange={this.handleTextChange.bind(this)}
                    />

                  {this.state.error_customer_name === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                 {this.state.error_customer_name === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}

                  <label className="control-label">Your Name</label>
                </div>
                <div
                  className={
                    this.state.customer_phone !== ""
                      ? "control-group control-flag focus-out focused"
                      : "control-group control-flag control-flag"
                  }
                >
                  <PhoneInput
                    country={"sg"}
                    value={this.state.country_code+this.state.customer_phone}
                    onChange={(customer_phone, country) =>
                      this.setState(
                        {
                          customer_phone,
                          country: country.dialCode,
                          error_customer_phone: false,
                        },
                        function () {
                          if (
                            customer_phone.replace(country.dialCode, "")
                              .length >= 8
                          ) {
                            this.checkAlreadyExit("phone");
                          }
                        }
                      )
                    }/>

                  {this.state.error_customer_phone === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                 {this.state.error_exist_phone !=='' && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                  {this.state.error_customer_phone === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}

                <label class="control-label"> Your Telephone Number</label>
                </div>
                <div
                  className={
                    this.state.customer_email !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }
                >
                  <input
                    type="text"
                    className="input-box"
                    id="customer_email"
                    name="customer_email"
                    value={this.state.customer_email}
                    onChange={this.handleSignupChange.bind(this)}
                    placeholder=""
                  />

                 {this.state.error_customer_email === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

  
                 {this.state.error_customer_email === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}


                <label className="control-label">Your Email Address</label>
                </div>

              </div>

              <div className="subscription-button mydetail-button">
                    <div class="black-part inquire-part"><a class="button" onClick={this.openChangepassword.bind(this)}>change password</a></div>
                    <div class="black-part"><a class="button active"  onClick={this.handleUpdateMyDetails.bind(this)}>save</a></div>
                  </div>
            </div>
        </div>
      </div>


           </div>
         </div>
        </div>




        <div id="change-password-popup" className="white-popup mfp-hide popup_sec login-popup">
          <div className="pop-whole">
            <div className="change-password-heading">
              <h3>Change Password</h3>
            </div>

              <div className="change-password-body">
               <div className="control-group">
               
             <div className="control-group">
              <div className={
                    this.state.old_password !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }>
                <input type="password" name="old_password" className="input-box" value={this.state.old_password} onChange={this.handleTextChange.bind(this)} />

                  {this.state.old_password_error === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}


                  {this.state.old_password_error === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}


                <label className="control-label">Old Password</label>
              </div>
              </div>


            <div className="control-group">
              <div className={
                    this.state.new_password !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }>
                <input type="password" name="new_password" className="input-box"  value={this.state.new_password} onChange={this.handleTextChange.bind(this)} />

                  {this.state.new_password_error === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}


                  {this.state.new_password_error === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}

                <label className="control-label">New Password</label>
              </div>
              </div>


             <div className="control-group">
              <div className={
                    this.state.reenter_new_password !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }>
                <input type="password" name="reenter_new_password" className="input-box"  value={this.state.reenter_new_password} onChange={this.handleTextChange.bind(this)} />

                  {this.state.reenter_new_password_error === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}


                  {this.state.reenter_new_password_error === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}


                  {this.state.reenter_new_password_same_error === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}


                  {this.state.reenter_new_password_same_error === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}

                <label className="control-label">Re-enter New Password</label>
              </div>
              </div>

              <div className="form-group">
                <div className="login_pop_sub">
                  <button
                    type="button"
                    className="button btn_black btn_minwid forgotpassword-cls"
                    onClick={this.changePasswordPopup.bind(this)}
                  >
                   Submit
                  </button>
                </div>
              </div>

            </div>

            </div>
          </div>
        </div>

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Mydetails);
