/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Select from "react-select";
import Moment from "moment";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Slider from "react-slick";
import { apiUrl, proapiUrl, apiBaseUrl, proapiBaseUrl, apiBaseUrlEnt } from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  country_symbol,
  showAlert
} from "../Helpers/SettingHelper";

import planone from "../../common/images/subscription.png";
import plantwo from "../../common/images/plan-enterprise.png";
import planthree from "../../common/images/plan-crm.png";

import logo from "../../common/images/logo.png";
import BackendImg from "../../common/images/backend.jpg";
import FrontendImg from "../../common/images/frontend.jpg";
import TabcontactImg from "../../common/images/tab-contact.jpg";
import TabImg from "../../common/images/settings.svg";
import TabImg1 from "../../common/images/tab-group.svg";
import TabImg2 from "../../common/images/setup1.svg";
import TabImg3 from "../../common/images/chat-dark.svg";
import sinfo from "../../common/images/settings-info.jpg";
import Back_inImg from "../../common/images/back_copy.png";
import Back_passImg from "../../common/images/passwrd.svg";
import mobilelogo from "../../common/images/logo.png";
import PricetickImg from "../../common/images/blue-tick.png";

import getstartImg from "../../common/images/s_bar2.svg";
import getstartWImg from "../../common/images/s_bar1.svg";
import mdetailsImg from "../../common/images/s_bar4.svg";
import mdetailsWImg from "../../common/images/s_bar3.svg";
import planImg from "../../common/images/s_bar6.svg";
import planWImg from "../../common/images/s_bar5.svg";
import helpImg from "../../common/images/s_bar8.svg";
import helpWImg from "../../common/images/s_bar7.svg";
import logoutImg from "../../common/images/s_bar10.svg";
import logoutWImg from "../../common/images/s_bar9.svg";
import dashboardImg from "../../common/images/s_bar12.svg";
import dashboardWImg from "../../common/images/s_bar11.svg";
import notficationImg from "../../common/images/s_bar14.svg";
import notficationWImg from "../../common/images/s_bar13.svg";
import subscriptionsImg from "../../common/images/s_bar16.svg";
import subscriptionsWImg from "../../common/images/s_bar15.svg";
import FileuploadImg from "../../common/images/download.svg";

var qs = require("qs");
class Subscription extends Component {

  constructor(props) {
    super(props);
    var userID =
      cookie.load("userID") !== "" &&
      typeof cookie.load("userID") !== undefined &&
      typeof cookie.load("userID") !== "undefined"
        ? cookie.load("userID")
        : "";
    this.state = {
      userID: userID,
      onboarding_type: "",
      onboarding_subscription_end_date: "",
      currentPage: this.props.match.path,
      inqury_package_name: "",
      enquiry_text_message:"",
      textareaerorr: "",

    };
    if (userID === "") {
      this.props.history.push("/");
    } else {
      this.loadUserDetails();
    }
  }

  loadUserDetails() {

    var urlShringTxt = apiUrl + "onboarding/getuserinfo?userID=" + this.state.userID;

    axios.get(urlShringTxt).then((res) => {

      var result = res.data.result_set;

      $("#dvLoading").fadeOut();

      if(res.data.status == "success"){

        console.log(result.onboarding_type, "result.onboarding_type");


        var inqury_package_name = (result.onboarding_type === "NinjaPro" ? "Ninja Enterprise" : "NinjaPro");

        console.log(inqury_package_name, "inqury_package_name");

       this.setState({  onboarding_type: result.onboarding_type, onboarding_subscription_end_date: result.onboarding_subscription_end_date, inqury_package_name: inqury_package_name },
        function () {
          $("#dvLoading").fadeOut();
        }
      );
     }

    });
  }

  handleTextChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }




  changePackage(e){
    e.preventDefault();
    var popupIdTxt = "#subscription-popup-div";
    $.magnificPopup.open({
      items: {
        src: popupIdTxt,
      },
      type: "inline",
      closeOnBgClick: false,
    });
  }

  changePackageName = (item, event) =>{

    var inqury_package_name = event.target.value;

    this.setState({
      inqury_package_name: inqury_package_name
    });

  }
  

  packageChangeRequest(){

    if(this.state.enquiry_text_message === ""){
      this.setState({textareaerorr:false});
      return false;
    }else{
      this.setState({ textareaerorr: true});
    }

    var postObject = { 
      user_id: this.state.userID,
      app_id: this.state.client_app_id,
      client_onboarding_enquriy_type: "Subscrption",
      client_onboarding_type: this.state.onboarding_type,
      client_onboarding_enquiry_package_type: this.state.inqury_package_name,
      enquiry_text_message: this.state.enquiry_text_message
     };


    axios.post(apiUrl + "onboarding/enquiry_change_package", qs.stringify(postObject))
    .then((res) => {
      if (res.data.status === "ok") {

        this.handleShowAlertFun("success", res.data.message);

      }else{

        this.handleShowAlertFun("Error", res.data.message);

      }

    });
  }

 handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }



  render() {

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (<div>
        <div className="dashboard-mobilemain">
        <div className="dashboard-mobilemenu">
          <div className="menu_icon trigger_menunav_act">
            <span className="icon-bar icon-bar1"></span>
            <span className="icon-bar icon-bar2"></span>
            <span className="icon-bar icon-bar3"></span>
          </div>

          <div className="mobile-nav-logo">
            <a href="#">
              {" "}
              <img src={mobilelogo} />{" "}
            </a>
          </div>

          <div className="mobile-menu">
            <div className="mobile-menu-header">
              <div className="mobile-menu-close">
                <span id="close_mobile_menu"></span>
              </div>
               <div className="mobile-menu-logo">
              <a title="Ninja Onboard" href="/">
                <img src={mobilelogo} />
              </a>
            </div>
            </div>
            <div className="mobile-menu-body">
              <ul className="menu">

              {cookie.load("onboarding_payment_status") === "Pending" && (
              <li className={this.state.currentPage === "/getstart" ? "active" : ""}>
                <Link to="/getstart">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={getstartImg} />
                    <img className="order_type_imgwt" src={getstartWImg} />
                  </div>
                  <div className="cat-content">
                    Get Started
                  </div>
                </div>
                </Link>{" "}
              </li>
              )}
              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={ this.state.currentPage === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={mdetailsImg} />
                      <img className="order_type_imgwt" src={mdetailsWImg} />
                    </div>
                    <div className="cat-content">
                      Dashboard
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {/*<li className={this.state.currentPage === "/notifications" ? "active" : ""}>
                <Link to="/notifications">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={notficationImg} />
                      <img className="order_type_imgwt" src={notficationWImg} />
                    </div>
                    <div className="cat-content">
                      notifications
                    </div>
                  </div>
                </Link>{" "}
              </li>*/}

              {cookie.load("onboarding_payment_status") === "Paid" && (
             <li className={this.state.currentPage === "/subscriptions" ? "active" : ""}>
                <Link to="/subscriptions">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={subscriptionsImg} />
                      <img className="order_type_imgwt" src={subscriptionsWImg} />
                    </div>
                    <div className="cat-content">
                      subscriptions
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={this.state.currentPage === "/my-details" ? "active" : ""}>
                <Link to="/my-details">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={dashboardImg} />
                      <img className="order_type_imgwt" src={dashboardWImg} />
                    </div>
                    <div className="cat-content">
                      My Details
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}
              <li className={this.state.currentPage === "/plan" ? "active" : ""}>
                <Link to="/plan">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={planImg} />
                      <img className="order_type_imgwt" src={planWImg} />
                    </div>
                    <div className="cat-content">
                       Plan
                    </div>
                  </div>
                </Link>{" "}
              </li>

              <li>
                <a href="https://support.ninjaos.com/" target="_blank">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={helpImg} />
                      <img className="order_type_imgwt" src={helpWImg} />
                    </div>
                    <div className="cat-content">
                       Help
                    </div>
                  </div>
                </a>{" "}
              </li>
              <li>
                <Link to="/logout">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={logoutImg} />
                      <img className="order_type_imgwt" src={logoutWImg} />
                    </div>
                    <div className="cat-content">
                       Logout
                    </div>
                  </div>
                </Link>{" "}
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard">
        <div className="dashboard-inner">
          <Sidebar {...this.props} />
            <div className="dashboard-content">

              <div className="getstarted subscrip-maindiv">
                <div className="your-package Subscrip-heading">
                  <h1>Subscriptions</h1>
                </div>

                <div className="package-details">
                  <div className="subscription-detail-heading">
                    <div className="subscription-detail-lhs">
                      <h5>your current package</h5>
                    </div>

                    <div className="subscription-detail-rhs">
                      <h5>subscription until:<strong>{" "}{Moment(this.state.onboarding_subscription_end_date).format("DD/MM/YYYY")}</strong></h5>
                    </div>
                  </div>
                  <div className="package-details-top">
                    <div className="pdt-lhs">
                      <img src={planone} />
                    </div>
                    <div className="pdt-rhs">
                      <div className="pdt-rhs-top">
                        <h4>{this.state.onboarding_type == "Ninja Enterprise" ? "Ninja Enterprise" : "NinjaPro"}</h4>
                        <em>Single Store, Multi Store, Grocery, Hotels</em>
                        <p>
                          Template based mobile web ordering system <br />
                          with CRM, Loyalty and Analytics.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="subscription-button">
                    <div class="black-part inquire-part"><a class="button" onClick={this.changePackage.bind(this)}>change package</a></div>
                    {/*<div class="black-part"><a class="button active">renew package</a></div>*/}
                  </div>
                </div>
              </div>
            </div>
         </div>
        </div>


          <div
          id="subscription-popup-div"
          className="white-popup mfp-hide popup_sec login-popup subscription-popup"
        >
          <div className="pop-whole">
            <div className="pop-whole-lhs">
              <div className="subscription-heading">
                <h3>I Want to Change My Package</h3>
              </div>
            </div>

            <div className="inside-popup-rhs">
              <div className="popup-header textcenter">
                <h4>
                  <img  />
                </h4>
              </div>

            <div className="popup-body">
              <div className="form-group">
                <div className={
                    this.state.forgotemailaddress !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }>
                
                    <select class="form-control subscription-select" defaultValue={this.state.inqury_package_name} onChange={this.changePackageName.bind(this, "package_name")}>
                      {this.state.inqury_package_name === "NinjaPro" && (
                        <option value="NinjaPro">NinjaPro</option>
                        )}
                      {this.state.inqury_package_name ==="Ninja Enterprise" && (
                      <option value="Ninja Enterprise" >NinjaEnterprise Lite</option>
                        )}
                    </select>


                    <label className="control-label">Choose Package</label>
                </div>
              </div>

              <div className="contact_col contact_col_textarea">
                <div className="form-group">
                  <div className="focus-out">
                    <textarea className="form-control" name="enquiry_text_message" value={this.state.enquiry_text_message} onChange={this.handleTextChange.bind(this)} placeholder="Enter Your Message here"></textarea>
                    {this.state.textareaerorr === false && (
                    <span className="error_para">This field is required.</span>)}
                  </div>
                </div>
              </div>




              <div className="form-group">
                <div className="login_pop_sub">
                  <button
                    type="button"
                    className="button btn_black btn_minwid forgotpassword-cls"
                    onClick={this.packageChangeRequest.bind(this)}
                  >
                    Request now
                  </button>
                </div>
              </div>

            </div>

            </div>
          </div>
        </div>


        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Subscription);
