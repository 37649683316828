/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";

import {
  apiUrl,
  stripeReference,
  stripeCurrency,
  stripeKey,
  stripeImage,
  stripeCompany,
} from "../Helpers/Config";
import Select from "react-select";
import StripeCheckout from "react-stripe-checkout";
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import {
  showLoader,
  hideLoader,
  showCustomAlert,
  showPriceValue,
} from "../Helpers/SettingHelper";


import {
  GET_OUTET_PRICE,
} from "../../actions";


import Sidebar from "../Myaccount/Sidebar";
import loadingImage from "../../common/images/loading_popup.gif";
import tickImage from "../../common/images/tick_popup.png";
import logo from "../../common/images/logo.png";
import started from "../../common/images/getting-started.png";
import planone from "../../common/images/plan-ninjapro.png";
import plantwo from "../../common/images/plan-enterprise.png";
import planthree from "../../common/images/plan-crm.png";
import detailpro from "../../common/images/detail-pro.png";
import howit from "../../common/images/howit-video.jpg";
import payment from "../../common/images/payment.png";
import mobilelogo from "../../common/images/logo.png";
import getstartImg from "../../common/images/s_bar2.svg";
import getstartWImg from "../../common/images/s_bar1.svg";
import mdetailsImg from "../../common/images/s_bar4.svg";
import mdetailsWImg from "../../common/images/s_bar3.svg";
import planImg from "../../common/images/s_bar6.svg";
import planWImg from "../../common/images/s_bar5.svg";
import helpImg from "../../common/images/s_bar8.svg";
import helpWImg from "../../common/images/s_bar7.svg";
import logoutImg from "../../common/images/s_bar10.svg";
import logoutWImg from "../../common/images/s_bar9.svg";
import dashboardImg from "../../common/images/s_bar12.svg";
import dashboardWImg from "../../common/images/s_bar11.svg";
import notficationImg from "../../common/images/s_bar14.svg";
import notficationWImg from "../../common/images/s_bar13.svg";
import subscriptionsImg from "../../common/images/s_bar16.svg";
import subscriptionsWImg from "../../common/images/s_bar15.svg";
import PricetickImg from "../../common/images/blue-tick.png";
import deliveryImg from "../../common/images/delivery.jpg";
import takeawayImg from "../../common/images/takeaway.jpg";
import dineinImg from "../../common/images/dinin.jpeg";
import videobtnImg from "../../common/images/video-btn.png";

import enterpriseImg from "../../common/images/enterprise.jpg";


var qs = require("qs");
class Plandetails extends Component {
  constructor(props) {
    super(props);

    var plan_detail_type = this.props.match.params.packagename !== "" &&
        typeof this.props.match.params.packagename !== undefined &&
        typeof this.props.match.params.packagename !== "undefined"
          ? this.props.match.params.packagename : "";

    if(plan_detail_type !== undefined && plan_detail_type !== "undefined" && plan_detail_type !== ""){
      cookie.save("plan_detail_type", plan_detail_type, { path: "/" });
    }
    
    this.state = {
       step: 1,
      userID: cookie.load("userID"),
      onboarding_type :"",
      plan_detail_type : cookie.load("plan_detail_type") !== "" &&
        typeof cookie.load("plan_detail_type") !== undefined &&
        typeof cookie.load("plan_detail_type") !== "undefined"
          ? cookie.load("plan_detail_type") : "",

      userDetails: [],
      outletlistprice: [],
      paymentstatus: "",
      totalOulet: 1,
      firstOutletprice: '',
      eachoutleprice: '',
      onboard_totalPrice: 0,
      totalPrice: 0,
      currentPage: this.props.match.path,
      client_enquiry_type: "",
      client_onboarding_enquriy_type: "",

    };
    this.loadUserDetails();
    this.props.loadOutletPriceDetails();
  }


  componentWillReceiveProps(nextProps){

    if (nextProps.outletlistprice !== this.state.outletlistprice) {

      if (Object.keys(nextProps.outletlistprice).length > 0) {
        this.setState({outletlistprice: nextProps.outletlistprice}, function(){
          this.calculatePrice();
        });
      }
    }
  }


  loadUserDetails() {
    var urlShringTxt =
      apiUrl + "onboarding/getuserinfo?userID=" + this.state.userID;

    axios.get(urlShringTxt).then((res) => {
      var result = res.data.result_set;
      var step = 1;
      if (result.onboarding_payment_status !== "Paid") {
        step =
          result.onboarding_complete_step !== "" &&
          result.onboarding_complete_step !== null
            ? parseInt(result.onboarding_complete_step) + 1
            : 1;
      }

      cookie.save("onboarding_payment_status", result.onboarding_payment_status, { path: "/" });

      this.setState(
        {
          onboarding_type: result.onboarding_type,
          paymentstatus: result.onboarding_payment_status,
        },
        function () {
          $("#dvLoading").fadeOut();
          this.calculatePrice();
        }
      );
    });
  }



incQty(type) {
      var totalOulet = this.state.totalOulet;
      if (type === "inc") {
        totalOulet = parseInt(totalOulet) + 1;
      } else {
        totalOulet = parseInt(totalOulet) - 1;
        if (totalOulet === 0) {
          totalOulet = 1;
        }
      }

      this.setState({ totalOulet: totalOulet }, function () {
        this.calculatePrice();
      });
    
  }

  calculatePrice() {





    if(this.state.plan_detail_type === "ninjapro"){

      var outletpricelist = this.state.outletlistprice;


      if(Object.keys(outletpricelist).length > 0){

           var totalOulet = this.state.totalOulet;
           var onboard_totalPrice = parseFloat(outletpricelist[0].settings_pro_first_outlet_price) * 12;
           var totalPrice = parseFloat(outletpricelist[0].settings_pro_first_outlet_price) * 12;

           if(this.state.totalOulet > 1){

           var each_total_outlet = this.state.totalOulet - 1;

           var eachoutleprice_total_price = parseFloat(outletpricelist[0].settings_pro_each_outlet_price) * 12 * each_total_outlet;

           var totalPrice_outlet = totalPrice + eachoutleprice_total_price;
           
           }else{

            var totalPrice_outlet = totalPrice;
           }
           
           var onboard_totalPrice = parseFloat(outletpricelist[0].settings_pro_first_outlet_price) * 12;

            this.setState({ totalPrice: totalPrice_outlet, firstOutletprice : outletpricelist[0].settings_pro_first_outlet_price, 
                      eachoutleprice: outletpricelist[0].settings_pro_each_outlet_price, onboard_totalPrice: onboard_totalPrice});
      }

    }else if(this.state.plan_detail_type == 'ninjaawt'){

      var outletpricelist = this.state.outletlistprice;

      if(Object.keys(outletpricelist).length > 0){

      var totalOulet = this.state.totalOulet;

      var totalPrice = parseFloat(outletpricelist[0].settings_enterprise_first_outlet_price) * 12;

      if(this.state.totalOulet > 1){

      var each_total_outlet = this.state.totalOulet - 1;

      var eachoutleprice_total_price = parseFloat(outletpricelist[0].settings_enterprise_each_outlet_price) * 12 * each_total_outlet;

      var totalPrice_outlet = totalPrice + eachoutleprice_total_price;

      }else{

      var totalPrice_outlet = totalPrice;

      }

      var onboard_totalPrice = parseFloat(outletpricelist[0].settings_enterprise_first_outlet_price) * 12;

      this.setState({ totalPrice: totalPrice_outlet, firstOutletprice : outletpricelist[0].settings_enterprise_first_outlet_price, 
                      eachoutleprice: outletpricelist[0].settings_enterprise_each_outlet_price, onboard_totalPrice: onboard_totalPrice});
      }

    }
   
  }


  featureshow(event){
   $("#features_list").show();
   $(".hide_btn-plan").show();
   $(".show_btn-plan").hide();
 }

 featureshide(){
   $(".hide_btn-plan").hide();
   $(".show_btn-plan").show();
   $("#features_list").hide();
  }



  featureshowpsd(event){
   $("#features_listpsd").show();
   $(".hide_btn-plan").show();
   $(".show_btn-plan").hide();
 }

 featureshidepsd(){
   $(".hide_btn-plan").hide();
   $(".show_btn-plan").show();
   $("#features_listpsd").hide();
  }


  InquiryCheck(type){

  var enquiry_type = type;
  if(enquiry_type !==""){

    this.setState({ client_enquiry_type: enquiry_type });
  }

   var popupIdTxt = "#enquire-popup";
    $.magnificPopup.open({
      items: {
        src: popupIdTxt,
      },
      type: "inline",
      closeOnBgClick: false,
    });

  }

enquirepopupClose(event){

  event.preventDefault();
   $.magnificPopup.close();
}

enquirenotifysend(event){
  
  event.preventDefault();

  var user_id = this.state.userID;
  var postObject = {
          user_id: this.state.userID,
          client_enquiry_type: this.state.client_enquiry_type,
          client_onboarding_enquriy_type: "Enquiry"
        };
  axios.post(apiUrl + "onboarding/enquiry_sumbit", qs.stringify(postObject))
    .then((res) => {
      if (res.data.status === "ok") {
         $.magnificPopup.close();
      } 
    });
}

openvideoPopup(event){

  if(event !==''){

    if(event == 1){

      this.setState({ninja_video_site_url: "https://www.youtube.com/embed/IZRJu4ZCs44"});

    }else if(event == 2){

      this.setState({ninja_video_site_url: "https://www.youtube.com/embed/mmlM0VSh-BE"});

    }else if(event == 3){

      this.setState({ninja_video_site_url: "https://www.youtube.com/embed/u_CdRNjYl3o"});
    }

     $("#video-popup").modal("show");
  }
}

updatePageInfo(page_type){

this.props.history.push("/plan");

 }


  handleTextChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });
  }

  updateInfo(){

    console.log(this.state.paymentstatus, "this.state.paymentstatus");


    if(this.state.paymentstatus !== "Paid"){

      this.props.history.push("/getstart");

    }
  }


  render() {

    const settings_plan = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      margin: 10,
      autoplay: false,
      dots: false,
      arrows: false,
      responsive: [
  {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
      },
    },
  ],
    };

    const settings_mobile_plan = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      margin: 10,
      autoplay: false,
      dots: false,
      arrows: false,
      responsive: [
  {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
      },
    },
  ],
    };
      return (<div>
        <div className="dashboard-mobilemain">
        <div className="dashboard-mobilemenu">
          <div className="menu_icon trigger_menunav_act">
            <span className="icon-bar icon-bar1"></span>
            <span className="icon-bar icon-bar2"></span>
            <span className="icon-bar icon-bar3"></span>
          </div>

          <div className="mobile-nav-logo">
            <a href="#">
              {" "}
              <img src={mobilelogo} />{" "}
            </a>
          </div>

          <div className="mobile-menu">
            <div className="mobile-menu-header">
              <div className="mobile-menu-close">
                <span id="close_mobile_menu"></span>
              </div>
              <div className="mobile-menu-logo">
                <a title="Cake Avenue" href="/">
                  <img src={mobilelogo} />
                </a>
              </div>
              
            </div>
            <div className="mobile-menu-body">
              <ul className="menu">
              {cookie.load("onboarding_payment_status") === "Pending" && (
              <li className={this.state.currentPage === "/getstart" ? "active" : ""}>
                <Link to="/getstart">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={getstartImg} />
                    <img className="order_type_imgwt" src={getstartWImg} />
                  </div>
                  <div className="cat-content">
                    Get Started
                  </div>
                </div>
                </Link>{" "}
              </li>
              )}
              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={ this.state.currentPage === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={mdetailsImg} />
                      <img className="order_type_imgwt" src={mdetailsWImg} />
                    </div>
                    <div className="cat-content">
                      Dashboard
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li>
                <Link to="/notifications">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={notficationImg} />
                      <img className="order_type_imgwt" src={notficationWImg} />
                    </div>
                    <div className="cat-content">
                      notifications
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li>
                <Link to="/subscriptions">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={subscriptionsImg} />
                      <img className="order_type_imgwt" src={subscriptionsWImg} />
                    </div>
                    <div className="cat-content">
                      subscriptions
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              <li>
                <Link to="/my-details">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={dashboardImg} />
                      <img className="order_type_imgwt" src={dashboardWImg} />
                    </div>
                    <div className="cat-content">
                      My Details
                    </div>
                  </div>
                </Link>{" "}
              </li>



              
              <li className={this.state.currentPage === "/plan" || this.state.currentPage ==="/plan/:packagename" ? "active" : ""}>
                <Link to="/plan">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={planImg} />
                      <img className="order_type_imgwt" src={planWImg} />
                    </div>
                    <div className="cat-content">
                       Plan
                    </div>
                  </div>
                </Link>{" "}
              </li>
              

              <li>
                <a href="https://support.ninjaos.com/" target="_blank">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={helpImg} />
                      <img className="order_type_imgwt" src={helpWImg} />
                    </div>
                    <div className="cat-content">
                       Help
                    </div>
                  </div>
                </a>{" "}
              </li>
              <li>
                <Link to="/logout">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={logoutImg} />
                      <img className="order_type_imgwt" src={logoutWImg} />
                    </div>
                    <div className="cat-content">
                       Logout
                    </div>
                  </div>
                </Link>{" "}
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>

        <div className="dashboard">
          <div className="dashboard-inner">
            <Sidebar {...this.state} />
            <div className="dashboard-content">

              {this.state.plan_detail_type == "ninjapro" &&(

                <div className="package-details">
                  <div className="package-details-top">
                    <div className="pdt-lhs">
                      <img src={detailpro} />
                    </div>
                    <div className="pdt-rhs">
                      <div className="pdt-rhs-top">
                        <h1>NinjaPro</h1>
                        <h3>Turnkey Ordering System For Your F&B Store</h3>
                        
                        <em>Single Store, Multi Store, Grocery, Hotels</em>
                        <p>
                          Template based mobile web ordering system <br />
                          with CRM, Loyalty and Analytics.
                        </p>
                      </div>
                      <div className="pdt-rhs-btm">
                        <h4>See How It Works</h4>
                        <div>
       
                          <Slider {...settings_plan}>
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this, "1")}>{" "}
                                <img src={deliveryImg} />
                                <img className="video-btn-slider" src={videobtnImg} /> {" "}</a>
                            </div>
                            </div>
                            
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this,"2")}>{" "}
                              <img src={takeawayImg} />
                              <img className="video-btn-slider" src={videobtnImg} /> {" "}</a>
                            </div>
                            </div>
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this, "3")}>{" "}
                              <img src={dineinImg} />
                              <img className="video-btn-slider" src={videobtnImg} />{" "}</a>
                            </div>
                            </div>

                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* =========Plan Package Choosing Start==========  */}

                  <div className="package-details-btm">
                    <div className="package-main-box">
                      <div className="package-details-main">
                        <div className="package-details-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h1>Choose Plan</h1>
                                <h4>you will get</h4>
                              </div>
                            </li>
                          </ul>

                          <ul className="package-details-bottom">
                            <li>delivery</li>
                            <li>takeaway</li>
                            <li>dine in</li>
                            <li>reservation</li>
                            <li>cash back loyalty</li>
                            <li>e vouchers</li>
                            <li>no commissions</li>
                            <li>unlimited orders</li>
                            <li>no of products</li>
                            <li>promo codes</li>
                            <li>CRM</li>
                            <li>menu setup (Done for you)</li>
                            <li>payment gateway setup</li>
                            <li>delivery integration</li>
                            <li>email support</li>
                            <li>phone support</li>
                        </ul>
                        </div>
                      </div>
                    </div>

                    <div className="package-middle-container destop-package-middle">
                      <div className="package-details-middle">
                        <div className="package-middle-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h4>NinjaPro</h4>
                                <p>single store, multi store grocery, hotels</p>
                              </div>
                            </li>
                            <li>
                              <div className="package-headingcontent">
                               <h6>per outlet</h6>
                                <h1><sup>$</sup>{showPriceValue(this.state.totalPrice)} </h1>
                                <p>monthly${showPriceValue(this.state.firstOutletprice)}</p>
                              </div>
                            </li>
                          </ul>

                          <ul className="package-priceul">
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li>200 products</li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                          </ul>

                          <div className="outlet-price-btn">
                            <div className="outlet-number">
                              <p>Outlets</p>
                              <div class="qty_bx">
                                <span class="qty_minus" onClick={this.incQty.bind(this, "dec")}> - </span>
                                <input
                                  type="text"
                                  value={this.state.totalOulet}
                                  name="totalOulet"
                                  onChange={this.handleTextChange.bind(this)}
                                />
                                <span class="qty_plus" onClick={this.incQty.bind(this, "inc")}> + </span>
                              </div>
                            </div>

                            <div className="black-part">
                              <a href={void 0} onClick={this.updateInfo.bind(this)}className="button">Checkout</a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="package-details-lower ninjapro_psg">
                        <div className="package-middle-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h4>NinjaPro PSG</h4>
                              <p>single store, multi store grocery, hotels</p>
                              </div>
                            </li>
                            <li>
                              <div className="package-headingcontent">
                                   <h6 className="p_middle">1 year license for 1 outlet</h6>
                                   <h1 className="p_middle_price"><sup>$</sup>3,300.00 </h1>
                               </div>
                            </li>
                          </ul>

                          <ul className="package-priceul">
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li>200 products</li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                          </ul>
                          <div className="black-part inquire-part">
                           <a className="bod_link ninjapro" href="https://www.gobusiness.gov.sg/images/psg/Jankosoft_20200023_Annex_3_20200625144914_Part_1.pdf" target="_blank">learn more</a>
                          
                            <a className="button" onClick= {this.InquiryCheck.bind(this, 'NinjaPro')}>inquire now</a>
                          </div>
                        </div>
                      </div>

                    </div>

                     <div className="package-middle-container mobile-package-middle">
                      <Slider {...settings_mobile_plan}>
                        <div>{/* =========1st SLIDER START==========  */}
                          <div className="package-details-middle">
                            <div className="package-middle-header">
                              <ul>
                                <li>
                                  <div className="package-headingtop">
                                   <h4>NinjaPro</h4>
                                    <p>single store, multi store grocery, hotels</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="package-headingcontent">
                                   <h6>per outlet</h6>
                                   <h1><sup>$</sup> </h1>
                                   <p>monthly$ </p>
                                  </div>
                                </li>
                              </ul>

                              <ul className="package-priceul">
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li>200 products</li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                              </ul>

                              <div className="outlet-price-btn">
                                <div className="outlet-number">
                                  <p>Outlets</p>
                                  <div class="qty_bx">
                                  <span class="qty_minus" onClick={this.incQty.bind(this, "dec")}> - </span>
                                  <input
                                    type="text"
                                    value={this.state.totalOulet}
                                    name="totalOulet"
                                    onChange={this.handleTextChange.bind(this)}
                                  />
                                  <span class="qty_plus" onClick={this.incQty.bind(this, "inc")}> + </span>
                                </div>
                                </div>
                                <div className="black-part">
                                  <a href={void 0} onClick={this.updateInfo.bind(this)} className="button">Checkout</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>{/* =========2nd SLIDER START==========  */}
                          <div className="package-details-lower">
                            <div className="package-middle-header">
                              <ul>
                                <li>
                                  <div className="package-headingtop">
                                   <h4>NinjaPro PSG</h4>
                                    <p>single store, multi store grocery, hotels</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="package-headingcontent">
                                   <h6 className="p_middle">1 year license for 1 outlet</h6>
                                   <h1 className="p_middle_price"><sup>$</sup>3,300.00 </h1>
                                  </div>
                                </li>
                              </ul>

                              <ul className="package-priceul">
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li>200 products</li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                                <li><img src={PricetickImg} /></li>
                              </ul>
                              <div className="black-part inquire-part">
                                 <a className="bod_link ninjapro" href="https://www.gobusiness.gov.sg/images/psg/Jankosoft_20200023_Annex_3_20200625144914_Part_1.pdf" target="_blank">learn more</a>
                                <a className="button" onClick= {this.InquiryCheck.bind(this, 'NinjaPro')}>inquire now</a>
                              </div>
                            </div>

                          </div>
                        </div>
                      </Slider>
                    </div>

                  </div>{/* =========Plan Package Choosing End==========  */}

                  <div className="plan-chose-maindiv">
                    <div className="plan-chose-hedaingdiv">
                      <h1>Choose Plan</h1>
                      <h4>you will get</h4>
                    </div>

                    <div className="plan-chose-innerdiv">
                      <div className="plan-chose-innerlhs">
                        <div className="plan-chose-innertop">
                          <h4>NinjaPro</h4>
                          <p>single store, multi store grocery, hotels</p>
                        </div>

                        <div className="plan-chose-innerprice">
                          <h6>per outlet</h6>
                          <h1><sup>$</sup>1,260 </h1>
                          <p>monthly $105</p>
                        </div>

                        <div className="plan-drop-maindiv">
                          <div className="plan-drop-btn">
                            <a class="button show_btn-plan" onClick={this.featureshow.bind(this)}> Show Features</a>

                            <ul className="plan-details-div" id="features_list">
                              <li>delivery</li>
                              <li>takeaway</li>
                              <li>dine in</li>
                              <li>reservation</li>
                              <li>cash back loyalty</li>
                              <li>e vouchers</li>
                              <li>no commissions</li>
                              <li>unlimited orders</li>
                              <li>no of products</li>
                              <li>promo codes</li>
                              <li>CRM</li>
                              <li>menu setup (Done for you)</li>
                              <li>payment gateway setup</li>
                              <li>delivery integration</li>
                              <li>email support</li>
                              <li>phone support</li>
                            </ul>
                            <a class="button hide_btn-plan" onClick={this.featureshide.bind(this)}>Hide Features</a>
                            <div className="outlet-price-btn">
                              <div className="outlet-number">
                                <p>Outlets</p>
                                <div class="qty_bx">
                                <span class="qty_minus" onClick={this.incQty.bind(this, "dec")}> - </span>
                                <input
                                  type="text"
                                  value={this.state.totalOulet}
                                  name="totalOulet"
                                  onChange={this.handleTextChange.bind(this)}
                                />
                                <span class="qty_plus" onClick={this.incQty.bind(this, "inc")}> + </span>
                              </div>
                              </div>

                              <div className="black-part show_btn_div">
                                <a href={void 0} onClick={this.updateInfo.bind(this)} className="button"> Checkout</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="plan-chose-innerlhs">
                        <div className="plan-chose-innertop">
                          <h4>NinjaPro PSG</h4>
                          <p>single store, multi store grocery, hotels</p>
                        </div>

                        <div className="plan-chose-innerprice">
                          <h6>per outlet</h6>
                          <h1><sup>$</sup>3,300.00 </h1>
                        </div>

                        <div className="plan-drop-maindiv">
                          <div className="plan-drop-btn">
                            <a class="button show_btn-plan" onClick={this.featureshowpsd.bind(this)}> Show Features</a>

                            <ul className="plan-details-div" id="features_listpsd">
                              <li>delivery</li>
                              <li>takeaway</li>
                              <li>dine in</li>
                              <li>reservation</li>
                              <li>cash back loyalty</li>
                              <li>e vouchers</li>
                              <li>no commissions</li>
                              <li>unlimited orders</li>
                              <li>no of products</li>
                              <li>promo codes</li>
                              <li>CRM</li>
                              <li>menu setup (Done for you)</li>
                              <li>payment gateway setup</li>
                              <li>delivery integration</li>
                              <li>email support</li>
                              <li>phone support</li>
                            </ul>


                            <a class="button hide_btn-plan" onClick={this.featureshidepsd.bind(this)}>Hide Features</a>
                            <div className="black-part inquire-plan-part">
                              <a href={void 0} onClick= {this.InquiryCheck.bind(this, 'NinjaPro')} className="button">inquire now</a>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>

                  <div className="back-package">
                  <a href={void 0} onClick= {this.updatePageInfo.bind(this, 2)} className="button"> back</a>
                  </div>

                </div>

                )}


                {this.state.plan_detail_type == "ninjaawt" && (

                <div className="package-details enterpriselite-div">
                  <div className="package-details-top">
                    <div className="pdt-lhs">
                      <img src={enterpriseImg} />
                    </div>
                    <div className="pdt-rhs">
                      <div className="pdt-rhs-top">
                        <h1>NinjaEnterprise Lite</h1>
                        <h3>advanced digital solution</h3>
                        <em>Single Store, Multi Store, Grocery, Hotels</em>
                        <p>
                          Template based mobile web ordering system <br />
                          with CRM, Loyalty and Analytics.
                        </p>
                      </div>
                      <div className="pdt-rhs-btm">
                        <h4>See How It Works</h4>
                        <div>
                          <Slider {...settings_plan}>
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this, "1")}>{" "}
                                <img src={deliveryImg} />
                                <img className="video-btn-slider" src={videobtnImg} /> {" "}</a>
                            </div>
                            </div>
                            
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this,"2")}>{" "}
                              <img src={takeawayImg} />
                              <img className="video-btn-slider" src={videobtnImg} /> {" "}</a>
                            </div>
                            </div>
                            <div>
                            <div className="plan-video">
                              <a onClick={this.openvideoPopup.bind(this, "3")}>{" "}
                              <img src={dineinImg} />
                              <img className="video-btn-slider" src={videobtnImg} />{" "}</a>
                            </div>
                            </div>

                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* =========Plan Package Choosing Start==========  */}

                  <div className="package-details-btm">
                    <div className="package-main-box enterprice-mainbox">
                      <div className="package-details-main enterprice-details-main">
                        <div className="package-details-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h1>Choose Plan</h1>
                                <h4>you will get</h4>
                              </div>
                            </li>
                          </ul>

                          <ul className="package-details-bottom enterprice-details-bottom">
                            <li className="package-details-li">1 x Point of sale (POS), Printer and Cashdrawer</li>
                            <li className="package-details-li">1 x Xero Account Software</li>
                            <li className="package-details-li">1 x Template Based Online Ordering Website</li>
                            <li>delivery</li>
                            <li>takeaway</li>
                            <li>dine in</li>
                            <li>reservation</li>
                            <li>cash back loyalty</li>
                            <li>e vouchers</li>
                            <li>no commissions</li>
                            <li>unlimited orders</li>
                            <li>no of products</li>
                            <li>promo codes</li>
                            <li>CRM</li>
                            <li>menu setup (Done for you)</li>
                            <li>payment gateway setup</li>
                            <li>delivery integration</li>
                            <li>email support</li>
                            <li>phone support</li>
                        </ul>
                        </div>
                      </div>

    
                    </div>

                    <div className="package-middle-container destop-package-middle enterprise-middle-div">
                      
                      <div className="package-details-lower enterprice-details-lower">
                        <div className="package-middle-header">
                          <ul>
                            <li>
                              <div className="package-headingtop">
                               <h4>NinjaLite ADS Package</h4>
                              <p>single store, multi store grocery, hotels</p>
                              </div>
                            </li>
                            <li>
                              <div className="package-headingcontent">
                                   <h6 className="p_middle">1 year license for 1 outlet</h6>
                                   <h1 className="p_middle_price"><sup>$</sup>2,320.00 </h1>
                               </div>
                            </li>
                          </ul>

                          <ul className="package-priceul enterprice-priceul">
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li>200 products</li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                            <li><img src={PricetickImg} /></li>
                          </ul>
                          <div className="black-part inquire-part epric-btn">
                             <a className="bod_link" href="https://www.imda.gov.sg/advanceddigitalsolutions#Food-Services" target="_blank">learn more</a>

                            <a className="button" onClick= {this.InquiryCheck.bind(this, 'Ninja Enterprise')}>inquire now</a>
                          </div>
                        </div>
                      </div>
                    </div>

                     <div className="package-middle-container mobile-package-middle mobile-enterprice-middle">
                      <Slider {...settings_mobile_plan}>
                        <div>{/* =========2nd SLIDER START==========  */}
                          <div className="package-details-lower enterprice-details-lower">
                            <div className="package-middle-header">
                              <ul>
                                <li>
                                  <div className="package-headingtop">
                                   <h4>NinjaLite ADS Package</h4>
                                  <p>single store, multi store grocery, hotels</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="package-headingcontent">
                                       <h6 className="p_middle">1 year license for 1 outlet</h6>
                                       <h1 className="p_middle_price"><sup>$</sup>2,320.00 </h1>
                                   </div>
                                </li>
                              </ul>

                              <ul className="package-priceul enterprice-priceul">
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li>200 products</li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                                  <li><img src={PricetickImg} /></li>
                              </ul>
                              <div className="black-part inquire-part epric-btn">
                                <a className="bod_link" href="https://www.imda.gov.sg/advanceddigitalsolutions#Food-Services" target="_blank">learn more</a>
                                <a className="button" onClick= {this.InquiryCheck.bind(this, 'Ninja Enterprise')}>inquire now</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>

                  </div>{/* =========Plan Package Choosing End==========  */}

                  <div className="plan-chose-maindiv">
                    <div className="plan-chose-hedaingdiv">
                      <h1>Choose Plan</h1>
                      <h4>you will get</h4>
                    </div>

                    <div className="plan-chose-innerdiv">

                      <div className="plan-chose-innerlhs">
                        <div className="plan-chose-innertop">
                          <h4>NinjaLite ADS Package</h4>
                          <p>single store, multi store grocery, hotels</p>
                        </div>

                        <div className="plan-chose-innerprice">
                          <h6 className="p_middle">1 year license for 1 outlet</h6>
                          <h1><sup>$</sup>2,320.00 </h1>
                        </div>

                        <div className="plan-drop-maindiv">
                          <div className="plan-drop-btn">
                            <a class="button show_btn-plan" onClick={this.featureshow.bind(this)}> Show Features</a>

                            <ul className="plan-details-div" id="features_list">
                              <li className="package-details-li">1 x Point of sale (POS), Printer and Cashdrawer</li>
                              <li className="package-details-li">1 x Xero Account Software</li>
                              <li className="package-details-li">1 x Template Based Online Ordering Website</li>
                              <li>delivery</li>
                              <li>takeaway</li>
                              <li>dine in</li>
                              <li>reservation</li>
                              <li>cash back loyalty</li>
                              <li>e vouchers</li>
                              <li>no commissions</li>
                              <li>unlimited orders</li>
                              <li>no of products</li>
                              <li>promo codes</li>
                              <li>crm</li>
                              <li>menu setup (done for you)</li>
                              <li>payment gateway setup</li>
                              <li>delivery integration</li>
                              <li>email support</li>
                              <li>phone support</li>
                            </ul>
                            <a class="button hide_btn-plan" onClick={this.featureshide.bind(this)}>Hide Features</a>
                            <div className="black-part inquire-plan-part">
                              <a href={void 0} onClick= {this.InquiryCheck.bind(this, 'Ninja Enterprise')} className="button">inquire now</a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                   <div className="back-package">
                  <a href={void 0} onClick= {this.updatePageInfo.bind(this, 2)} className="button"> back</a>
                  </div>

                </div>
                )}

            </div>
          </div>


          {/*Enquiry Popup*/}
          <div  id="enquire-popup"   className="white-popup mfp-hide popup_sec pay-conf-popup">
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header">Enquiry Submission</div>
                  <div className="alert_body">
                    <div className="alt_btns">
                        <p>Our sales consultant will be in touch with you shortly to share more about the solution.</p>
                        <p>Do you wish to proceed ?</p>
                        <div className="alt_btns">
                          <a onClick={this.enquirenotifysend.bind(this)}  className="button button-right">Yes</a>
                          <a onClick={this.enquirepopupClose.bind(this)} className=" button button-left">No</a>
                        </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Enquiry popup - End*/}


          {/*Enquiry Popup*/}

          <div className="white-popup mfp-hide popup_sec pay-conf-popup">
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header"></div>
                  <div className="alert_body">
                    <div className="alt_btns">
                      <p></p>
  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Enquiry popup - End*/}

          <div className="modal fade commom-modal-topcls" id="video-popup">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="common-modal-head">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true">
                      </button>
                    </div>
                    <div className="modal-detail-box">
                      <iframe width="560" height="315" src={this.state.ninja_video_site_url+"?controls=0&amp;&amp;autoplay=1&amp;loop=1&amp;mute=1"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div id="dvLoading"></div>
        </div>
      </div>
      );
  }
}

const mapStateTopProps = (state) => {
  var outletlistprice = Array();
  if (Object.keys(state.outletprice).length > 0) {
    if (state.outletprice[0].status === "ok") {
      outletlistprice = state.outletprice[0].result;
    }
  }

  return {
    outletlistprice: outletlistprice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadOutletPriceDetails: () => {
      dispatch({ type: GET_OUTET_PRICE });
    },
  };
};



export default connect(mapStateTopProps, mapDispatchToProps)(Plandetails);
