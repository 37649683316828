/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";

import Sidebar from "../Myaccount/Sidebar";
import planone from "../../common/images/plan-ninjapro.png";
import plantwo from "../../common/images/plan-enterprise.png";
import planthree from "../../common/images/plan-crm.png";

import mobilelogo from "../../common/images/logo.png";
import getstartImg from "../../common/images/s_bar2.svg";
import getstartWImg from "../../common/images/s_bar1.svg";
import mdetailsImg from "../../common/images/s_bar4.svg";
import mdetailsWImg from "../../common/images/s_bar3.svg";
import planImg from "../../common/images/s_bar6.svg";
import planWImg from "../../common/images/s_bar5.svg";
import helpImg from "../../common/images/s_bar8.svg";
import helpWImg from "../../common/images/s_bar7.svg";
import logoutImg from "../../common/images/s_bar10.svg";
import logoutWImg from "../../common/images/s_bar9.svg";
import dashboardImg from "../../common/images/s_bar12.svg";
import dashboardWImg from "../../common/images/s_bar11.svg";
import notficationImg from "../../common/images/s_bar14.svg";
import notficationWImg from "../../common/images/s_bar13.svg";
import subscriptionsImg from "../../common/images/s_bar16.svg";
import subscriptionsWImg from "../../common/images/s_bar15.svg";
import PricetickImg from "../../common/images/blue-tick.png";

class Plan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onboarding_type: "",
      currentPage: this.props.match.path,
    };
    $("#dvLoading").fadeIn();
  }

  componentDidMount(){
   $("#dvLoading").fadeOut(2000);
  }

  planDetails(type){

    if(type == "NinjaPro"){
      this.props.history.push("/plan/ninjapro");
    }else  if(type == "Ninja Enterprise"){
      this.props.history.push("/plan/ninjaawt");
    }

  }

  render() {
    return (<div>
        <div className="dashboard-mobilemain">
        <div className="dashboard-mobilemenu">
          <div className="menu_icon trigger_menunav_act">
            <span className="icon-bar icon-bar1"></span>
            <span className="icon-bar icon-bar2"></span>
            <span className="icon-bar icon-bar3"></span>
          </div>

          <div className="mobile-nav-logo">
            <a href="#">
              {" "}
              <img src={mobilelogo} />{" "}
            </a>
          </div>

          <div className="mobile-menu">
            
            <div className="mobile-menu-header">
              <div className="mobile-menu-close">
                <span id="close_mobile_menu"></span>
              </div>
               <div className="mobile-menu-logo">
              <a title="Ninja Onboard" href="/">
                <img src={mobilelogo} />
              </a>
            </div>
            </div>

            <div className="mobile-menu-body">
              <ul className="menu">
                   {cookie.load("onboarding_payment_status") === "Pending" && (
              <li className={this.state.currentPage === "/getstart" ? "active" : ""}>
                <Link to="/getstart">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={getstartImg} />
                    <img className="order_type_imgwt" src={getstartWImg} />
                  </div>
                  <div className="cat-content">
                    Get Started
                  </div>
                </div>
                </Link>{" "}
              </li>
              )}
              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={ this.state.currentPage === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={mdetailsImg} />
                      <img className="order_type_imgwt" src={mdetailsWImg} />
                    </div>
                    <div className="cat-content">
                      Dashboard
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {/*<li className={ this.state.currentPage === "/notifications" ? "active" : ""}>
                <Link to="/notifications">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={notficationImg} />
                      <img className="order_type_imgwt" src={notficationWImg} />
                    </div>
                    <div className="cat-content">
                      notifications
                    </div>
                  </div>
                </Link>{" "}
              </li>*/}

              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={ this.state.currentPage === "/subscriptions" ? "active" : ""}>
                <Link to="/subscriptions">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={subscriptionsImg} />
                      <img className="order_type_imgwt" src={subscriptionsWImg} />
                    </div>
                    <div className="cat-content">
                      subscriptions
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              
             <li className={ this.state.currentPage === "/my-details" ? "active" : ""}>
                <Link to="/my-details">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={dashboardImg} />
                      <img className="order_type_imgwt" src={dashboardWImg} />
                    </div>
                    <div className="cat-content">
                      My Details
                    </div>
                  </div>
                </Link>{" "}
              </li>
              
              
             <li className={ this.state.currentPage === "/plan" ? "active" : ""}>
                <Link to="/plan">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={planImg} />
                      <img className="order_type_imgwt" src={planWImg} />
                    </div>
                    <div className="cat-content">
                       Plan
                    </div>
                  </div>
                </Link>{" "}
              </li>

              <li>
                <a href="https://support.ninjaos.com/" target="_blank">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={helpImg} />
                      <img className="order_type_imgwt" src={helpWImg} />
                    </div>
                    <div className="cat-content">
                       Help
                    </div>
                  </div>
                </a>{" "}
              </li>
              <li>
                <Link to="/logout">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={logoutImg} />
                      <img className="order_type_imgwt" src={logoutWImg} />
                    </div>
                    <div className="cat-content">
                       Logout
                    </div>
                  </div>
                </Link>{" "}
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>


      <div className="dashboard">
        <div className="dashboard-inner">
          <Sidebar {...this.props} />

          <div className="dashboard-content">
            <div className="getstarted">
              {/* =========Package Start==========  */}

              <div className="your-package">
                <h1>Plan</h1>
                <ul>
                  <li
                    className={
                      this.state.onboarding_type === "NinjaPro" ? "active" : ""
                    }
                    onClick={this.planDetails.bind(this, "NinjaPro")}
                  >
                    <div className="package-img">
                      <img src={planone} />
                    </div>
                    <div className="package-content">
                      <h3>NinjaPro</h3>
                      <span>Single Store, Multi Store, Grocery, Hotels</span>
                      <p>
                        Template based mobile web ordering system with CRM,
                        Loyalty and Analytics.
                      </p>
                    </div>
                    <div className="package-li-btn">
                      <a href="#" className="button package-btn">
                        Learn More
                      </a>
                    </div>
                  </li>
                  <li
                    className={
                      this.state.onboarding_type === "Ninja Enterprise"
                        ? "active"
                        : ""
                    }
                    onClick={this.planDetails.bind(this, "Ninja Enterprise")}
                  >
                    <div className="package-img">
                      <img src={plantwo} />
                    </div>
                    <div className="package-content">
                      <h3>NinjaEnterprise Pro</h3>
                      <span>Single Store, Multi Store, Grocery, Hotels</span>
                      <p>
                        Custom designed web app ordering system with Multi Tier
                        CRM, Loyalty and Analytics. Extensive integration and
                        customisations available to suit your business
                        requirements.
                      </p>
                    </div>
                    <div className="package-li-btn">
                      <a href="#" className="button package-btn">
                        Learn More
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="package-img">
                      <img src={planthree} />
                    </div>
                    <div className="package-content">
                      <h3>CRM</h3>
                      <span>Loyalty & Payments</span>
                      <p>
                        Setup and launch a cashback loyalty system for your
                        store today. Accept payments and build a loyal customer
                        base today !
                      </p>
                    </div>
                    <div className="package-li-btn">
                      <a href="#" className="button package-btn">
                       Coming Soon
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="dvLoading"></div>
    </div>
    );
  }
}
export default withRouter(Plan);
