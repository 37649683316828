/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import logo from "../../common/images/logo.png";

import getstartImg from "../../common/images/s_bar2.svg";
import getstartWImg from "../../common/images/s_bar1.svg";
import mdetailsImg from "../../common/images/s_bar4.svg";
import mdetailsWImg from "../../common/images/s_bar3.svg";
import planImg from "../../common/images/s_bar6.svg";
import planWImg from "../../common/images/s_bar5.svg";
import helpImg from "../../common/images/s_bar8.svg";
import helpWImg from "../../common/images/s_bar7.svg";
import logoutImg from "../../common/images/s_bar10.svg";
import logoutWImg from "../../common/images/s_bar9.svg";
import dashboardImg from "../../common/images/s_bar12.svg";
import dashboardWImg from "../../common/images/s_bar11.svg";
import notficationImg from "../../common/images/s_bar14.svg";
import notficationWImg from "../../common/images/s_bar13.svg";
import subscriptionsImg from "../../common/images/s_bar16.svg";
import subscriptionsWImg from "../../common/images/s_bar15.svg";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.match.path,
    };
  }

  render() {

    return (
    <div className="sidebar-maindiv">
      <div className="sidebar">
        <div className="sidebar-nav-top">
          <div className="sidebar-nav-logo">
            <a href="#">
              {" "}
              <img src={logo} />{" "}
            </a>
          </div>
          <div className="sidebar-nav-links">
            <ul>
            {cookie.load("onboarding_payment_status") === "Pending" && (
              <li className={this.state.currentPage === "/getstart" ? "active" : ""}>
                <Link to="/getstart">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={getstartImg} />
                    <img className="order_type_imgwt" src={getstartWImg} />
                  </div>
                  <div className="cat-content">
                    Get Started
                  </div>
                </div>
                </Link>{" "}
              </li>
              )}
              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={ this.state.currentPage === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={dashboardImg} />
                      <img className="order_type_imgwt" src={dashboardWImg} />    
                    </div>
                    <div className="cat-content">
                     Dashboard
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}
          
              {/*<li className={this.state.currentPage === "/notifications" ? "active" : ""}>
                <Link to="/notifications">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={notficationImg} />
                      <img className="order_type_imgwt" src={notficationWImg} />
                    </div>
                    <div className="cat-content">
                      notifications
                    </div>
                  </div>
                </Link>{" "}
              </li>*/}

              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={this.state.currentPage === "/subscriptions" ? "active" : ""}>
                <Link to="/subscriptions">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={subscriptionsImg} />
                      <img className="order_type_imgwt" src={subscriptionsWImg} />
                    </div>
                    <div className="cat-content">
                      subscriptions
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

       
               <li className={this.state.currentPage === "/my-details" ? "active" : ""}>
                 <Link to="/my-details">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={mdetailsImg} />
                      <img className="order_type_imgwt" src={mdetailsWImg} />
                    </div>
                    <div className="cat-content">
                      My Details
                    </div>
                  </div>
                </Link>{" "}
              </li>


            </ul>
          </div>

        <div className="sidebar-nav-btm">
          <ul>
            <li className={this.state.currentPage === "/plan" ? "active" : ""}>
              <Link to="/plan">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={planImg} />
                    <img className="order_type_imgwt" src={planWImg} />
                  </div>
                  <div className="cat-content">
                     Plan
                  </div>
                </div>
              </Link>{" "}
            </li>

            <li>
              <a href="https://support.ninjaos.com/" target="_blank">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={helpImg} />
                    <img className="order_type_imgwt" src={helpWImg} />
                  </div>
                  <div className="cat-content">

                     Help
                  </div>
                </div>
              </a>{" "}
            </li>
            <li>
              <Link to="/logout">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={logoutImg} />
                    <img className="order_type_imgwt" src={logoutWImg} />
                  </div>
                  <div className="cat-content">
                     Logout
                  </div>
                </div>
              </Link>{" "}
            </li>
          </ul>
        </div>
      </div>
      </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
