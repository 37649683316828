import { SET_OUTET_PRICE } from '../actions';

const outletprice = (state = [], action) => {
  switch (action.type) {
    case SET_OUTET_PRICE:
      return [...action.value];
    default: return state;
  }
}

export default outletprice;