/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "../../common/css/countryflag.css";
import { apiUrl } from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  showAlert
} from "../Helpers/SettingHelper";
import heroimage from "../../common/images/hero-image.jpg";

import errorImg from "../../common/images/error.svg";
import errorwImg from "../../common/images/error_wrong.svg";

import helpImg from "../../common/images/s_bar8.svg";
import helpWImg from "../../common/images/s_bar7.svg";
import logoutImg from "../../common/images/s_bar10.svg";
import logoutWImg from "../../common/images/s_bar9.svg";
import mobilelogo from "../../common/images/logo.png";


var qs = require("qs");
class Home extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      displayType: "login",
      div_verification_code: "hide",
      div_dashboard_inner: "hide",
      customer_name: "",
      customer_phone: "",
      customer_email: "",
      customer_password: "",
      customer_password_re: "",
      customer_terms_condition: false,
      error_customer_name: "",
      error_customer_email: "",
      error_invalid_email: "",
      error_exist_email: "",
      error_exist_phone: "",
      error_customer_phone: "",
      error_customer_password: "",
      error_customer_password_re: "",
      error_customer_terms_condition: "",
      error_customer_captcha: false,
      code_digit1: "",
      code_digit2: "",
      code_digit3: "",
      code_digit4: "",
      code_digit5: "",
      code_digit6: "",
      login_customer_email: "",
      login_customer_password: "",
      error_login_customer_email: "",
      error_login_customer_password: "",
      error_login_invalid_email:"",
      error_login:'',
      forgotemailaddress: "",
      errorforgotemailaddress:"",
    };
  }

  componentWillReceiveProps(PropsData) {}

  componentDidMount() {


  }

  handleSignupChange(event) {
    var value = event.target.value;
    if (event.target.name === "customer_name") {
      if (value !== "") {
        this.setState({ error_customer_name: false });
      } else {
        this.setState({ error_customer_name: true });
      }
      this.setState({ customer_name: value });
    } else if (event.target.name === "customer_phone") {
      if (value !== "") {
        this.setState({ error_customer_phone: false });
      } else {
        this.setState({ error_customer_phone: true });
      }
      this.setState(
        { customer_phone: value },
        function () {
          this.checkAlreadyExit("phone");
        }.bind(this)
      );
    } else if (event.target.name === "customer_email") {
      if (value !== "") {
        this.setState({ error_customer_email: false });
        this.setState(
        { customer_email: value },
        function () {
          var validate_Email_Fun = validateEmailFun(this.state.customer_email);
          if (validate_Email_Fun === true) {
            this.checkAlreadyExit("email");
          }
        }.bind(this)
      );
      } else {
        this.setState({ error_customer_email: true });
      }
      this.setState(
        { customer_email: value },
        function () {
          var validate_Email_Fun = validateEmailFun(this.state.customer_email);
          if (validate_Email_Fun === true) {
            this.checkAlreadyExit("email");
          }
        }.bind(this)
      );
    } else if (event.target.name === "customer_password") {
      if (value !== "") {
        this.setState({ error_customer_password: false });
      } else {
        this.setState({ error_customer_password: true });
      }
      this.setState({ customer_password: value });
    } else if (event.target.name === "customer_password_re") {
      if (value !== "") {
        this.setState({ error_customer_password_re: false });
      } else {
        this.setState({ error_customer_password_re: true });
      }
      this.setState(
        { customer_password_re: value },
        function () {
          this.passwordCheck();
        }.bind(this)
      );
    } else if (event.target.name === "terms") {
      var isCheck = this.state.customer_terms_condition === true ? false : true;
      this.setState({
        customer_terms_condition: isCheck,
        error_customer_terms_condition: !isCheck,
      });
    }
  }

  checkAlreadyExit(flg) {
    var postObject = {
      type: flg,
      customer_email: this.state.customer_email,
      customer_phone: this.state.customer_phone.replace(this.state.country, ""),
    };
    axios
      .post(apiUrl + "onboarding/checkexisting", qs.stringify(postObject))
      .then((res) => {
        hideLoader("signup_first", "class");
        if (res.data.status === "success") {
          if (flg === "email") {
            this.setState({ error_exist_email: "" });
          }
          if (flg === "phone") {
            this.setState({ error_exist_phone: "" });
          }
        } else {
          if (flg === "email") {
            this.setState({ error_exist_email: res.data.message, error_customer_email:true});
          }
          if (flg === "phone") {
            this.setState({ error_exist_phone: res.data.message, error_customer_phone:true});
          }
        }
      });
  }

  passwordCheck() {}

  handleTextChange(event) {
    var name = event.target.name;
    var value = event.target.value;

    this.setState({ [name]: value });
  }

  handleFromChange(actFrom) {
    if (actFrom === "singup") {
      var errorFlg = "n";
      if (this.state.customer_name === "") {
        errorFlg = "y";
        this.setState({ error_customer_name: true });
        return false;
      } else {
        this.setState({ error_customer_name: false });
      }
      if (this.state.customer_phone === "") {
        errorFlg = "y";
        this.setState({ error_customer_phone: true });
        return false;
      } else if (this.state.error_exist_phone !==""){
        this.setState({ error_exist_phone: false });
      }
      else {
        this.setState({ error_customer_phone: false, error_exist_phone:""});
      }
      if (this.state.customer_email === "") {
         errorFlg = "y";
        this.setState({
          error_customer_email: true,
          error_invalid_email: false,
        });
       
         return false;

      } else if(this.state.error_exist_email != ''){
        errorFlg = "y";
          this.setState({ error_customer_email: true });
          this.setState({ error_invalid_email: false });
          return false;
      }
       else {
        var validate_Email_Fun = validateEmailFun(this.state.customer_email);

        if (validate_Email_Fun === true) {
          this.setState({ error_customer_email: false });
          this.setState({ error_invalid_email: false });
        } else {
          errorFlg = "n";
          this.setState({ error_invalid_email: true, error_customer_email: true});
          return false;
        }
      }
      if (this.state.customer_password === "") {
         errorFlg = "y";
        this.setState({ error_customer_password: true });
         return false;
      } else {
        this.setState({ error_customer_password: false });
      }
      if (this.state.customer_password_re === "") {
        errorFlg = "y";
        this.setState({ error_customer_password_re: true });
         return false;
      } else {
        this.setState({ error_customer_password_re: false });
      }

       if (this.state.customer_password_re !== "") {
        if (
          this.state.customer_password !== this.state.customer_password_re
        ) {
          errorFlg = "y";
          this.setState({ error_customer_password_re: true });
           return false;
        } else {
          this.setState({ error_customer_password_re: false });
        }
      }

      if (this.state.customer_terms_condition === false) {
        errorFlg = "y";
        this.setState({ error_customer_terms_condition: true });
         return false;
      } else {
        this.setState({ error_customer_terms_condition: false });
      }

      if (this.state.error_customer_captcha === false) {
        errorFlg = "y";
        this.setState({ error_customer_captcha: false });
        return false;
      } else {
        this.setState({ error_customer_captcha: true });
      }

      if (errorFlg === "n") {
        var postObject = {
          customer_email: this.state.customer_email,
          customer_name: this.state.customer_name,
        };
        showLoader("signup_first", "class");
        axios
          .post(apiUrl + "onboarding/generateOTP", qs.stringify(postObject))
          .then((res) => {
            hideLoader("signup_first", "class");
            if (res.data.status === "success") {
              this.setState({
                otp: res.data.otp,
                div_verification_code: "show",
              });
            } else {
              showCustomAlert("error", res.data.message);
            }
          });
      }
    } else if (actFrom === "verification") {
      if (
        this.state.code_digit1 !== "" ||
        this.state.code_digit2 !== "" ||
        this.state.code_digit3 !== "" ||
        this.state.code_digit4 !== "" ||
        this.state.code_digit5 !== "" ||
        this.state.code_digit6 !== ""
      ) {
        this.setState({ error_verifiy_code: false });
        var enterOTP =
          this.state.code_digit1 +
          this.state.code_digit2 +
          this.state.code_digit3 +
          this.state.code_digit4 +
          this.state.code_digit5 +
          this.state.code_digit6;
        if (parseInt(this.state.otp) !== parseInt(enterOTP)) {
          this.setState({
            error_invalid_verifiy_code: true,
            error_verifiy_code: false,
          });
        } else {
          this.setState({ error_invalid_verifiy_code: false });
          var postObject = {
            name: this.state.customer_name,
            country: this.state.country,
            phone: this.state.customer_phone.replace(this.state.country, ""),
            email: this.state.customer_email,
            password: this.state.customer_password,
          };
          showLoader("verify_btn", "class");
          axios
            .post(apiUrl + "onboarding/createaccount", qs.stringify(postObject))
            .then((res) => {
              hideLoader("verify_btn", "class");
              if (res.data.status === "success") {
                cookie.save("userID", res.data.userId, { path: "/" });
                

                this.props.history.push("/getstart");
                showCustomAlert("success", res.data.message);
              } else {
                showCustomAlert("error", res.data.message);
              }
            });
        }
      } else {
        this.setState({ error_verifiy_code: true });
      }
    }
  }

  handleSigninFromChange(actFrom) {

      var errorFlg = "n";
      if (this.state.login_customer_email === "") {
        this.setState({
          error_login_customer_email: true,
        });
        errorFlg = "y";
      } else {
        var validate_Email_Fun = validateEmailFun(this.state.login_customer_email);
        if (validate_Email_Fun === true) {
          this.setState({ error_login_customer_email: false });
          this.setState({ error_login_invalid_email: false });
        } else {
          errorFlg = "n";
          this.setState({ error_login_invalid_email: true, error_login_customer_email:true});

        }
      }
      if (this.state.login_customer_password === "") {
        this.setState({ error_login_customer_password: true });
        errorFlg = "y";
      } else {
        this.setState({ error_login_customer_password: false});
      }

      if (errorFlg === "n") {
        var postObject = {
          email: this.state.login_customer_email,
          password: this.state.login_customer_password,
        };
        showLoader("signup_first", "class");
        axios
          .post(apiUrl + "onboarding/login", qs.stringify(postObject))
          .then((res) => {
            hideLoader("signup_first", "class");
            if (res.data.status === "success") {
                this.setState({ error_login: '',error_login_customer_password: true});
                cookie.save("userID", res.data.userId, { path: "/" });
                this.props.history.push("/getstart");
            } else {
                this.setState({ error_login: res.data.message, error_login_customer_password: false });
            }
          });
      }
  }


  handleVerificationCode(step, event) {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: event.target.value }, function () {
      if (value !== "") {
        var gotoNext = parseInt(step) + 1;
        $("#code_digit" + gotoNext).focus();
      }
    });
  }
  onChange(value) {
    if(value !==""){
      this.setState({error_customer_captcha: true});
    }else{
      this.setState({error_customer_captcha: false});
    }
  }


  openForgotPassword(e){
    e.preventDefault();

    var popupIdTxt = "#forgot-password-popup";
    $.magnificPopup.open({
      items: {
        src: popupIdTxt,
      },
      type: "inline",
      closeOnBgClick: false,
    });
  }

  ForgorPassword(){
    var pass = 'y';
    if(this.state.forgotemailaddress === ""){
      this.setState({errorforgotemailaddress :false});
      pass = 'e';
      return false;

    }else{

      this.setState({errorforgotemailaddress :true});
      pass = 'y';
    }

    if(pass == 'y'){

      var postObject = {
        email_address: this.state.forgotemailaddress,
      };

     axios.post(apiUrl + "onboarding/forgot_password", qs.stringify(postObject)).then((res)=>{

       if(res.data.status == "success"){

        this.handleShowAlertFun("success", res.data.message);

       }else{

        this.handleShowAlertFun("Error", res.data.message);

       }

     });

   }

  }


 handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }


  render() {


    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();


    return (
         <div>

       <div className="login_mobileheader">
        <div className="dashboard-mobilemenu ">
          <div className="mobile-nav-logo">
            <a href="#">
              <img src={mobilelogo} />
            </a>
          </div>
        </div>
      </div>

      <div className="dashboard">
        <div className="step-one">
          <div className="so-lhs">
            <img src={heroimage} />{" "}
          </div>
          <div className="so-rhs">
            <div className="form-header">
              <h1>Start Your Own Ordering Platform</h1>
              <p>
                Delivery, Takeaway, QR Dine In, Catering, Reservation,
                <br /> CRM, Loyalty, Analytics and Integrations.
              </p>
            </div>

        <div className="dashboard-ulmain">
        <ul className="nav nav-tabs text-center">
          <li className="active" id="signup_tab">
            <a data-toggle="tab" href="#signup" aria-expanded="true"> <span>Signup</span> </a>
          </li>
          <li className="" id="login_tab">
            <a data-toggle="tab" href="#login" aria-expanded="false"> <span>Login</span> </a>
          </li>
          </ul>
        <div className="tab-content">
          <div id="signup" className="tab-pane fade active in">
            <div className="so-form">
              <div className="singupform-step-one">
                <div
                  className={
                    this.state.customer_name !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }
                >
                  <input
                    type="text"
                    className="input-box"
                    id="customer_name"
                    name="customer_name"
                    value={this.state.customer_name}
                    onChange={this.handleSignupChange.bind(this)}
                  />

                  {this.state.error_customer_name === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                 {this.state.error_customer_name === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}

                  <label className="control-label">Your Name</label>
                </div>
                <div
                  className={
                    this.state.customer_phone !== ""
                      ? "control-group control-flag focus-out focused"
                      : "control-group control-flag control-flag"
                  }
                >
                  <PhoneInput
                    country={"sg"}
                    value={this.state.customer_phone}
                    onChange={(customer_phone, country) =>
                      this.setState(
                        {
                          customer_phone,
                          country: country.dialCode,
                          error_customer_phone: false,
                        },
                        function () {
                          if (
                            customer_phone.replace(country.dialCode, "")
                              .length >= 8
                          ) {
                            this.checkAlreadyExit("phone");
                          }
                        }
                      )
                    }/>

                  {this.state.error_customer_phone === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                 {this.state.error_exist_phone !=='' && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                  {this.state.error_customer_phone === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}

                <label class="control-label"> Your Telephone Number</label>
                </div>
                <div
                  className={
                    this.state.customer_email !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }
                >
                  <input
                    type="text"
                    className="input-box"
                    id="customer_email"
                    name="customer_email"
                    value={this.state.customer_email}
                    onChange={this.handleSignupChange.bind(this)}
                    placeholder=""
                  />

                 {this.state.error_customer_email === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

  
                 {this.state.error_customer_email === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}


                <label className="control-label">Your Email Address</label>
                </div>
                <div
                  className={
                    this.state.customer_password !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }
                >
                  <input
                    type="password"
                    className="input-box"
                    id="customer_password"
                    name="customer_password"
                    value={this.state.customer_password}
                    onChange={this.handleSignupChange.bind(this)}
                    placeholder=""
                  />

                  {this.state.error_customer_password === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}


                  {this.state.error_customer_password === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}

                  <label className="control-label">Password</label>
                </div>
                <div
                  className={
                    this.state.customer_password_re !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }
                >
                  <input
                    type="password"
                    className="input-box"
                    id="customer_password_re"
                    name="customer_password_re"
                    value={this.state.customer_password_re}
                    onChange={this.handleSignupChange.bind(this)}
                    placeholder=""
                  />

                  {this.state.error_customer_password_re=== true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                  {this.state.error_customer_password_re === false && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorImg} />
                  </span>
                  )}

                  <label className="control-label">Re Enter Password</label>
                </div>
                {this.state.div_verification_code === "hide" && (
                  <div>

                    <div className="terms-chk">
                      <input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        checked={this.state.customer_terms_condition}
                        onChange={this.handleSignupChange.bind(this)}
                      />
                      {this.state.error_customer_terms_condition === true && (
                        <span className="error">This field is required.</span>
                      )}
                      <label className="inline">
                        I agree with NinjaOS {" "}
                        <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a>{" "}
                      </label>
                    </div>

                    <div className="control-group captcha-div">
                    <h6>Are you a robot ?</h6>
                      <ReCAPTCHA
                        sitekey="6LfKsbUeAAAAAB2CXX_pS_eLbuengic52Xu7XKgw"
                        onChange={this.onChange.bind(this)}
                      />
                    </div>

                    <button
                      type="button"
                      className="button signup_first"
                      onClick={this.handleFromChange.bind(this, "singup")}
                    >
                      Next
                    </button>
                  {(this.state.error_customer_password_re === true || this.state.error_customer_password === true  || this.state.error_customer_email === true || this.state.error_customer_name === true || this.state.error_customer_phone === true ) && (
                    <span className="error">Please enter all fields</span>
                  )}
                  {this.state.error_invalid_email === true && (
                    <span className="error">This is not a valid email.</span>
                  )}
                  {this.state.error_exist_email !== "" && (
                    <span className="error">
                      {this.state.error_exist_email}
                    </span>
                  )}
                  {this.state.error_exist_phone !== "" && (
                    <span className="error">
                      {this.state.error_exist_phone}
                    </span>
                  )}

                  </div>
                )}
                {this.state.div_verification_code === "show" && (
                  <div className="verification-code-div">
                    <div className="verification-code">
                      <div className="prompt">
                        Please enter verification code we sent to your email.
                      </div>
                      <div className="prompt-box verification-code-row">
                        <input
                          type="text"
                          className="input-box-small"
                          id="code_digit1"
                          name="code_digit1"
                          value={this.state.code_digit1}
                          maxLength="1"
                          onChange={this.handleVerificationCode.bind(this, 1)}
                        />
                        <input
                          type="text"
                          className="input-box-small"
                          id="code_digit2"
                          name="code_digit2"
                          value={this.state.code_digit2}
                          maxLength="1"
                          onChange={this.handleVerificationCode.bind(this, 2)}
                        />
                        <input
                          type="text"
                          className="input-box-small"
                          id="code_digit3"
                          name="code_digit3"
                          value={this.state.code_digit3}
                          maxLength="1"
                          onChange={this.handleVerificationCode.bind(this, 3)}
                        />
                        <input
                          type="text"
                          className="input-box-small"
                          id="code_digit4"
                          name="code_digit4"
                          value={this.state.code_digit4}
                          maxLength="1"
                          onChange={this.handleVerificationCode.bind(this, 4)}
                        />
                        <input
                          type="text"
                          className="input-box-small"
                          id="code_digit5"
                          name="code_digit5"
                          value={this.state.code_digit5}
                          maxLength="1"
                          onChange={this.handleVerificationCode.bind(this, 5)}
                        />
                        <input
                          type="text"
                          className="input-box-small"
                          id="code_digit6"
                          name="code_digit6"
                          value={this.state.code_digit6}
                          maxLength="1"
                          onChange={this.handleVerificationCode.bind(this, 6)}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="button verify_btn"
                      onClick={this.handleFromChange.bind(this, "verification")}
                    >
                      Lets Get Started
                    </button>
                  </div>
                )}
              </div>
            </div>

          </div>
          <div id="login" className="tab-pane fade">           

            <div className="so-form">
              
              <div className="singupform-step-one">
                <div
                  className={
                    this.state.login_customer_email !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }
                >
                  <input
                    type="text"
                    className="input-box"
                    id="login_customer_email"
                    name="login_customer_email"
                    value={this.state.login_customer_email}
                    onChange={this.handleTextChange.bind(this)}
                    placeholder=""
                  />

                  {this.state.error_login_customer_email === false && (
                  <span className="error_img">
                  <img className="order_type_img" src={errorImg}  />
                  </span>
                  )}

                  {this.state.error_login_customer_email === true && (
                  <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}

                  {this.state.error_login_invalid_email === true && (
                    <span className="error">This is not a valid email.</span>
                  )}
                  <label className="control-label">Your Email Address</label>
                </div>
                <div
                  className={
                    this.state.login_customer_password !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }
                >
                  <input
                    type="password"
                    className="input-box"
                    id="login_customer_password"
                    name="login_customer_password"
                    value={this.state.login_customer_password}
                    onChange={this.handleTextChange.bind(this)}
                    placeholder=""
                  />
                  {this.state.error_login_customer_password === false && (
                  <span className="error_img">
                  <img className="order_type_img" src={errorImg}  />
                  </span>
                  )}


                  {this.state.error_login_customer_password === false  && this.state.error_login !=='' &&(
                   <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}


                  {this.state.error_login_customer_password === true  && this.state.error_login === '' &&(
                   <span className="error_img">
                  <img className="order_type_imgwt" src={errorwImg} />
                  </span>
                  )}


                  <label className="control-label">Password</label>
                </div>
                <button
                  type="button"
                  className="button verify_btn"
                  onClick={this.handleSigninFromChange.bind(this)}
                >
                  Login now
                </button>
                <div className="f_passwd_div">
                <Link className="onboard-forgot-password" onClick={this.openForgotPassword.bind(this)}>Forgot password</Link>
                </div>

                {this.state.error_login != '' && (
                  <span className="error">{this.state.error_login}</span>
                )}

              </div>
            </div>
          </div>
          </div>
        </div>



          </div>
        </div>

        <div
          id="forgot-password-popup"
          className="white-popup mfp-hide popup_sec login-popup forgot-password-popup"
        >
          <div className="pop-whole">
              <div className="forgot-password-heading">
                <h3>Forgot your Password?</h3>
                <p>You can reset your Password here.</p>
              </div>

            <div className="forgot-popup-inner">
            <div className="popup-body">
              <div className="form-group">
                <div className="forget-heading">Please click Forgot Password to set a Password for the login.</div>
                <div className={
                    this.state.forgotemailaddress !== ""
                      ? "control-group focus-out focused"
                      : "control-group"
                  }>
                  <input
                    type="email"
                    name="forgotemailaddress"
                    value={this.state.forgotemailaddress}
                    onChange={this.handleTextChange.bind(this)}
                    className="input-box"
                    />
                    <label className="control-label">Email Address</label>

                    {this.state.errorforgotemailaddress === false &&(

                       <div id="form-msg">This field is required</div>
                    )}

                </div>
              </div>
              <div className="login_pop_sub">
                <button
                  type="button"
                  onClick={this.ForgorPassword.bind(this)}
                  className="button btn_black btn_minwid forgotpassword-cls"
                >
                  Get Reset Link
                </button>
              </div>

            </div>

            </div>
          </div>
        </div>



        <footer>
          <p>© {yearSp} NinjaOS</p>
        </footer>
      </div>
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
