/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Slider from "react-slick";
import { apiUrl, proapiUrl, apiBaseUrl, proapiBaseUrl, apiBaseUrlEnt } from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  country_symbol
} from "../Helpers/SettingHelper";


import logo from "../../common/images/logo.png";
import BackendImg from "../../common/images/backend.jpg";
import FrontendImg from "../../common/images/frontend.jpg";
import TabcontactImg from "../../common/images/tab-contact.jpg";
import TabImg from "../../common/images/settings.svg";
import TabImg1 from "../../common/images/tab-group.svg";
import TabImg2 from "../../common/images/setup1.svg";
import TabImg3 from "../../common/images/chat-dark.svg";
import sinfo from "../../common/images/settings-info.jpg";
import Back_inImg from "../../common/images/back_copy.png";
import Back_passImg from "../../common/images/passwrd.svg";
import mobilelogo from "../../common/images/logo.png";
import PricetickImg from "../../common/images/blue-tick.png";

import getstartImg from "../../common/images/s_bar2.svg";
import getstartWImg from "../../common/images/s_bar1.svg";
import mdetailsImg from "../../common/images/s_bar4.svg";
import mdetailsWImg from "../../common/images/s_bar3.svg";
import planImg from "../../common/images/s_bar6.svg";
import planWImg from "../../common/images/s_bar5.svg";
import helpImg from "../../common/images/s_bar8.svg";
import helpWImg from "../../common/images/s_bar7.svg";
import logoutImg from "../../common/images/s_bar10.svg";
import logoutWImg from "../../common/images/s_bar9.svg";
import dashboardImg from "../../common/images/s_bar12.svg";
import dashboardWImg from "../../common/images/s_bar11.svg";
import notficationImg from "../../common/images/s_bar14.svg";
import notficationWImg from "../../common/images/s_bar13.svg";
import subscriptionsImg from "../../common/images/s_bar16.svg";
import subscriptionsWImg from "../../common/images/s_bar15.svg";
import FileuploadImg from "../../common/images/download.svg";

import messageImg from "../../common/images/message.svg";

var qs = require("qs");
class Notifications extends Component {
  constructor(props) {
    super(props);
    var userID =
      cookie.load("userID") !== "" &&
      typeof cookie.load("userID") !== undefined &&
      typeof cookie.load("userID") !== "undefined"
        ? cookie.load("userID")
        : "";
    this.state = {
      step: 1,
      userID: userID,
      currentPage: this.props.match.path,
    };
    if (userID === "") {
      this.props.history.push("/");
    } else {
      this.loadUserDetails();
    }
  }

  loadUserDetails() {
    var urlShringTxt =
      apiUrl + "onboarding/getuserinfo?userID=" + this.state.userID;

    axios.get(urlShringTxt).then((res) => {
      var result = res.data.result_set;
      var step = 1;
      $("#dvLoading").fadeOut();
      if (result.onboarding_payment_status !== "Paid") {
        step =
          result.onboarding_complete_step !== "" &&
          result.onboarding_complete_step !== null
            ? parseInt(result.onboarding_complete_step) + 1
            : 1;
      }

    });
  }


  
  render() {

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (<div>
        <div className="dashboard-mobilemain">
        <div className="dashboard-mobilemenu">
          <div className="menu_icon trigger_menunav_act">
            <span className="icon-bar icon-bar1"></span>
            <span className="icon-bar icon-bar2"></span>
            <span className="icon-bar icon-bar3"></span>
          </div>

          <div className="mobile-nav-logo">
            <a href="#">
              {" "}
              <img src={mobilelogo} />{" "}
            </a>
          </div>

          <div className="mobile-menu">
            <div className="mobile-menu-header">
              <div className="mobile-menu-close">
                <span id="close_mobile_menu"></span>
              </div>
               <div className="mobile-menu-logo">
              <a title="Ninja Onboard" href="/">
                <img src={mobilelogo} />
              </a>
            </div>
            </div>
            <div className="mobile-menu-body">
              <ul className="menu">
              {cookie.load("onboarding_payment_status") === "Pending" && (
              <li className={this.state.currentPage === "/getstart" ? "active" : ""}>
                <Link to="/getstart">
                <div className="sidebar-img-div">
                  <div className="sidebar-img">
                    <img className="order_type_img" src={getstartImg} />
                    <img className="order_type_imgwt" src={getstartWImg} />
                  </div>
                  <div className="cat-content">
                    Get Started
                  </div>
                </div>
                </Link>{" "}
              </li>
              )}
              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={ this.state.currentPage === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={mdetailsImg} />
                      <img className="order_type_imgwt" src={mdetailsWImg} />
                    </div>
                    <div className="cat-content">
                      Dashboard
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {cookie.load("onboarding_payment_status") === "Paid" && (
               <li className={this.state.currentPage === "/notifications" ? "active" : ""}>
                <Link to="/notifications">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={notficationImg} />
                      <img className="order_type_imgwt" src={notficationWImg} />
                    </div>
                    <div className="cat-content">
                      notifications
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {cookie.load("onboarding_payment_status") === "Paid" && (
             <li className={this.state.currentPage === "/subscriptions" ? "active" : ""}>
                <Link to="/subscriptions">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={subscriptionsImg} />
                      <img className="order_type_imgwt" src={subscriptionsWImg} />
                    </div>
                    <div className="cat-content">
                      subscriptions
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              {cookie.load("onboarding_payment_status") === "Paid" && (
              <li className={this.state.currentPage === "/my-details" ? "active" : ""}>
                <Link to="/my-details">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={dashboardImg} />
                      <img className="order_type_imgwt" src={dashboardWImg} />
                    </div>
                    <div className="cat-content">
                      My Details
                    </div>
                  </div>
                </Link>{" "}
              </li>
              )}

              <li className={this.state.currentPage === "/plan" ? "active" : ""}>
                <Link to="/plan">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={planImg} />
                      <img className="order_type_imgwt" src={planWImg} />
                    </div>
                    <div className="cat-content">
                       Plan
                    </div>
                  </div>
                </Link>{" "}
              </li>

              <li>
                <a href="https://support.ninjaos.com/" target="_blank">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={helpImg} />
                      <img className="order_type_imgwt" src={helpWImg} />
                    </div>
                    <div className="cat-content">
                       Help
                    </div>
                  </div>
                </a>{" "}
              </li>
              <li>
                <Link to="/logout">
                  <div className="sidebar-img-div">
                    <div className="sidebar-img">
                      <img className="order_type_img" src={logoutImg} />
                      <img className="order_type_imgwt" src={logoutWImg} />
                    </div>
                    <div className="cat-content">
                       Logout
                    </div>
                  </div>
                </Link>{" "}
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard">
        <div className="dashboard-inner">
          <Sidebar {...this.props} />
          <div className="dashboard-content">
            {/*<ul className="stepper-wrapper active-first">
              <li
                className={
                  this.state.step === 1 ||
                  this.state.step === 2 ||
                  this.state.step === 3 ||
                  this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item"
                }
              >
                <div className="step-counter">1</div>
              </li>
              <li
                className={
                  this.state.step === 2 ||
                  this.state.step === 3 ||
                  this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item "
                }
              >
                <div className="step-counter">2</div>
              </li>
              <li
                className={
                  this.state.step === 3 || this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item "
                }
              >
                <div className="step-counter">3</div>
              </li>
              <li
                className={
                  this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item "
                }
              >
                <div className="step-counter">3</div>
              </li>
              <li
                className={
                  this.state.step === 4
                    ? "stepper-item active"
                    : "stepper-item "
                }
              >
                <div className="step-counter">4</div>
              </li>
            </ul>*/}
            

            <div className="getstarted">
              <div className="your-package notification-heading">
                <h1>Notifications</h1>
              </div>
            </div>

            <div className="notification-maindiv">
              <ul>
                <div className="clear">
                  <a className="">clear all</a>
                </div>
                <li className="active">
                  <div className="notification-li">
                    <div className="notification-img">
                        <img className="order_type_img" src={messageImg} />
                    </div>
                    <div className="notification-content">
                      <h5>Notification Title will be here</h5>
                      <p>Would you like to use your own domain? no problem please follow our guid</p>
                    </div>
                  </div>
                  <div className="notification-button">
                    <div className="black-part"><a className="button">read</a></div>
                  </div>
                </li>

                <li className="">
                  <div className="notification-li">
                    <div className="notification-img">
                        <img className="order_type_img" src={messageImg} />
                    </div>
                    <div className="notification-content">
                      <h5>Notification Title will be here</h5>
                      <p>Your setup is completed, please click dashboard to view more information</p>
                    </div>
                  </div>
                  {/*<div className="notification-button">
                    <div className="black-part"><a className="button">read</a></div>
                  </div>*/}
                </li>

                <li className="">
                  <div className="notification-li">
                    <div className="notification-img">
                        <img className="order_type_img" src={messageImg} />
                    </div>
                    <div className="notification-content">
                      <h5>Notification Title will be here</h5>
                      <p>Your setup is completed, please click dashboard to view more information</p>
                    </div>
                  </div>
                  {/*<div className="notification-button">
                    <div className="black-part"><a className="button">read</a></div>
                  </div>*/}
                </li>

                <li className="">
                  <div className="notification-li">
                    <div className="notification-img">
                        <img className="order_type_img" src={messageImg} />
                    </div>
                    <div className="notification-content">
                      <h5>Notification Title will be here</h5>
                      <p>Your setup is completed, please click dashboard to view more information</p>
                    </div>
                  </div>
                  {/*<div className="notification-button">
                    <div className="black-part"><a className="button">read</a></div>
                  </div>*/}
                </li>

                <li className="">
                  <div className="notification-li">
                    <div className="notification-img">
                        <img className="order_type_img" src={messageImg} />
                    </div>
                    <div className="notification-content">
                      <h5>Notification Title will be here</h5>
                      <p>Your setup is completed, please click dashboard to view more information</p>
                    </div>
                  </div>
                  {/*<div className="notification-button">
                    <div className="black-part"><a className="button">read</a></div>
                  </div>*/}
                </li>

                <li className="">
                  <div className="notification-li">
                    <div className="notification-img">
                        <img className="order_type_img" src={messageImg} />
                    </div>
                    <div className="notification-content">
                      <h5>Notification Title will be here</h5>
                      <p>Your setup is completed, please click dashboard to view more information</p>
                    </div>
                  </div>
                  {/*<div className="notification-button">
                    <div className="black-part"><a className="button">read</a></div>
                  </div>*/}
                </li>

                <li className="active">
                  <div className="notification-li">
                    <div className="notification-img">
                        <img className="order_type_img" src={messageImg} />
                    </div>
                    <div className="notification-content">
                      <h5>Notification Title will be here</h5>
                      <p>Would you like to use your own domain? no problem please follow our guid</p>
                    </div>
                  </div>
                  <div className="notification-button">
                    <div class="black-part"><a class="button">read</a></div>
                  </div>
                </li>
              </ul>
            </div>
           </div>
         </div>
        </div>
        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Notifications);
